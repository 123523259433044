define("pickle/controllers/companies/new", ["exports", "pickle/mixins/form-cancel-edit", "pickle/mixins/company-form"], function (_exports, _formCancelEdit, _companyForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_formCancelEdit.default, _companyForm.default, {});

  _exports.default = _default;
});