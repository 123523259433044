define("pickle/templates/components/simple-opportunity-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kMKe4vO0",
    "block": "[[[6,[39,0],null,[[\"route\",\"model\"],[[33,1],[33,2,[\"id\"]]]],[[\"default\"],[[[[1,\"  \"],[10,\"h5\"],[12],[1,[33,2,[\"name\"]]],[13],[1,\"\\n  \"],[10,0],[14,0,\"people-involved\"],[12],[1,\"\\n    \"],[1,[28,[35,3],null,[[\"people\"],[[33,2,[\"contacts\"]]]]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,2],[14,0,\"opportunity-description\"],[12],[1,[28,[35,4],[[33,2,[\"description\"]],[33,5]],null]],[13],[1,\"\\n  \"],[10,0],[12],[1,[28,[35,6],[[33,2,[\"status\"]]],null]],[13],[1,\"\\n  \"],[10,1],[14,0,\"gray-text\"],[12],[1,[28,[35,7],[[33,2,[\"updated_at\"]]],[[\"timeAgoInWords\"],[true]]]],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"link-to\",\"linkToDestination\",\"opportunity\",\"people-avatars\",\"trim-text\",\"maxDescriptionLength\",\"opportunity-status-badge\",\"date-format\"]]",
    "moduleName": "pickle/templates/components/simple-opportunity-cell.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});