define("pickle/models/address", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    address_1: _emberData.default.attr('string'),
    address_2: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    zip: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    kind: _emberData.default.attr('string'),
    contact: _emberData.default.belongsTo('contact'),
    contact_id: _emberData.default.attr('string')
  });

  _exports.default = _default;
});