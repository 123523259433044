define("pickle/controllers/settings/magic-email/how-to-send", ["exports", "pickle/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentAccount: Ember.inject.service(),
    userOnboarding: Ember.inject.service(),
    magicEmailReceived: false,
    magicEmailMessage: null,
    showConfetti: false,
    showOnwardLink: false,
    magicEmail: Ember.computed('currentAccount.currentAccount.inboundEmailAddresses', function () {
      return this.get('currentAccount.currentAccount.inboundEmailAddresses.firstObject.emailAddress');
    }),
    downloadVCardURL: Ember.computed('currentAccount.currentAccount', function () {
      var magicEmail = this.get('currentAccount.currentAccount.inboundEmailAddresses.firstObject');
      var accountId = this.get('currentAccount.currentAccount.id');
      var token = window.localStorage.getItem('userAccessToken');
      this.setupPusher("magic-email_".concat(magicEmail.get('emailAddress')));
      return "".concat(_environment.default.apiHost, "/magicEmail/").concat(magicEmail.get('id'), "/vcard?account_id=").concat(accountId, "&token=").concat(token);
    }),
    setupPusher: function setupPusher(channelName) {
      var _this = this;

      var pusher = new window.Pusher(_environment.default.pusherApp, {
        cluster: 'us2',
        encrypted: true
      });
      var channel = pusher.subscribe(channelName);
      channel.bind(channelName, function () {// console.log(data.message);
      });
      channel.bind('pusher:subscription_succeeded', function () {
        _this.set('socketConnected', true);
      });
      channel.bind('email-received', function (data) {
        _this.get('userOnboarding').magicEmailReceived(data, true);

        _this.set('magicEmailReceived', true);

        _this.set('magicEmailMessage', data);

        _this.confetti(1.5);

        _this.showConfettiWhenHasFocus(2); // this.magicEmailReceived(data);

      });
      channel.bind('sms-inbound-reply-received', function () {// this.set('controller.notificationCenter.reloadContactNotes', true);
      });
    },
    // magicEmailReceived(data) {
    //     this.get('userOnboarding').magicEmailReceived(data, true);
    //     this.set('magicEmailReceived', true);
    //     this.set('magicEmailMessage', data);
    //     this.showConfettiWhenHasFocus(2);
    // },
    showConfettiWhenHasFocus: function showConfettiWhenHasFocus(seconds) {
      var _this2 = this;

      Ember.$(window).focus(function () {
        console.log('Congrats!');

        _this2.confetti(seconds); // remove focus handler


        Ember.$(window).off('focus');
      });
    },
    confetti: function confetti(seconds) {
      // globals
      var canvas;
      var ctx;
      var W;
      var H;
      var mp = 150; //max particles

      var particles = [];
      var angle = 0;
      var tiltAngle = 0; //eslint-disable-line

      var confettiActive = true;
      var animationComplete = true; // var deactivationTimerHandler;

      var reactivationTimerHandler;
      var animationHandler; // objects

      var particleColors = {
        colorOptions: ["DodgerBlue", "OliveDrab", "Gold", "pink", "SlateBlue", "lightblue", "Violet", "PaleGreen", "SteelBlue", "SandyBrown", "Chocolate", "Crimson"],
        colorIndex: 0,
        colorIncrementer: 0,
        colorThreshold: 10,
        getColor: function getColor() {
          if (this.colorIncrementer >= 10) {
            this.colorIncrementer = 0;
            this.colorIndex++;

            if (this.colorIndex >= this.colorOptions.length) {
              this.colorIndex = 0;
            }
          }

          this.colorIncrementer++;
          return this.colorOptions[this.colorIndex];
        }
      };

      function confettiParticle(color) {
        this.x = Math.random() * W; // x-coordinate

        this.y = Math.random() * H - H; //y-coordinate

        this.r = RandomFromTo(10, 30); //radius;

        this.d = Math.random() * mp + 10; //density;

        this.color = color;
        this.tilt = Math.floor(Math.random() * 10) - 10;
        this.tiltAngleIncremental = Math.random() * 0.07 + .05;
        this.tiltAngle = 0;

        this.draw = function () {
          ctx.beginPath();
          ctx.lineWidth = this.r / 2;
          ctx.strokeStyle = this.color;
          ctx.moveTo(this.x + this.tilt + this.r / 4, this.y);
          ctx.lineTo(this.x + this.tilt, this.y + this.tilt + this.r / 4);
          return ctx.stroke();
        };
      }

      Ember.$(document).ready(function () {
        SetGlobals();
        InitializeButton();
        InitializeConfetti();
        Ember.$(window).resize(function () {
          W = window.innerWidth;
          H = window.innerHeight;
          canvas.width = W;
          canvas.height = H;
        });
      });

      function InitializeButton() {
        Ember.$('#stopButton').click(DeactivateConfetti);
        Ember.$('#startButton').click(RestartConfetti);
      }

      function SetGlobals() {
        canvas = document.getElementById("confetti-canvas");
        ctx = canvas.getContext("2d");
        W = window.innerWidth;
        H = window.innerHeight;
        canvas.width = W;
        canvas.height = H;
      }

      function InitializeConfetti() {
        particles = [];
        animationComplete = false;

        for (var i = 0; i < mp; i++) {
          var particleColor = particleColors.getColor();
          particles.push(new confettiParticle(particleColor));
        }

        StartConfetti();
      }

      function Draw() {
        ctx.clearRect(0, 0, W, H);
        var results = [];

        for (var i = 0; i < mp; i++) {
          (function (j) {
            results.push(particles[j].draw());
          })(i);
        }

        Update();
        return results;
      }

      function RandomFromTo(from, to) {
        return Math.floor(Math.random() * (to - from + 1) + from);
      }

      function Update() {
        var remainingFlakes = 0;
        var particle;
        angle += 0.01;
        tiltAngle += 0.1;

        for (var i = 0; i < mp; i++) {
          particle = particles[i];
          if (animationComplete) return;

          if (!confettiActive && particle.y < -15) {
            particle.y = H + 100;
            continue;
          }

          stepParticle(particle, i);

          if (particle.y <= H) {
            remainingFlakes++;
          }

          CheckForReposition(particle, i);
        }

        if (remainingFlakes === 0) {
          StopConfetti();
        }
      }

      function CheckForReposition(particle, index) {
        if ((particle.x > W + 20 || particle.x < -20 || particle.y > H) && confettiActive) {
          if (index % 5 > 0 || index % 2 == 0) //66.67% of the flakes
            {
              repositionParticle(particle, Math.random() * W, -10, Math.floor(Math.random() * 10) - 10);
            } else {
            if (Math.sin(angle) > 0) {
              //Enter from the left
              repositionParticle(particle, -5, Math.random() * H, Math.floor(Math.random() * 10) - 10);
            } else {
              //Enter from the right
              repositionParticle(particle, W + 5, Math.random() * H, Math.floor(Math.random() * 10) - 10);
            }
          }
        }
      }

      function stepParticle(particle, particleIndex) {
        particle.tiltAngle += particle.tiltAngleIncremental;
        particle.y += (Math.cos(angle + particle.d) + 3 + particle.r / 2) / 2;
        particle.x += Math.sin(angle);
        particle.tilt = Math.sin(particle.tiltAngle - particleIndex / 3) * 15;
      }

      function repositionParticle(particle, xCoordinate, yCoordinate, tilt) {
        particle.x = xCoordinate;
        particle.y = yCoordinate;
        particle.tilt = tilt;
      }

      function StartConfetti() {
        W = window.innerWidth;
        H = window.innerHeight;
        canvas.width = W;
        canvas.height = H;

        (function animloop() {
          if (animationComplete) return null;
          animationHandler = window.requestAnimFrame(animloop);
          return Draw();
        })();
      }

      function ClearTimers() {
        clearTimeout(reactivationTimerHandler);
        clearTimeout(animationHandler);
      }

      function DeactivateConfetti() {
        confettiActive = false;
        ClearTimers();
      }

      function StopConfetti() {
        animationComplete = true;
        if (ctx == undefined) return;
        ctx.clearRect(0, 0, W, H);
      }

      function RestartConfetti() {
        ClearTimers();
        StopConfetti();
        reactivationTimerHandler = setTimeout(function () {
          confettiActive = true;
          animationComplete = false;
          InitializeConfetti();
        }, 100);
      }

      window.requestAnimFrame = function () {
        return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.oRequestAnimationFrame || window.msRequestAnimationFrame || function (callback) {
          return window.setTimeout(callback, 1000 / 60);
        };
      }();

      window.setTimeout(DeactivateConfetti, seconds * 1000);
    }
  });

  _exports.default = _default;
});