define("pickle/components/timeline-message-composer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    timeline: null,
    relatedStepIndex: -1,
    messageBody: null,
    message: null,
    isLoading: false,
    store: Ember.inject.service(),
    canSendEmail: Ember.computed('timeline.contacts.@each', function () {
      // check if at least one contact has an email address
      var contacts = this.get('timeline.contacts');
      if (!contacts) return;

      if (contacts.get('length') > 0) {
        // check if at least one has an email
        return contacts.any(function (c) {
          return !Ember.isNone(c.get('email'));
        });
      } else {
        return false;
      }
    }),
    didInsertElement: function didInsertElement() {
      if (Ember.isNone(this.get('message'))) {
        this.set('message', this.get('store').createRecord('timeline-message', {
          opportunity: this.get('timeline')
        }));
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.get('store').deleteRecord(this.get('message'));
    },
    stepChanged: Ember.observer('relatedStepIndex', function () {
      if (this.get('relatedStepIndex') >= 0) {
        var steps = this.get('timeline.timelineSteps');
        var index = this.get('relatedStepIndex');
        var step = steps.objectAt(index);
        this.set('message.step', step); // set placeholder text

        this.setPlaceholderTextForStep(step);
      }
    }),
    setPlaceholderTextForStep: function setPlaceholderTextForStep(step) {
      this.set('message.name', step.get('template_subject'));
      this.set('message.body', step.get('template_body'));
    },
    actions: {
      checked: function checked(isChecked) {
        this.set('message.completes_step', isChecked);
      },
      sendEmailChecked: function sendEmailChecked(isChecked) {
        this.set('message.sends_email', isChecked);
      },
      removeModal: function removeModal() {
        this.sendAction('hiddenAction', true);
      },
      reset: function reset() {
        this.send('setSelectedStep');
        this.set('relatedStepIndex', -1);
        this.set('messageBody', null);
      },
      // can update or create here.
      saveNote: function saveNote() {
        var _this = this;

        this.set('isLoading', true);
        this.set('timeline.opportunity', this.get('timeline')); // this.setNoteboxToSaving(true);

        if (Ember.isNone(this.get('timeline.opportunity'))) {
          window.alert('Oops, no timeline found!');
          this.set('isLoading', false);
        } else {
          this.get('message').save().then(function (result) {
            _this.get('timeline.timelineMessages').addObject(result);

            _this.set('isLoading', false);

            Ember.$('.modal').modal('hide');
          });
        }
      }
    }
  });

  _exports.default = _default;
});