define("pickle/components/contacts-search-results-popover/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XVJzl0Id",
    "block": "[[[41,[30,0,[\"query\"]],[[[1,\"    \"],[10,0],[14,0,\"p-3 shadow contacts-search-results\"],[12],[1,\"\\n\"],[41,[30,0,[\"results\",\"length\"]],[[[1,\"        \"],[10,\"h5\"],[14,0,\"font-bold text-gray-600\"],[12],[1,\"Found \"],[1,[33,1,[\"length\"]]],[1,\" people matching \\\"\"],[1,[34,2]],[1,\"\\\"\"],[13],[1,\"\\n        \"],[10,\"ul\"],[14,0,\"no-bullets\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"results\"]]],null]],null],null,[[[1,\"            \"],[10,\"li\"],[15,\"onclick\",[28,[37,5],[[30,0],\"selectedItem\",[30,1]],null]],[14,0,\"rounded\"],[12],[1,[30,1,[\"nickname\"]]],[13],[1,\"\\n\"]],[1]],null],[1,\"        \"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"tagName\",\"length\"]],[[[1,\"        \"],[10,0],[14,0,\"empty\"],[12],[1,\"\\n            Press return/enter to save \"],[10,\"strong\"],[12],[1,[34,6]],[13],[1,\" as a new tag.\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,0],[14,0,\"flex\"],[12],[1,\"\\n          \"],[11,\"button\"],[24,0,\"flex items-center flex-grow p-2 rounded fill-current hover:bg-green-400 hover:text-white\"],[24,4,\"button\"],[4,[38,7],[\"click\",[28,[37,8],[[30,0,[\"createAction\"]],[30,0,[\"query\"]]],null]],null],[12],[1,\"\\n\"],[41,[30,0,[\"showSpinner\"]],[[[1,\"              \"],[10,1],[14,0,\"mr-3 loading-spinner-sm\"],[12],[13],[1,\"\\n\"]],[]],[[[1,\"              \"],[10,1],[14,0,\"mr-3 bg-gray-800 fill-current icon icon-plus-circle hover:bg-white \"],[12],[13],[1,\"\\n\"]],[]]],[1,\"             Create contact with name \\\"\"],[10,1],[14,0,\"font-bold\"],[12],[1,[30,0,[\"query\"]]],[13],[1,\"\\\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"]],[]]]],[]]],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[18,2,null],[1,\"\\n\"]],[\"item\",\"&default\"],false,[\"if\",\"results\",\"query\",\"each\",\"-track-array\",\"action\",\"tagName\",\"on\",\"fn\",\"yield\"]]",
    "moduleName": "pickle/components/contacts-search-results-popover/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});