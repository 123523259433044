define("pickle/templates/components/pipeline-container-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2jvvVNBy",
    "block": "[[[18,1,null],[1,\"\\n\"],[10,0],[14,0,\"cell\"],[15,\"data-opportunity\",[33,1,[\"id\"]]],[12],[1,\"\\n    \"],[10,0],[14,0,\"title\"],[12],[1,\"\\n        \"],[6,[39,2],null,[[\"route\",\"model\"],[\"opportunities.show\",[33,1,[\"id\"]]]],[[\"default\"],[[[[1,[33,1,[\"name\"]]]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"body\"],[12],[1,\"\\n        \"],[1,[33,1,[\"description\"]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"value\"],[12],[1,\"\\n        \"],[41,[33,1,[\"value\"]],[[[1,[28,[35,4],[[33,1,[\"value\"]]],[[\"commas\"],[true]]]]],[]],null],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"opportunity\",\"link-to\",\"if\",\"format-currency\"]]",
    "moduleName": "pickle/templates/components/pipeline-container-cell.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});