define("pickle/adapters/message-reply", ["exports", "pickle/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _Ember$String = Ember.String,
      pluralize = _Ember$String.pluralize,
      underscore = _Ember$String.underscore;

  var _default = _application.default.extend({
    pathForType: function pathForType(type) {
      return pluralize(underscore(type));
    }
  });

  _exports.default = _default;
});