define("pickle/mixins/model-pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    queryParams: ['page', 'size'],
    page: 1,
    // no such thing as 0 pages, must be 1 or greater
    size: 15,
    pages: Ember.computed('model.meta', function () {
      var total = parseInt(this.get('model.meta.total'));
      return Math.round(total / this.get('size'));
    }),
    previousPage: Ember.computed('model.meta', function () {
      var page = parseInt(this.get('page'));

      if (page === 1) {
        return null;
      } else {
        return page - 1;
      }
    }),
    nextPage: Ember.computed('model.meta', function () {
      var page = parseInt(this.get('page')); // if there are less contacts than fill a page, don't show next page

      if (this.get('model.meta.total') <= this.get('size')) {
        return null;
      }

      if (this.get('pages') === page) {
        return null;
      } else {
        return page + 1;
      }
    })
  });

  _exports.default = _default;
});