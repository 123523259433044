define("pickle/templates/components/timeline-step-composer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wpyguuSU",
    "block": "[[[6,[39,0],null,[[\"title\",\"ok\",\"close\",\"footer\"],[\"Steps\",\"save\",\"removeModal\",false]],[[\"default\"],[[[[10,0],[14,0,\"timeline-step-composer\"],[12],[1,\"\\n    \"],[18,1,null],[1,\"\\n    \"],[10,0],[15,0,[29,[\"add-note \",[36,2],\" minimized\"]]],[12],[1,\"\\n\"],[41,[33,4],[[[1,\"            \"],[1,[34,5]],[1,\"\\n\"]],[]],[[[1,\"            \"],[11,\"form\"],[4,[38,6],[[30,0],\"saveStep\"],[[\"on\"],[\"submit\"]]],[12],[1,\"\\n                \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n\"],[41,[33,7],[[[1,\"                        \"],[10,\"label\"],[14,\"for\",\"exampleInputEmail1\"],[12],[1,\"Edit step\"],[13],[1,\"\\n\"]],[]],[[[1,\"                        \"],[10,\"label\"],[14,\"for\",\"exampleInputEmail1\"],[12],[1,\"Add a Step\"],[13],[1,\"\\n\"]],[]]],[1,\"                    \"],[10,2],[12],[1,\"Your clients will see steps when viewing the timeline.\"],[13],[1,\"\\n\\n                    \"],[10,0],[14,0,\"flex-row\"],[12],[1,\"\\n                        \"],[10,0],[14,0,\"field\"],[12],[1,\"\\n                            \"],[1,[28,[35,8],null,[[\"type\",\"value\",\"placeholder\",\"autofocus\",\"id\"],[\"text\",[33,9,[\"name\"]],\"Add a New Step...\",\"true\",\"timelineStepName\"]]]],[1,\"\\n                        \"],[13],[1,\"\\n                    \"],[13],[1,\"\\n\\n\"],[41,[28,[37,10],[[33,9,[\"name\",\"length\"]],0],null],[[[1,\"                    \"],[10,\"button\"],[14,\"stype\",\"submit\"],[14,0,\"button-shadow green\"],[12],[1,\"Add Step\"],[13],[1,\"\\n\"]],[]],null],[1,\"                    \"],[13],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[]]]]]],[\"&default\"],false,[\"custom-modal\",\"yield\",\"style\",\"if\",\"isLoading\",\"loading-dots\",\"action\",\"isEditing\",\"input\",\"timelineStep\",\"gt\"]]",
    "moduleName": "pickle/templates/components/timeline-step-composer.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});