define("pickle/templates/components/progress-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3T+4yJY4",
    "block": "[[[10,0],[14,0,\"progress\"],[12],[1,\"\\n\\t\"],[10,0],[14,0,\"progress-bar progress-bar-striped active\"],[14,\"role\",\"progressbar\"],[14,\"aria-valuenow\",\"45\"],[14,\"aria-valuemin\",\"0\"],[14,\"aria-valuemax\",\"100\"],[15,5,[29,[\"width: \",[36,0],\"%\"]]],[12],[1,\"\\n\\t\\t\"],[10,1],[14,0,\"sr-only\"],[12],[1,[34,0]],[1,\"% Complete\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"progress\"]]",
    "moduleName": "pickle/templates/components/progress-bar.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});