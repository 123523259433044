define("pickle/routes/timelines/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentAccount: Ember.inject.service('current-account'),
    model: function model(params) {
      return this.store.findRecord('opportunity', params.id);
    },
    afterModel: function afterModel(timeline
    /*, transition*/
    ) {
      this.store.query('timeline-step', {
        opportunity_id: timeline.get('id')
      });
      this.store.query('timeline-message', {
        timeline_id: timeline.get('id')
      });
      this.store.query('file', {
        opportunity_id: timeline.get('id'),
        account_id: this.get('currentAccount.currentAccountId')
      });
    },
    actions: {
      accountChanged: function accountChanged() {
        this.transitionTo('timelines.index');
      }
    }
  });

  _exports.default = _default;
});