define("pickle/mixins/search-base-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    results: null,
    account: Ember.inject.service('current-account'),
    model: function model() {
      return this.get('results');
    },
    afterModel: function afterModel() {
      var _this = this;

      var account_id = this.get('account.currentAccount.id') || window.localStorage.getItem('currentAccountId'); // find all custom fields to use when useAllCustomFields is set

      this.store.query('customField', {
        account_id: account_id
      }).then(function (fields) {
        _this.set('controller.customFields', fields);
      }); // find all tags

      this.store.query('tag', {
        account_id: account_id
      }).then(function (tags) {
        _this.set('controller.isLoadingTags', false);

        _this.set('controller.tags', tags);
      }); // find all saved searches

      this.store.query('search', {
        account_id: account_id
      }).then(function (saved) {
        _this.set('controller.savedSearches', saved);
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('isLoadingTags', true);

      if (model) {
        controller.set('contacts', model.contacts);
      }
    },
    actions: {
      accountChanged: function accountChanged() {
        this.refresh();
      },
      customSearchSavingHasFinished: function customSearchSavingHasFinished() {
        this.refresh();
      },
      searchCompleted: function searchCompleted(res) {
        this.set('results', res);
        this.set('controller.contacts', res.Contacts);
      }
    }
  });

  _exports.default = _default;
});