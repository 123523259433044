define("pickle/templates/components/metadata-editor-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "10/BtX1f",
    "block": "[[[10,0],[14,0,\"flex-row border rounded border-smoke-100 p-1 justify-evenly items-center mb-3\"],[12],[1,\"\\n\"],[41,[30,0,[\"args\",\"isNew\"]],[[[1,\"        \"],[10,0],[12],[8,[39,1],[[24,\"placeholder\",\"name\"],[24,0,\"p-1 rounded\"]],[[\"@value\",\"@key-up\"],[[30,0,[\"key\"]],[28,[37,2],[[30,0],[30,0,[\"keyChanged\"]]],null]]],null],[13],[1,\"\\n        \"],[10,0],[12],[8,[39,1],[[24,\"placeholder\",\"data\"],[24,0,\"p-1 rounded\"]],[[\"@value\",\"@key-up\"],[[30,0,[\"value\"]],[28,[37,2],[[30,0],[30,0,[\"valueChanged\"]]],null]]],null],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,0],[12],[1,[30,1]],[13],[1,\"\\n        \"],[10,0],[12],[8,[39,1],[[24,\"placeholder\",\"data\"],[24,0,\"p-1 rounded\"]],[[\"@value\",\"@key-up\"],[[30,0,[\"value\"]],[28,[37,2],[[30,0],[30,0,[\"valueChanged\"]]],null]]],null],[13],[1,\"\\n\"]],[]]],[41,[33,3],[[[1,\"        \"],[10,0],[12],[11,\"button\"],[24,0,\"button-shadow\"],[24,4,\"button\"],[4,[38,2],[[30,0],[30,2],[33,3]],null],[12],[10,1],[14,0,\"glyphicon glyphicon-trash\"],[12],[13],[13],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,0],[12],[11,\"button\"],[24,0,\"button-shadow\"],[24,4,\"button\"],[4,[38,2],[[30,0],[30,2],[33,4]],null],[12],[10,1],[14,0,\"glyphicon glyphicon-trash\"],[12],[13],[13],[13],[1,\"\\n\"]],[]]],[13]],[\"@key\",\"@deleteAction\"],false,[\"if\",\"input\",\"action\",\"row\",\"key\"]]",
    "moduleName": "pickle/templates/components/metadata-editor-row.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});