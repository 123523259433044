define("pickle/templates/components/pills-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KqrezeUm",
    "block": "[[[10,0],[14,0,\"pills\"],[12],[1,\"\\n    \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"        \"],[10,\"li\"],[14,0,\"pill\"],[12],[1,\"\\n          \"],[8,[39,2],null,[[\"@contact\"],[[30,2]]],null],[1,\"\\n\"],[41,[51,[30,3]],[[[1,\"            \"],[10,1],[14,0,\"delete-button\"],[12],[11,\"button\"],[4,[38,4],[[30,0],\"removeItem\",[30,2]],null],[12],[1,\"🗑\"],[13],[13],[1,\"\\n\"]],[]],null],[1,\"        \"],[13],[1,\"\\n\"]],[2]],null],[41,[33,6],[[[1,\"        \"],[10,\"li\"],[14,0,\"new\"],[12],[1,\"\\n            \"],[8,[39,7],[[24,0,\"addPillField dark:text-gray-100\"],[24,\"placeholder\",\"Add a person...\"],[24,\"enter\",\"add\"],[24,4,\"text\"]],[[\"@value\",\"@key-up\"],[[30,0,[\"itemValue\"]],[28,[37,8],[[30,0,[\"autoCompleteAction\"]],[30,0,[\"itemValue\"]]],null]]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[18,4,null],[1,\"\\n\"]],[\"@items\",\"item\",\"@static\",\"&default\"],false,[\"each\",\"-track-array\",\"pill-view-contact\",\"unless\",\"action\",\"if\",\"autoCompleteAction\",\"input\",\"fn\",\"yield\"]]",
    "moduleName": "pickle/templates/components/pills-view.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});