define("pickle/components/address-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    addresss: null,
    urlFormattedAddress: Ember.computed('address', function () {
      var address = "".concat(this.get('address.address_1'), ",").concat(this.get('address.city'), ",").concat(this.get('address.state')); // return `Brooklyn+Bridge,New+York,NY`;

      return address;
    }),
    googleMapStaticImageUrl: Ember.computed('address', function () {
      return "https://maps.googleapis.com/maps/api/staticmap?center=".concat(this.get('urlFormattedAddress'), "\n&markers=color:green%7Clabel:A%7C").concat(this.get('urlFormattedAddress'), "\n&zoom=13\n&size=225x100\n&maptype=roadmap\n&key=AIzaSyADVW_HseI4Ejob100TQizFnLmugIX5Oug");
    }),
    googleMapUrl: Ember.computed('address', function () {
      return "https://maps.google.com/?q=".concat(this.get('urlFormattedAddress'));
    })
  });

  _exports.default = _default;
});