define("pickle/routes/companies/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentAccount: Ember.inject.service('current-account'),
    queryParams: {
      page: {
        refreshModel: true
      },
      size: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var account_id = this.get('currentAccount.currentAccount.id') || window.localStorage.getItem('currentAccountId');

      if (Ember.isPresent(params.account_id)) {
        account_id = params.account_id;
      } else if (Ember.isNone(account_id)) {
        account_id = this.get('currentUser.default_account_id');
      }

      return this.store.query('company', {
        account_id: account_id,
        page: params.page - 1,
        size: params.size
      });
    },
    actions: {
      accountChanged: function accountChanged() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});