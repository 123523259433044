define("pickle/components/contact-pill/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    contact: null,
    actions: {
      removeContact: function removeContact() {
        console.log('remove contact---------');
        this.sendAction('removeContactAction', this.get('contact'));
      }
    }
  });

  _exports.default = _default;
});