define("pickle/components/quick-note-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    type: null,
    description: null,
    didInsertElement: function didInsertElement() {// TODO: figure out tool tips without jquery..
      // Ember.$(function() {
      // 	Ember.$('[data-toggle="tooltip"]').tooltip();
      // });
    },
    actions: {
      handleOnClick: function handleOnClick() {
        this.sendAction('onClick', this.get('type'), this.get('description'));
      }
    }
  });

  _exports.default = _default;
});