define("pickle/templates/reminders/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LdOxej3J",
    "block": "[[[10,0],[14,0,\"flex-column maxed\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"main container\"],[12],[1,\"\\n        \"],[10,\"h1\"],[12],[1,\"Reminders\"],[13],[1,\"\\n        \"],[10,\"table\"],[14,0,\"table table-hover reminders\"],[12],[1,\"\\n            \"],[10,\"tbody\"],[12],[1,\"\\n                \"],[10,\"th\"],[12],[1,\"Name\"],[13],[1,\"\\n                \"],[10,\"th\"],[12],[1,\"Description\"],[13],[1,\"\\n                \"],[10,\"th\"],[12],[1,\"Frequency\"],[13],[1,\"\\n                \"],[10,\"th\"],[12],[1,\"Last\"],[13],[1,\"\\n                \"],[10,\"th\"],[12],[1,\"Next\"],[13],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[1,\"                \"],[10,\"tr\"],[12],[1,\"\\n                    \"],[10,\"td\"],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n                    \"],[10,\"td\"],[12],[1,[30,1,[\"description\"]]],[13],[1,\"\\n                    \"],[10,\"td\"],[12],[10,1],[15,0,[29,[\"frequency-badge \",[30,1,[\"frequency\"]]]]],[12],[1,[30,1,[\"frequency\"]]],[13],[13],[1,\"\\n                    \"],[10,\"td\"],[12],[41,[30,1,[\"last_notification_date\"]],[[[1,[28,[35,4],[[30,1,[\"last_notification_date\"]],\"MMM, DD, YYYY h:mm a\"],null]]],[]],null],[13],[1,\"\\n                    \"],[10,\"td\"],[12],[41,[30,1,[\"next_notification_date\"]],[[[1,[28,[35,4],[[30,1,[\"next_notification_date\"]],\"MMM, DD, YYYY h:mm a\"],null]]],[]],null],[13],[1,\"\\n                    \"],[10,\"td\"],[12],[11,3],[4,[38,5],[[30,0],\"delete\",[30,1]],null],[12],[1,\"Delete\"],[13],[13],[1,\"\\n                \"],[13],[1,\"\\n\"]],[1]],null],[1,\"            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[46,[28,[37,7],null,null],null,null,null],[1,\"\\n\"],[13]],[\"reminder\"],false,[\"each\",\"-track-array\",\"ascendingReminders\",\"if\",\"moment-format\",\"action\",\"component\",\"-outlet\"]]",
    "moduleName": "pickle/templates/reminders/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});