define("pickle/routes/save-search-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      console.log('save-search-modal model()');
    }
  });

  _exports.default = _default;
});