define("pickle/controllers/search/index", ["exports", "pickle/mixins/search-base"], function (_exports, _searchBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_searchBase.default, {
    term: {
      as: 'other-term'
    },
    currentUserService: Ember.inject.service('current-user')
  });

  _exports.default = _default;
});