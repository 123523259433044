define("pickle/templates/components/history-event-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wKDoP7Sp",
    "block": "[[[6,[39,0],null,[[\"route\",\"model\"],[[33,1],[33,2,[\"entity_id\"]]]],[[\"default\"],[[[[1,\"    \"],[10,0],[14,0,\"timeline-block\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"content\"],[12],[1,\"\\n                \"],[10,\"h2\"],[12],[1,[33,2,[\"name\"]]],[13],[1,\"\\n                \"],[10,0],[14,0,\"content-body\"],[12],[1,\"\\n\"],[41,[28,[37,4],[[33,2,[\"entity_type\"]],\"contact\"],null],[[[1,\"                        \"],[1,[28,[35,5],null,[[\"contact_id\"],[[33,2,[\"entity_id\"]]]]]],[1,\"\\n\"]],[]],[[[41,[28,[37,4],[[33,2,[\"entity_type\"]],\"note\"],null],[[[1,\"                        \"],[1,[28,[35,6],null,[[\"note_id\"],[[33,2,[\"entity_id\"]]]]]],[1,\"\\n\"]],[]],[[[1,\"                        \"],[10,2],[12],[1,[28,[35,7],null,[[\"markdown\"],[[33,2,[\"description\"]]]]]],[13],[1,\"\\n                    \"]],[]]]],[]]],[1,\"                \"],[13],[1,\"\\n                \"],[10,0],[14,0,\"content-footer\"],[12],[1,\"\\n                    \"],[10,1],[12],[1,\"By \"],[1,[33,2,[\"user\",\"nickname\"]]],[13],[1,\"\\n                \"],[13],[1,\"\\n            \"],[13],[1,\"\\n            \"],[10,0],[14,0,\"icon-container\"],[12],[1,\"\\n\"],[1,\"                \"],[10,0],[14,0,\"icon\"],[12],[1,\"\\n                    \"],[1,[28,[35,8],null,[[\"user\",\"size\"],[[33,2,[\"user\"]],50]]]],[1,\"\\n                \"],[13],[1,\"\\n            \"],[13],[1,\"\\n            \"],[10,0],[14,0,\"date\"],[12],[1,[28,[35,9],[[33,2,[\"created_at\"]],\"h:mm a\"],null]],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[18,1,null]],[\"&default\"],false,[\"link-to\",\"routeName\",\"event\",\"if\",\"eq\",\"contact-summary\",\"note-summary\",\"markdown-to-html\",\"user-avatar\",\"moment-format\",\"yield\"]]",
    "moduleName": "pickle/templates/components/history-event-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});