define("pickle/templates/components/search-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dgbKnRi6",
    "block": "[[[18,1,null],[1,\"\\n\"],[10,0],[14,0,\"input-group\"],[12],[1,\"\\n\\t\"],[10,\"i\"],[14,0,\"icon-spinner icon-spin icon-large\"],[12],[13],[1,\"\\n\\t\"],[10,\"input\"],[14,0,\"form-control\"],[14,\"placeholder\",\"press / to search\"],[14,3,\"srch-term\"],[14,1,\"search-box\"],[14,4,\"text\"],[12],[13],[1,\"\\n\\t\"],[10,0],[14,0,\"input-group-btn\"],[12],[1,\"\\n\\t\\t\"],[10,\"button\"],[14,0,\"btn btn-default\"],[14,4,\"submit\"],[12],[10,\"i\"],[14,0,\"glyphicon glyphicon-search\"],[12],[13],[13],[1,\"\\n\\t\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "pickle/templates/components/search-box.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});