define("pickle/mixins/form-cancel-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      cancelEdit: function cancelEdit(model, path) {
        model.rollbackAttributes();

        if (this.transitionToRoute) {
          this.transitionToRoute(path, model);
        } else {
          this.transitionTo(path, model);
        }
      }
    }
  });

  _exports.default = _default;
});