define("pickle/controllers/address-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    address: {},
    actions: {
      save: function save() {
        var self = this;
        var address = this.store.createRecord('address', {
          contact_id: self.model.id,
          address_1: this.get('address.address_1'),
          address_2: this.get('address.address_2'),
          city: this.get('address.city'),
          state: this.get('address.state'),
          zip: this.get('address.zip')
        });
        address.save().then(function (result) {
          self.model.get('Addresses').addObject(result);
        });
      }
    }
  });

  _exports.default = _default;
});