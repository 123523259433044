define("pickle/templates/components/progress-bar-onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5eKSJjWz",
    "block": "[[[10,0],[14,0,\"onboarding-progress-bar\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"text\"],[12],[1,\"\\n        \"],[1,[34,0]],[1,\"% Complete\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"progress-percent\"],[15,5,[29,[\"width:\",[36,0],\"%\"]]],[12],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[18,1,null],[1,\"\\n\"]],[\"&default\"],false,[\"progress\",\"yield\"]]",
    "moduleName": "pickle/templates/components/progress-bar-onboarding.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});