define("pickle/templates/components/custom-field-form-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zZpqZdc/",
    "block": "[[[10,0],[15,0,[29,[\"form-group \",[52,[33,1,[\"hasDirtyAttributes\"]],\"highlighted\"]]]],[12],[1,\"\\n\"],[1,\"  \"],[10,0],[14,0,\"col-sm-12\"],[14,5,\"padding-left:0\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"col-sm-4\"],[12],[1,\"\\n      \"],[1,[28,[35,2],null,[[\"class\",\"value\"],[\"form-control\",[33,1,[\"name\"]]]]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"col-sm-3\"],[12],[1,\"\\n      \"],[11,\"select\"],[24,0,\"form-control\"],[24,\"disabled\",\"\"],[4,[38,3],[[30,0],\"setType\"],[[\"on\"],[\"change\"]]],[12],[1,\"\\n        \"],[10,\"option\"],[14,2,\"string\"],[14,\"selected\",\"true\"],[12],[1,\"String\"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"col-sm-3\"],[12],[1,\"\\n      \"],[1,[28,[35,2],null,[[\"class\",\"value\",\"placeholder\"],[\"form-control\",[33,1,[\"value_options\"]],\"comma, separated, values\"]]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"col-sm-1\"],[12],[1,\"\\n      \"],[11,\"button\"],[24,4,\"button\"],[4,[38,3],[[30,0],[33,4],[33,1]],null],[12],[1,\"Remove\"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"data\",\"input\",\"action\",\"removeFieldAction\"]]",
    "moduleName": "pickle/templates/components/custom-field-form-element.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});