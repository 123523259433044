define("pickle/templates/components/array-item-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4H2AUcQX",
    "block": "[[[10,0],[14,0,\"flex-row\"],[12],[1,\"\\n    \"],[1,[28,[35,0],null,[[\"class\",\"key-up\",\"value\"],[\"form-control\",[28,[37,1],[[30,0],\"update\"],null],[33,2]]]]],[1,\"\\n    \"],[10,0],[14,0,\"row-actions\"],[12],[41,[33,4],[[[11,\"button\"],[24,0,\"button-shadow\"],[4,[38,1],[[30,0],[33,4],[33,2]],null],[12],[10,1],[14,0,\"glyphicon glyphicon-trash\"],[12],[13],[13]],[]],null],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[18,1,null]],[\"&default\"],false,[\"input\",\"action\",\"item\",\"if\",\"deleteAction\",\"yield\"]]",
    "moduleName": "pickle/templates/components/array-item-input.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});