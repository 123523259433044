define("pickle/templates/history/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TJ74IfAq",
    "block": "[[[10,0],[14,0,\"flex-column timeline-container\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"container main full-width\"],[12],[1,\"\\n        \"],[10,\"h1\"],[14,0,\"centered\"],[12],[1,\"Recent Activity\"],[13],[1,\"\\n        \"],[10,\"hr\"],[12],[13],[1,\"\\n\"],[1,\"    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"vertical-timeline\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[1,\"            \"],[10,0],[14,0,\"timeline-block-group\"],[12],[1,\"\\n                \"],[10,\"h2\"],[14,0,\"header\"],[12],[1,[28,[35,3],[[30,1,[\"value\"]],\"dddd, MMMM Do\"],null]],[13],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1,[\"items\"]]],null]],null],null,[[[1,\"                    \"],[1,[28,[35,4],null,[[\"event\"],[[30,3]]]]],[1,\"\\n\"]],[3]],null],[1,\"            \"],[13],[1,\"\\n\"]],[1,2]],null],[1,\"    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[46,[28,[37,6],null,null],null,null,null],[1,\"\\n\"]],[\"eventGroup\",\"index\",\"event\"],false,[\"each\",\"-track-array\",\"groupedHistory\",\"moment-format\",\"history-event-item\",\"component\",\"-outlet\"]]",
    "moduleName": "pickle/templates/history/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});