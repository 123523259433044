define("pickle/services/debug-mode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    enabled: Ember.computed('enabled', {
      get: function get() {
        if (window.localStorage.getItem('debug-mode-isEnabled') === 'true') {
          return true;
        } else {
          return false;
        }
      },
      set: function set(key, val) {
        window.localStorage.setItem('debug-mode-isEnabled', val);
        return val;
      }
    })
  });

  _exports.default = _default;
});