define("pickle/templates/components/contact-bubble", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2o9Efj40",
    "block": "[[[10,1],[14,0,\"contact-bubble\"],[12],[1,\"\\n\"],[6,[39,0],null,[[\"route\",\"model\"],[\"contacts.show\",[33,1,[\"id\"]]]],[[\"default\"],[[[[1,\"        \"],[1,[28,[35,2],null,[[\"email\",\"size\",\"class\",\"defaultImage\"],[[33,1,[\"email\"]],22,\"rounded-avatar\",\"retro\"]]]],[1,\"\\n\"],[41,[33,1,[\"nameOrEmail\"]],[[[1,\"            \"],[1,[33,1,[\"nameOrEmail\"]]],[1,\"\\n\"]],[]],[[[1,\"            \"],[1,[33,1,[\"first_name\"]]],[1,\" \"],[1,[33,1,[\"last_name\"]]],[1,\"\\n\"]],[]]],[41,[33,1,[\"opportunity_contacts\",\"role\"]],[[[1,\"            (\"],[1,[33,1,[\"opportunity_contacts\",\"role\"]]],[1,\")\\n\"]],[]],null]],[]]]]],[41,[33,4],[[[1,\"        \"],[10,1],[14,0,\"delete-button\"],[12],[11,\"button\"],[4,[38,5],[[30,0],\"removeContact\",[33,1]],null],[12],[1,\"🗑\"],[13],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"],[18,1,null]],[\"&default\"],false,[\"link-to\",\"contact\",\"gravatar-image\",\"if\",\"removeContact\",\"action\",\"yield\"]]",
    "moduleName": "pickle/templates/components/contact-bubble.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});