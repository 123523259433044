define("pickle/components/file-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // type options:
    // - page_white_icon
    // -
    type: null,
    imagePathForType: Ember.computed('imagePathForType', function () {
      switch (this.get('type')) {
        case 'page_white_acrobat':
          return '/images/icons/pdf.png';

        case 'application/pdf':
          return '/images/icons/pdf.png';

        case 'page_white_text':
          return '/images/icons/txt.png';

        case 'page_white_blank':
          return '/images/icons/_blank.png';

        case 'image/jpeg':
          return '/images/icons/jpg.png';

        case 'page_white_picture':
          return '/images/icons/jpg.png';

        case 'application/msword':
          return '/images/icons/doc.png';

        case 'page_white_word':
          return '/images/icons/doc.png';

        case 'folder':
          return '/images/icons/folder.png';

        default:
          return '/images/icons/_blank.png';
      }
    })
  });

  _exports.default = _default;
});