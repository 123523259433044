define("pickle/components/tag-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    filteredTags: [],
    model: null,
    tagName: null,
    searchForTags: function searchForTags(tagName) {
      var _this = this;

      this.set('tagName', tagName);

      if (tagName.length < 2) {
        return;
      }

      this.get('store').query('tag', {
        account_id: this.get('currentAccount.currentAccount.id')
      }).then(function (tags) {
        var filteredTags = tags.filter(function (tag
        /*, index, self*/
        ) {
          var name = tag.get('tags').toLowerCase(); // exclude existing tags

          if (_this.get('model.tags') && _this.get('model.tags').includes(name)) {
            return false;
          }

          return name.indexOf(tagName) !== -1;
        });

        _this.set('filteredTags', filteredTags);
      });
    },
    actions: {
      selectedTag: function selectedTag(tag) {
        this.send('addTag', tag);
      },
      autoCompleteTags: function autoCompleteTags(tag) {
        Ember.run.debounce(this, this.searchForTags, tag, 300);
      },
      addTag: function addTag(tag) {
        var _this2 = this;

        if (Ember.isEmpty(this.get('model.tags'))) {
          this.set('model.tags', [tag]);
        } else {
          this.get('model.tags').pushObject(tag);
        }

        this.model.save().then(function () {
          // clear the form
          _this2.set('addTagValue', null);

          _this2.set('filteredTags', null);

          _this2.set('tagName', null);
        }).catch(function () {// console.log(error);
          // console.error(error);
        });
      },
      removeTag: function removeTag(tag) {
        var result = window.confirm("Are you sure you want to remove the tag \"".concat(tag, "\"?"));

        if (result) {
          // delete tag from contact
          this.get('model.tags').removeObject(tag);
          this.model.save().then(function () {}).catch(function () {// console.log(error);
          });
        }
      }
    }
  });

  _exports.default = _default;
});