define("pickle/templates/components/contact-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r0KJDzdl",
    "block": "[[[18,1,null],[1,\"\\n\\n\"],[1,[28,[35,1],null,[[\"contact\"],[[33,2]]]]]],[\"&default\"],false,[\"yield\",\"simple-contact-cell\",\"contact\"]]",
    "moduleName": "pickle/templates/components/contact-summary.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});