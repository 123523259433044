define("pickle/controllers/save-search-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentAccount: Ember.inject.service(),
    search: Ember.object(),
    init: function init() {
      this.set('search', this.store.createRecord('search'));

      this._super.apply(this, arguments);
    },
    tags: Ember.computed('model', function () {
      var params = this.get('model.currentSearch');

      if (!Ember.isNone(params)) {
        return params.tags;
      } else {
        return null;
      }
    }),
    searchString: Ember.computed('model', function () {
      var params = this.get('model');

      if (!Ember.isNone(params)) {
        this.set('search.search_string', params.currentSearch.query);
        return params.currentSearch.query;
      } else {
        return null;
      }
    }),
    actions: {
      save: function save() {
        var self = this;
        var controller = this.get('model.controller'); // passed in with the hash in search.js

        var search = this.get('search');
        search.set('account_id', this.get('currentAccount.currentAccount.id'));
        search.set('tags', this.get('tags'));
        search.save().then(function () {
          self.store.peekAll('search').addObject(self.search);
          controller.send('customSearchSavingHasFinished'); // reset this controller after save

          self.set('model', null);
          self.set('searchString', null);
          self.init();
        });
      }
    }
  });

  _exports.default = _default;
});