define("pickle/adapters/message", ["exports", "pickle/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    // namespace: 'groups/:group_id'
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var group_id = null;

      if (query) {
        group_id = query.group_id ? query.group_id : null;
      }

      return "".concat(this._super(), "/groups/").concat(group_id, "/messages");
    }
  });

  _exports.default = _default;
});