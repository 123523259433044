define("pickle/controllers/slack-create-modal", ["exports", "pickle/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    channelName: '',
    init: function init() {//this.set('channelName', this.get('model.name'));
    },
    actions: {
      createSlackChannel: function createSlackChannel() {
        var flashMessages = Ember.get(this, 'flashMessages');
        var token = localStorage.getItem('userAccessToken');
        var self = this;
        Ember.$.ajax(_environment.default.apiHost + '/opportunities/' + self.get('model.id') + '/slack/create?channelName=' + this.get('channelName') + '', {
          headers: {
            'x-access-token': token
          }
        }).done(function (res) {
          if (res === 'name_taken') {
            flashMessages.danger('Name already taken, please try a different slack channel name');
          } else {
            flashMessages.success('Wahoo, time to go chat!');
            self.get('model').set('slack_channel_jsonb', res.opportunity.slack_channel_jsonb);
          }
        });
      }
    }
  });

  _exports.default = _default;
});