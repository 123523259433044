define("pickle/templates/components/add-selected-contacts-to-group-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o68mHwhN",
    "block": "[[[41,[33,1],[[[1,\"    \"],[10,1],[14,0,\"highlight\"],[12],[1,[33,1,[\"length\"]]],[1,\" contacts selected\"],[13],[1,\"\\n    \"],[10,\"select\"],[14,3,\"selectedGroup\"],[14,1,\"selectedGroup\"],[15,\"onchange\",[28,[37,2],[[30,0],[28,[37,3],[[33,4]],null]],[[\"value\"],[\"target.value\"]]]],[12],[1,\"\\n    \"],[10,\"option\"],[14,\"disabled\",\"true\"],[14,\"selected\",\"\"],[12],[1,\"Groups\"],[13],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[33,7]],null]],null],null,[[[1,\"        \"],[10,\"option\"],[15,2,[29,[[30,1,[\"id\"]]]]],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n\"],[41,[51,[33,9]],[[[1,\"        \"],[11,\"button\"],[24,0,\"button-shadow green small\"],[4,[38,2],[[30,0],\"addSelectedContactsToGroup\"],null],[12],[1,\"Add to Group\"],[13],[1,\" or \"],[11,3],[24,5,\"cursor:pointer;\"],[4,[38,2],[[30,0],\"clear\"],null],[12],[1,\"clear\"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,0],[14,0,\"loading-spinner-sm\"],[12],[13],[1,\"\\n\"]],[]]]],[]],null],[1,\"\\n\"],[18,2,null]],[\"group\",\"&default\"],false,[\"if\",\"checkedContacts\",\"action\",\"mut\",\"selectedGroupId\",\"each\",\"-track-array\",\"groups\",\"unless\",\"isLoading\",\"yield\"]]",
    "moduleName": "pickle/templates/components/add-selected-contacts-to-group-form.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});