define("pickle/templates/components/simple-contact-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PbnKqxa6",
    "block": "[[[6,[39,0],null,[[\"route\",\"model\"],[\"contacts.show\",[33,1,[\"id\"]]]],[[\"default\"],[[[[10,0],[15,0,[29,[\"flex rounded-md p-3 mb-3 \",[30,1],\" hover:bg-purple-300 dark:bg-smoke-100 dark-hover:bg-purple-800\"]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"avatar h-16 w-16 rounded-full mx-auto mr-3 \"],[12],[1,\"\\n        \"],[8,[39,2],null,[[\"@contact\",\"@textSize\"],[[30,2],\"text-2xl\"]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"flex-col\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"font-bold text-gray-800 dark:text-cream\"],[12],[1,\"\\n      \"],[1,[34,3]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[12],[1,\"\\n\"],[41,[51,[33,5]],[[[1,\"        \"],[10,1],[14,0,\"gray-text\"],[12],[1,[28,[35,6],[[33,1,[\"updated_at\"]]],null]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n    \"],[18,3,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[]]]]]],[\"@extraClass\",\"@contact\",\"&default\"],false,[\"link-to\",\"contact\",\"contact-avatar-image\",\"displayName\",\"unless\",\"hideContactUpdatedAt\",\"moment-from-now\",\"yield\"]]",
    "moduleName": "pickle/templates/components/simple-contact-cell.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});