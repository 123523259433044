define("pickle/models/subscription", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    has_current_plan: _emberData.default.attr('boolean'),
    current_plan: _emberData.default.attr(),
    card: _emberData.default.attr(),
    next_bill_date: _emberData.default.attr(),
    next_bill_date_human: Ember.computed('next_bill_date', function () {
      return _moment.default.unix(this.get('next_bill_date')).format("MM/DD/YYYY");
    })
  });

  _exports.default = _default;
});