define("pickle/mixins/search-base", ["exports", "pickle/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Mixin.create({
    currentAccount: Ember.inject.service(),
    searchValue: null,
    isSearching: false,
    useAllCustomFields: false,
    customFields: null,
    // Put these in every place where mixin is included
    // queryParams: ['term'],
    // term: null,
    quantity: 15,
    first_name: null,
    last_name: null,
    email: null,
    phone_number: null,
    background_info: null,
    custom_fields_query: null,
    custom_fields_array: [],
    selectedTags: [],
    store: Ember.inject.service(),
    queryObjects: [],
    // list of objects used for v2 search
    isLoadingTags: false,
    handleTermChange: Ember.observer('term', function () {
      // weird issue where between keypresses it sometimes is undefined, we can ignore it here:
      if (this.get('term') !== 'undefined') {
        this.set('searchValue', this.get('term')); // this.send('performSearch');
      }
    }),
    shouldShowSaveSearchButton: Ember.computed('selectedTags.@each', 'term', function () {
      if (this.get('selectedTags.length') > 0 || !Ember.isNone(this.get('term'))) {
        return true;
      } else {
        return false;
      }
    }),
    updateSearchHash: function updateSearchHash() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          query = _ref.query,
          tags = _ref.tags,
          queryObjects = _ref.queryObjects;

      var jsonString = JSON.stringify({
        query: query,
        tags: tags,
        queryObjects: queryObjects
      });
      this.set('searchHash', window.btoa(jsonString));
      window.sessionStorage.setItem('searchHash', this.get('searchHash'));
    },
    doSearchForDebounceV2: function doSearchForDebounceV2() {
      this.searchWithValuesV2({
        query: this.get('searchValue'),
        tags: this.get('selectedTags'),
        queryObjects: this.get('queryObjects')
      });
      this.updateSearchHash({
        query: this.get('searchValue'),
        tags: this.get('selectedTags'),
        queryObjects: this.get('queryObjects')
      });
    },
    doSearchForDebounce: function doSearchForDebounce() {
      this.searchWithValues({
        query: this.get('searchValue'),
        tags: this.get('selectedTags'),
        searchCustomFields: this.get('useAllCustomFields'),
        custom_fields_with_values: this.get('custom_fields_query')
      });
    },
    buildCustomFieldsString: function buildCustomFieldsString(key, value) {
      var string_for_query = '';

      if (!Ember.isNone(key)) {
        var arr = this.get('custom_fields_array');

        if (!Ember.isEmpty(value)) {
          arr[key] = value;
        } else {
          delete arr[key];
        } // convert to string


        for (var _key in arr) {
          if (arr.hasOwnProperty(_key)) {
            var element = arr[_key];
            string_for_query += "".concat(_key, ":").concat(element, ",");
          }
        }
      }

      var string_length = string_for_query.length;

      if (string_for_query[string_length - 1] == ',') {
        string_for_query = string_for_query.slice(0, -1);
      }

      return string_for_query;
    },
    // Builds the query object array that's used for /search/v2
    updateQueryObjects: function updateQueryObjects() {
      var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          columnName = _ref2.columnName,
          modifier = _ref2.modifier,
          inputValue = _ref2.inputValue,
          updateOnly = _ref2.updateOnly;

      var queryObjects = this.get('queryObjects');
      var obj = queryObjects.find(function (element) {
        return element.key == columnName;
      });

      if (!obj && !updateOnly) {
        obj = Ember.Object.create({
          // using ember.object.create to use Ember objects to the view is updated (set/get)
          key: columnName,
          modifier: modifier || 'equals',
          value: inputValue || ''
        });
        queryObjects.pushObject(obj);
      } else {
        [{
          key: columnName
        }, {
          modifier: modifier
        }, {
          value: inputValue
        }].forEach(function (element) {
          var key = Object.keys(element)[0];

          if (element[key] !== undefined) {
            obj.set(key, element[key]); // notify properties changes so the view will also be updated
            // obj[key] = element[key]; // doesn't notify property change to ember
          }
        });
      }

      this.set('queryObjects', queryObjects);
    },
    restore: function restore() {
      this.restoreFromEncodedString(this.get('searchHash'));
    },
    restoreFromEncodedString: function restoreFromEncodedString(encodedString) {
      var restoredSearch = JSON.parse(atob(encodedString));
      var queryObjects = []; // convert to ember objects so we can use Object.set and observers.

      var _iterator = _createForOfIteratorHelper(restoredSearch.queryObjects),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var obj = _step.value;
          queryObjects.pushObject(new Ember.Object(obj));
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      this.set('queryObjects', queryObjects);
      this.set('term', restoredSearch.query);
      this.set('selectedTags', restoredSearch.tags);
    },
    actions: {
      changedThing: function changedThing(inputValue, event) {
        if (Ember.isEmpty(inputValue)) {
          this.set(event.target.name, null);
        }

        var columnName = event.target.name; // TODO: update storage in local storage to use the new base64 encoded version
        // TODO: Remove this query param, it's no longer necessary and doesn't make sense

        var custom_fields_string = this.buildCustomFieldsString(columnName, inputValue);
        this.set('custom_fields_query', custom_fields_string);
        this.updateQueryObjects({
          columnName: columnName,
          inputValue: inputValue
        });
        Ember.run.debounce(this, this.doSearchForDebounceV2, 300);
      },
      updateModifier: function updateModifier(column, modifier) {
        this.updateQueryObjects({
          columnName: column.name,
          modifier: modifier
        });
        Ember.run.debounce(this, this.doSearchForDebounceV2, 300);
      },
      addTag: function addTag(tagName) {
        this.get('selectedTags').pushObject(tagName);
        this.send('performSearch');
      },
      removeTag: function removeTag(tagName) {
        this.get('selectedTags').removeObject(tagName);
        this.send('performSearch');
      },
      clearAllTags: function clearAllTags() {
        this.set('selectedTags', []);
      },
      performSearch: function performSearch() {
        Ember.run.debounce(this, this.doSearchForDebounceV2, 300);
      },
      performSavedSearch: function performSavedSearch(savedSearch) {
        var self = this;
        this.send('clearAllTags');
        savedSearch.get('tags').forEach(function (tag) {
          self.send('addTag', tag);
        });
        this.set('searchValue', savedSearch.get('search_string'));
        this.searchWithValues({
          query: savedSearch.get('search_string'),
          tags: savedSearch.get('tags'),
          useCustomFields: false
        });
      },
      saveCurrentSearch: function saveCurrentSearch() {
        var currentSearch = {
          query: this.get('searchValue'),
          tags: this.get('selectedTags'),
          useAllCustomFields: this.get('useAllCustomFields')
        };
        this.send('showModal', 'save-search-modal', {
          model: this.get('model'),
          currentSearch: currentSearch,
          controller: this
        }); // comes from the application controller
      },
      deleteSavedSearch: function deleteSavedSearch(search) {
        search.destroyRecord().then(function () {// console.log('deleted!');
        });
      },
      removeFilter: function removeFilter(object) {
        this.get('queryObjects').removeObject(object);
        this.doSearchForDebounceV2();
      }
    },
    searchWithValues: function searchWithValues() {
      var _ref3 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          query = _ref3.query,
          tags = _ref3.tags,
          useCustomFields = _ref3.useCustomFields,
          custom_fields_with_values = _ref3.custom_fields_with_values;

      this.set('isSearching', true);
      var searchParams = {
        "q": query
      };
      var currentAccount = this.get('currentAccount.currentAccountId');
      searchParams.account_id = this.get("currentAccount.currentAccount.id");

      if (useCustomFields) {
        searchParams['useAllCustomFields'] = true; // create a string list

        var fields = this.get('customFields').toArray();
        var stringFields = [];
        fields.forEach(function (f) {
          stringFields.push(encodeURIComponent(f.get('name')));
        });
        searchParams['searchCustomFields'] = stringFields.toString();
      }

      if (custom_fields_with_values) {
        searchParams['custom_fields_with_values'] = custom_fields_with_values;
      }

      if (!Ember.isEmpty(tags)) {
        searchParams['tags'] = tags;
      }

      searchParams['quantity'] = this.get('quantity');
      this.set('searchParams', searchParams);
      var self = this;
      var url = "".concat(_environment.default.apiHost, "/search?q=").concat(searchParams.q, "&accountId=").concat(searchParams.account_id);
      fetch(url, {
        credentials: 'include',
        method: 'get'
      }).then(function (response) {
        return response.json();
      }).then(function (response) {
        self.set('isSearching', false); //console.log(response);

        if (self.store) {
          self.get('store').pushPayload('contact', response);
        }

        self.send('searchCompleted', response);
      });
    },
    searchWithValuesV2: function searchWithValuesV2() {
      var _this = this;

      var _ref4 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          query = _ref4.query,
          tags = _ref4.tags,
          useCustomFields = _ref4.useCustomFields,
          queryObjects = _ref4.queryObjects;

      this.set('isSearching', true);
      var searchParams = {
        "q": query
      };
      searchParams.account_id = this.get("currentAccount.currentAccount.id"); // if (!Ember.isEmpty(tags)) {
      //   searchParams['tags'] = tags;
      // }

      searchParams['quantity'] = this.get('quantity');
      this.set('searchParams', searchParams);
      var url = _environment.default.apiHost + "/search/v2";
      fetch(url, {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          'queries': queryObjects,
          'queryString': query,
          'account_id': this.get('currentAccount.currentAccount.id'),
          'quantity': this.get("quantity"),
          'tags': this.get('selectedTags')
        })
      }).then(function (response) {
        return response.json();
      }).then(function (response) {
        _this.set('isSearching', false);

        if (_this.store) {
          _this.get('store').pushPayload('contact', response);
        }

        _this.send('searchCompleted', response);
      });
    }
  });

  _exports.default = _default;
});