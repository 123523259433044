define("pickle/routes/admin/slack", ["exports", "pickle/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    account: Ember.inject.service('current-account'),
    model: function model() {
      return this.store.findAll('account');
    },
    afterModel: function afterModel(model
    /*, transition*/
    ) {
      this.get('account').set('accounts', model);
      this.get('account').set('currentAccount', model.get('firstObject'));
    },
    setupController: function setupController(controller
    /*, model*/
    ) {
      controller.set('account', this.get('account'));
      controller.set('apiHost', _environment.default.apiHost);
    },
    actions: {
      accountChanged: function accountChanged() {
        var _this = this;

        // request new data
        this.set('controller.isLoadingAccount', true);
        this.store.find('account', this.get('account.currentAccount.id')).then(function () {
          _this.set('controller.isLoadingAccount', false);
        });
      }
    }
  });

  _exports.default = _default;
});