define("pickle/controllers/reminders/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sortingOrder: ['created_at:asc'],
    ascendingReminders: Ember.computed.sort('model', 'sortingOrder'),
    actions: {
      delete: function _delete(reminder) {
        var confirm = window.confirm("Are you sure you want to delete ".concat(reminder.get('name'), "? This action cannot be undone."));

        if (confirm) {
          reminder.destroyRecord().then(function () {// console.log('done!');
          });
        }
      }
    }
  });

  _exports.default = _default;
});