define("pickle/templates/components/tag-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "a4I8AsCM",
    "block": "[[[41,[51,[30,1]],[[[10,\"h4\"],[14,0,\"mb-2 dark:text-cream\"],[12],[1,\"Tags\"],[13]],[]],null],[1,\"\\n\\n\"],[10,0],[14,0,\"tags mb-5\"],[12],[1,\"\\n    \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[33,3,[\"tags\"]]],null]],null],null,[[[1,\"        \"],[8,[39,4],null,[[\"@tag\",\"@removeTag\"],[[30,2],[28,[37,5],[[30,0],\"removeTag\"],null]]],null],[1,\"\\n\"]],[2]],null],[1,\"    \"],[10,\"li\"],[14,0,\"tag\"],[12],[1,\"\\n        \"],[1,[28,[35,6],null,[[\"class\",\"type\",\"placeholder\",\"enter\",\"value\",\"key-up\"],[\"addTagField standard-input\",\"text\",\"type tag name then press enter/return\",[28,[37,5],[[30,0],\"addTag\"],null],[33,7],[28,[37,5],[[30,0],\"autoCompleteTags\"],null]]]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\\n\"],[41,[33,9,[\"length\"]],[[[1,\"    \"],[1,[28,[35,10],null,[[\"tagName\",\"results\",\"selectedTag\"],[[33,9],[33,11],[28,[37,5],[[30,0],\"selectedTag\"],null]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[18,3,null],[1,\"\\n\"]],[\"@hideTitle\",\"tag\",\"&default\"],false,[\"unless\",\"each\",\"-track-array\",\"model\",\"tag-pill\",\"action\",\"input\",\"addTagValue\",\"if\",\"tagName\",\"tag-search-results-popover\",\"filteredTags\",\"yield\"]]",
    "moduleName": "pickle/templates/components/tag-list.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});