define("pickle/templates/components/sms-composer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NOY5IAgm",
    "block": "[[[10,0],[14,0,\"group-message-composer\"],[12],[1,\"\\n\"],[1,\"    \"],[10,0],[14,0,\"contacts-selector\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"header\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"left\"],[12],[1,\"Send to: \"],[1,[33,0,[\"first_name\"]]],[1,\" \"],[1,[33,0,[\"last_name\"]]],[1,\" \"],[1,[28,[35,1],[[33,0,[\"phone_number\"]]],null]],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"hr\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"composer\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"header\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"left\"],[12],[1,\"Message: \"],[13],[1,\"\\n            \"],[10,0],[14,0,\"right\"],[12],[1,\"\\n                \"],[41,[28,[37,3],[[33,4],\"sms\"],null],[[[1,\" Characters: \"],[1,[33,5,[\"length\"]]],[1,\"/160 \"]],[]],null],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[12],[1,\"\\n            \"],[1,[28,[35,6],null,[[\"class\",\"value\",\"keyUp\",\"maxlength\"],[\"pretty-textarea full-width\",[33,5],[28,[37,7],[[30,0],\"messageChanged\"],[[\"value\"],[\"target.value\"]]],\"160\"]]]],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"hr\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"actions\"],[12],[1,\"\\n\"],[41,[33,8],[[[1,\"            Sending... \\n\"]],[]],[[[1,\"            \"],[11,\"button\"],[24,0,\"button-shadow green\"],[16,\"disabled\",[36,9]],[4,[38,7],[[30,0],\"sendMessage\"],null],[12],[1,\"Send Message\"],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[18,1,null]],[\"&default\"],false,[\"contact\",\"phone-number\",\"if\",\"eq\",\"sendVia\",\"messageContent\",\"textarea\",\"action\",\"isNotifying\",\"sendButtonEnabled\",\"yield\"]]",
    "moduleName": "pickle/templates/components/sms-composer.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});