define("pickle/routes/opportunities/edit", ["exports", "pickle/mixins/form-cancel-edit"], function (_exports, _formCancelEdit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_formCancelEdit.default, {
    model: function model(params) {
      return this.store.findRecord('opportunity', params.opportunity_id);
    }
  });

  _exports.default = _default;
});