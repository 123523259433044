define("pickle/adapters/tag", ["exports", "pickle/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    buildURL: function buildURL() {
      return "".concat(this._super(), "/contacts/tags");
    }
  });

  _exports.default = _default;
});