define("pickle/routes/pipelines/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentAccount: Ember.inject.service('current-account'),
    model: function model() {
      var account_id = this.get('currentAccount.currentAccount.id') || window.localStorage.getItem('currentAccountId') || this.get('currentUser.default_account_id');
      return this.store.createRecord('pipeline', {
        account_id: account_id
      });
    }
  });

  _exports.default = _default;
});