define("pickle/models/history", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    user: _emberData.default.belongsTo('user'),
    description: _emberData.default.attr(),
    created_at: _emberData.default.attr('date'),
    updated_at: _emberData.default.attr('date'),
    entity_type: _emberData.default.attr('string'),
    entity_id: _emberData.default.attr(),
    entity_uuid: _emberData.default.attr(),
    smartDescription: _emberData.default.attr(),
    iconBackground: Ember.computed('entity_type', function () {
      var type = this.get('entity_type');

      switch (type) {
        case 'file':
          return 'green';

        case 'note':
          return 'purple';

        case 'contact':
          return 'blue';

        default:
          return 'white';
      }
    }),
    created_at_without_time: Ember.computed('created_at', function () {
      if (this.get('created_at')) {
        return this.get('created_at').toDateString();
      } else {
        return null;
      }
    })
  });

  _exports.default = _default;
});