define("pickle/components/clickable-tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isSelected: false,
    tagName: null,
    selectedTags: null,
    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get('selectedTags').length > 0) {
        var selectedTags = this.get('selectedTags');
        var tagName = this.get('tag.tags');

        if (selectedTags.indexOf(tagName) !== -1) {
          this.set('isSelected', true);
        } else {
          this.set('isSelected', false);
        }
      } else {
        this.set('isSelected', false);
      }
    },
    actions: {
      toggleTag: function toggleTag(tagName) {
        if (this.get('isSelected')) {
          this.sendAction('removeAction', tagName);
        } else {
          this.sendAction('addAction', tagName);
        }

        this.toggleProperty('isSelected');
      }
    }
  });

  _exports.default = _default;
});