define("pickle/helpers/checked-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.checkedValue = checkedValue;
  _exports.default = void 0;

  function checkedValue(params
  /*, hash*/
  ) {
    if (params[0]) {
      return 'checked';
    } else {
      return '';
    }
  }

  var _default = Ember.Helper.helper(checkedValue);

  _exports.default = _default;
});