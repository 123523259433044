define("pickle/components/tag-search-results-popover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: null,
    // needle
    results: null,
    // results
    actions: {
      selectTag: function selectTag(tag) {
        this.sendAction('selectedTag', tag.get('tags'));
        this.set('results', null);
        this.set('tagName', null);
      }
    }
  });

  _exports.default = _default;
});