define("pickle/components/capture-name-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    firstName: '',
    lastName: '',
    isSavingName: false,
    actions: {
      nameUpdated: function nameUpdated() {
        if (Ember.isNone(this.get('firstName'))) {
          Ember.$('input[type=submit]').first().val('Call me _________');
        } else {
          Ember.$('input[type=submit]').first().val("Call me ".concat(this.get('firstName'), " ").concat(this.get('lastName')));
        }
      },
      saveName: function saveName() {
        this.sendAction('save-action', this.get('firstName'), this.get('lastName'));
      }
    }
  });

  _exports.default = _default;
});