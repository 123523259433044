define("pickle/mixins/checked-contacts-for-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    checkedContacts: [],
    selectedGroupId: null,
    actions: {
      finishedAddingContactsToGroup: function finishedAddingContactsToGroup(id) {
        this.transitionToRoute('groups.show', id);
      },
      clearCheckedContacts: function clearCheckedContacts(contacts) {
        contacts.setEach('isChecked', false);
        this.set('checkedContacts', []);
      }
    }
  });

  _exports.default = _default;
});