define("pickle/templates/components/opportunities-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/ZSlpk9Z",
    "block": "[[[10,0],[14,0,\"flex-row container-header bordered margin-top-sm\"],[12],[1,\"\\n    \"],[10,\"h5\"],[12],[1,\"Opportunities\"],[13],[1,\"\\n    \"],[10,0],[14,0,\"right\"],[12],[1,\"\\n\"],[1,\"    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"margin-top\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"flex flex-row flex-wrap\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[1,\"            \"],[10,0],[14,0,\"opportunity-cell-flex\"],[12],[1,\"\\n            \"],[1,[28,[35,3],null,[[\"opportunity\"],[[30,1]]]]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\\n\\n\"],[18,2,null]],[\"opportunity\",\"&default\"],false,[\"each\",\"-track-array\",\"opportunities\",\"simple-opportunity-cell\",\"yield\"]]",
    "moduleName": "pickle/templates/components/opportunities-panel.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});