define("pickle/templates/admin/slack", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SfHI2a/G",
    "block": "[[[10,0],[14,0,\"main container\"],[12],[1,\"\\n  \"],[46,[28,[37,1],null,null],null,null,null],[1,\"\\n  \"],[10,0],[14,0,\"col-xs-12 col-md-12\"],[12],[1,\"\\n    \"],[10,\"h4\"],[12],[1,\"Slack Settings\"],[13],[1,\"\\n    \"],[10,2],[12],[1,\"Your admin will set this part up. The token is required so CRM can talk to your Slack account.\"],[13],[1,\"\\n\"],[41,[33,3],[[[1,\"      \"],[10,0],[14,0,\"loading-indicator\"],[12],[1,\"\\n        \"],[10,0],[14,1,\"holder\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"dot\"],[12],[13],[1,\"\\n          \"],[10,0],[14,0,\"pulse\"],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[41,[33,4,[\"currentAccount\",\"has_slack_account\"]],[[[1,\"      \"],[10,2],[12],[1,\"Looks like your account is all setup!\"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,3],[15,6,[29,[\"https://slack.com/oauth/authorize?scope=incoming-webhook,commands,admin,channels:write,channels:history,channels:read,files:read,pins:write,pins:read,users:read,chat:write:bot,chat:write:user&client_id=16129442194.19597985506&state=\",[33,5,[\"id\"]],\"_\",[33,4,[\"currentAccount\",\"id\"]],\"&redirect_uri=\",[36,6],\"/webhooks/slack\"]]],[12],[10,\"img\"],[14,\"alt\",\"Add to Slack\"],[14,\"height\",\"40\"],[14,\"width\",\"139\"],[14,\"src\",\"https://platform.slack-edge.com/img/add_to_slack.png\"],[14,\"srcset\",\"https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x\"],[12],[13],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"component\",\"-outlet\",\"if\",\"isLoadingAccount\",\"account\",\"currentUser\",\"apiHost\"]]",
    "moduleName": "pickle/templates/admin/slack.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});