define("pickle/models/inbound-email-address", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    user: _emberData.default.belongsTo('user'),
    account: _emberData.default.belongsTo('Account'),
    emailAddress: _emberData.default.attr('string'),
    isEnabled: _emberData.default.attr(),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date')
  });

  _exports.default = _default;
});