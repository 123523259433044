define("pickle/routes/companies/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord('company', params.id);
    },
    afterModel: function afterModel(company, transition) {
      this._super(company, transition);
    },
    setupController: function setupController(controller, company) {
      var _this = this;

      this._super(controller, company);

      this.set('controller.opportunities', null);
      this.fetchEmployees(company).then(function (employees) {
        // fetch opportunities for each employee
        if (employees.get('meta.total') > 0) {
          _this.fetchOpportunities(company, employees);
        }
      });
      this.fetchNotes(company);
      this.fetchUsers(company);
    },
    fetchEmployees: function fetchEmployees(company) {
      var _this2 = this;

      // fetch employees
      this.set('controller.thingsLoading.employees', true);
      return this.store.query('contact', {
        company_id: company.id,
        account_id: company.get('account.id')
      }).then(function (contacts) {
        _this2.set('controller.thingsLoading.employees', false);

        _this2.set('controller.contacts', contacts);

        return contacts;
      });
    },
    fetchOpportunities: function fetchOpportunities(company, contacts) {
      var _this3 = this;

      this.set('controller.thingsLoading.opportunities', true);
      console.log('fetch opportunities');

      if (contacts) {
        // pluck ids
        var contact_ids = contacts.map(function (c) {
          return c.id;
        });
        console.log(contact_ids);
        console.log(contact_ids.toString());
        this.store.query('opportunity', {
          contact_ids: contact_ids.toString(),
          account_id: company.get('account.id')
        }).then(function (opportunities) {
          _this3.set('controller.thingsLoading.opportunities', false);

          _this3.set('controller.opportunities', opportunities);
        });
      }
    },
    fetchNotes: function fetchNotes(company) {
      var _this4 = this;

      this.set('controller.thingsLoading.notes', true);
      this.store.query('Note', {
        company_id: company.id,
        account_id: company.get('account.id')
      }).then(function (notes) {
        _this4.set('controller.thingsLoading.notes', false);

        company.set('Notes', notes);
      });
    },
    fetchUsers: function fetchUsers(company) {
      var _this5 = this;

      this.set('controller.thingsLoading.users', true); // fetch users for @mentions

      this.store.query('user', {
        account_id: company.get('account.id')
      }).then(function (users) {
        _this5.set('controller.thingsLoading.users', false);

        _this5.set('controller.users', users);
      });
    },
    actions: {
      accountChanged: function accountChanged() {
        this.transitionTo('companies.index');
      }
    }
  });

  _exports.default = _default;
});