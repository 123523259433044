define("pickle/components/loading-spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="flex justify-center mb-3">
      <div class="loading-spinner"></div>
  </div>
  {{yield}}
  */
  {
    "id": "7LW+vJEX",
    "block": "[[[10,0],[14,0,\"flex justify-center mb-3\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"loading-spinner\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[18,1,null]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "pickle/components/loading-spinner.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});