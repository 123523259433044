define("pickle/serializers/note", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _attrs;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: (_attrs = {
      User: 'user_id',
      contact: 'contact_id'
    }, _defineProperty(_attrs, "contact", {
      deserialize: 'records'
    }), _defineProperty(_attrs, "contacts", {
      embedded: 'always'
    }), _defineProperty(_attrs, "Opportunity", 'opportunity_id'), _defineProperty(_attrs, "company", 'company_id'), _defineProperty(_attrs, "company", {
      deserialize: 'records'
    }), _attrs)
  });

  _exports.default = _default;
});