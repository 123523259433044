define("pickle/templates/groups/_form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CC1upYUP",
    "block": "[[[10,0],[14,0,\"col-xs-12 opportunity-form\"],[12],[1,\"\\n\\t\"],[11,\"form\"],[4,[38,0],[[30,0],\"save\"],[[\"on\"],[\"submit\"]]],[12],[1,\"\\n\\t\\t\"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n\\t\\t\\t\"],[10,\"label\"],[14,\"for\",\"exampleInputEmail1\"],[12],[1,\"Name: \"],[13],[1,\"\\n\\t\\t\\t\"],[1,[28,[35,1],null,[[\"value\",\"class\",\"placeholder\",\"autofocus\"],[[33,2,[\"name\"]],\"form-control name\",\"your group name here\",\"autofocus\"]]]],[1,\"\\n\\t\\t\"],[13],[1,\"\\n\"],[41,[33,4],[[[1,\"\\t\\t\"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n\\t\\t\\t\"],[10,\"label\"],[14,\"for\",\"exampleInputEmail1\"],[12],[1,\"Status: \"],[13],[1,\"\\n\\t\\t\\t\"],[11,\"select\"],[24,1,\"opportunity_status\"],[4,[38,0],[[30,0],\"\"],[[\"on\"],[\"change\"]]],[12],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[33,7]],null]],null],\"@index\",[[[1,\"\\t\\t\\t\\t\"],[10,\"option\"],[15,2,[29,[[30,1]]]],[15,\"selected\",[28,[37,8],[[30,1],[33,9]],null]],[12],[1,\"\\n\\t\\t\\t\\t\\t\"],[1,[30,1]],[1,\"\\n\\t\\t\\t\\t\"],[13],[1,\"\\n\"]],[1]],null],[1,\"\\t\\t\\t\"],[13],[1,\"\\n\\t\\t\"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\\t\\t\"],[10,0],[14,0,\"form-actions\"],[12],[1,\"\\n\\t\\t\\t\"],[10,\"button\"],[14,0,\"button-shadow green\"],[14,4,\"submit\"],[12],[1,\"Save\"],[13],[1,\"\\n\\t\\t\\t\"],[41,[33,2,[\"id\"]],[[[10,0],[14,0,\"cancel\"],[12],[1,\" or \"],[11,3],[24,6,\"#\"],[4,[38,0],[[30,0],\"cancelEdit\",[33,2],\"groups.show\"],null],[12],[1,\"cancel\"],[13],[13]],[]],null],[1,\"\\n\\t\\t\"],[13],[1,\"\\n\\t\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"item\"],false,[\"action\",\"input\",\"model\",\"if\",\"isNew\",\"each\",\"-track-array\",\"status_values\",\"is-equal\",\"selectedValue\"]]",
    "moduleName": "pickle/templates/groups/_form.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});