define("pickle/routes/opportunities/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    opportunity: null,
    opportunity_id: null,
    account: Ember.inject.service('current-account'),
    model: function model(params
    /*,transition*/
    ) {
      var account_id = this.get('account.currentAccount.id') || window.localStorage.getItem('currentAccountId');
      this.set('opportunity_id', params.opportunity_id);
      return this.store.queryRecord('opportunity', {
        id: params.opportunity_id,
        account_id: account_id
      });
    },
    afterModel: function afterModel(model, controller) {
      this.set('slackChannelName', null);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('status_values', this.controllerFor('opportunities').get('status_values'));
      controller.set('opportunity', model);
      controller.set('model', model);
      controller.set('dropbox_path', "/better-crm/cases/" + model.get('id')); // get cases that we can add to

      controller.set('cases', this.store.query('opportunity', {
        account_id: model.get('account.id'),
        type: 'case'
      })); // fetch users for @mentions

      this.store.query('user', {
        account_id: model.get('account.id')
      }).then(function (users) {
        controller.set('users', users);
      });
    },
    actions: {
      accountChanged: function accountChanged() {
        this.transitionTo('opportunities.index');
      }
    }
  });

  _exports.default = _default;
});