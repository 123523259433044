define("pickle/adapters/timeline-step", ["exports", "pickle/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForQuery: function urlForQuery(query
    /*, modelName*/
    ) {
      var baseUrl = this.buildURL();
      return "".concat(baseUrl, "/timelines/").concat(query.opportunity_id, "/steps"); // return this._super(...arguments);
    },
    // findRecord(store, thype, id, snapshot) {
    //     let baseUrl = this.buildURL();
    //     let url = `${baseUrl}/timelines/`;
    //     console.log('find record');
    //     return this._super(...arguments);
    // },
    urlForCreateRecord: function urlForCreateRecord(modelName, snapshot) {
      // console.log(snapshot);
      var opportunity_id = snapshot.record.get('opportunity.id');
      return "".concat(this.buildURL(), "/timelines/").concat(opportunity_id, "/steps");
    }
  });

  _exports.default = _default;
});