define("pickle/routes/settings/account/profile-buttons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    account: Ember.inject.service('current-account'),
    model: function model() {
      var account_id = this.get('account.currentAccount.id') || window.localStorage.getItem('currentAccountId');
      return this.store.query('profile-action', {
        account_id: account_id
      }); //.then(actions => model.set('profileActions', actions));
    }
  });

  _exports.default = _default;
});