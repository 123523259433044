define("pickle/templates/timelines/-template-chooser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ABm+7iVi",
    "block": "[[[10,\"h1\"],[12],[1,\"Pick a Timeline Template\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"templates\"],[12],[1,\"\\n\\n\"],[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[1,\"        \"],[11,3],[4,[38,3],[[30,0],\"useTimeline\",[30,1]],null],[12],[1,\"\\n            \"],[10,0],[14,0,\"template\"],[12],[1,\"\\n                \"],[10,0],[14,0,\"centered\"],[12],[1,\"\\n                    \"],[10,0],[14,0,\"big-emoji-icon\"],[12],[1,[30,1,[\"emoji\"]]],[13],[1,\"\\n                    \"],[10,\"h1\"],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n                    \"],[10,2],[12],[1,[30,1,[\"description\"]]],[13],[1,\"\\n                \"],[13],[1,\"\\n                \"],[10,\"footer\"],[12],[1,\"Created by: Pickle\"],[13],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[1]],null],[1,\"\\n\"],[13]],[\"template\"],false,[\"each\",\"-track-array\",\"templates\",\"action\"]]",
    "moduleName": "pickle/templates/timelines/-template-chooser.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});