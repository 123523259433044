define("pickle/routes/tables/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params, transition) {
      return this.store.findRecord('table', params.uuid);
    },
    afterModel: function afterModel(table) {
      var _this = this;

      // find values
      this.store.query('table_column_value', {
        table: table.get('uuid')
      }).then(function (values) {
        _this.set('controller.values', values);
      });
    }
  });

  _exports.default = _default;
});