define("pickle/templates/pipelines/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oneEK/Pq",
    "block": "[[[10,0],[14,0,\"flex-column left-margin\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"main container\"],[12],[1,\"\\n\"],[41,[33,1],[[[1,\"        \"],[10,0],[14,0,\"container-header\"],[12],[1,\"\\n            \"],[10,\"h1\"],[12],[1,\"Pipelines\"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,\"table\"],[14,0,\"margin-top table table-hover\"],[12],[1,\"\\n            \"],[10,\"tbody\"],[12],[1,\"\\n                \"],[42,[28,[37,3],[[28,[37,3],[[33,1]],null]],null],null,[[[1,\" \"],[6,[39,4],null,[[\"tagName\",\"query\",\"route\",\"model\"],[\"tr\",[28,[37,5],null,[[\"account_id\"],[[30,1,[\"account\",\"id\"]]]]],\"pipelines.show\",[30,1,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n                \"],[10,\"td\"],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n                \"]],[]]]]],[1,\" \"]],[1]],null],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n\"]],[]],[[[1,\"        \"],[10,0],[14,0,\"empty-state centered\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"graphic\"],[12],[1,\"🤑\"],[13],[1,\"\\n            \"],[10,2],[12],[1,\"Pipelines visually show you opportuniites\"],[13],[1,\"\\n            \"],[10,\"ul\"],[12],[1,\"\\n                \"],[10,\"li\"],[12],[1,\"Move opportunities through a pipeline\"],[13],[1,\"\\n                \"],[10,\"li\"],[12],[1,\"Uses custom opportunity states for each column\"],[13],[1,\"\\n            \"],[13],[1,\"\\n            \"],[10,0],[12],[1,\"\\n                \"],[6,[39,4],null,[[\"class\",\"route\"],[\"button create green\",\"pipelines.new\"]],[[\"default\"],[[[[1,\"Create a Pipeline\"]],[]]]]],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"],[13]],[\"pipeline\"],false,[\"if\",\"model\",\"each\",\"-track-array\",\"link-to\",\"-hash\"]]",
    "moduleName": "pickle/templates/pipelines/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});