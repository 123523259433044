define("pickle/templates/components/add-search-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5PS5b2K9",
    "block": "[[[10,\"table\"],[12],[1,\"\\n    \"],[10,\"tbody\"],[12],[1,\"\\n        \"],[10,\"tr\"],[12],[1,\"\\n            \"],[10,\"td\"],[12],[1,\"\\n                \"],[10,\"select\"],[14,3,\"\"],[14,1,\"\"],[15,\"onchange\",[28,[37,0],[[30,0],[28,[37,1],[[33,2]],null]],[[\"value\"],[\"target.value\"]]]],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[33,5]],null]],null],null,[[[1,\"                        \"],[10,\"option\"],[15,2,[29,[[30,1,[\"name\"]]]]],[15,\"selected\",[28,[37,6],[[30,1,[\"name\"]],[33,2]],null]],[12],[1,[30,1,[\"displayName\"]]],[13],[1,\"\\n\"]],[1]],null],[1,\"                \"],[13],[1,\"\\n            \"],[13],[1,\"\\n            \"],[10,\"td\"],[12],[1,\"\\n\"],[1,\"            \"],[13],[1,\"\\n            \"],[10,\"td\"],[12],[1,\"\\n                \"],[1,[28,[35,7],null,[[\"type\",\"value\",\"name\",\"class\"],[\"text\",[33,8],[33,9,[\"name\"]],\"form-control\"]]]],[1,\"\\n            \"],[13],[1,\"\\n            \"],[10,\"td\"],[12],[1,\"\\n\"],[41,[33,11],[[[1,\"                    \"],[11,3],[24,0,\"pointer\"],[4,[38,0],[[30,0],\"remove\"],null],[12],[1,\"Remove\"],[13],[1,\"\\n\"]],[]],[[[1,\"                    \"],[11,\"button\"],[24,0,\"button-shadow\"],[4,[38,0],[[30,0],\"apply\"],null],[12],[1,\"Apply\"],[13],[1,\" \"],[41,[33,12],[[[1,\" or \"],[11,3],[24,0,\"pointer\"],[4,[38,0],[[30,0],\"cancel\"],null],[12],[1,\"Cancel\"],[13]],[]],null],[1,\"\\n\"]],[]]],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[18,2,null]],[\"column\",\"&default\"],false,[\"action\",\"mut\",\"selectedColumnName\",\"each\",\"-track-array\",\"fields\",\"is-equal\",\"input\",\"value\",\"column\",\"if\",\"saved\",\"cancelAction\",\"yield\"]]",
    "moduleName": "pickle/templates/components/add-search-filter.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});