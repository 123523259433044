define("pickle/templates/components/capture-name-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2XjWbVg0",
    "block": "[[[10,0],[14,0,\"alert alert-info\"],[14,\"role\",\"alert\"],[12],[1,\"\\n\"],[41,[33,1],[[[1,\"\\t\"],[10,0],[14,0,\"text-center\"],[12],[1,\"\\n\\t\\t\"],[10,\"h2\"],[12],[1,\"Preparing to call you \"],[1,[34,2]],[1,\" \"],[1,[34,3]],[1,\"!\"],[13],[1,\"\\n\\t\"],[13],[1,\"\\n\"]],[]],[[[1,\"\\t\\t\"],[10,\"strong\"],[12],[1,\"Hi there Mr. Anonymous!\"],[13],[1,\"\\n\\t\\t\"],[10,2],[12],[1,\"We'd love to know what to call you! What's your name?\"],[13],[1,\"\\n\\t\\t\"],[11,\"form\"],[24,0,\"form-horizontal\"],[4,[38,4],[[30,0],\"saveName\"],[[\"on\"],[\"submit\"]]],[12],[1,\"\\n\\t\\t\\t\"],[1,[28,[35,5],null,[[\"class\",\"value\",\"placeholder\",\"key-up\"],[\"form-control\",[33,2],\"Johnny\",[28,[37,4],[[30,0],\"nameUpdated\"],null]]]]],[1,\"\\n\\t\\t\\t\"],[1,[28,[35,5],null,[[\"class\",\"value\",\"placeholder\",\"key-up\"],[\"form-control\",[33,3],\"Appleseed\",[28,[37,4],[[30,0],\"nameUpdated\"],null]]]]],[1,\"\\n\\t\\t\\t\"],[1,[28,[35,5],null,[[\"type\",\"class\",\"value\"],[\"submit\",\"btn btn-default\",\"Call me ______\"]]]],[1,\"\\n\\t\\t\"],[13],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[],false,[\"if\",\"isSavingName\",\"firstName\",\"lastName\",\"action\",\"input\"]]",
    "moduleName": "pickle/templates/components/capture-name-alert.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});