define("pickle/adapters/form", ["exports", "pickle/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForCreateRecord: function urlForCreateRecord(modelName, snapshot) {
      return this._super.apply(this, arguments) + "/?account_id=".concat(snapshot.record.get('account_id'));
    },
    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      var baseUrl = this.buildURL(modelName, id, snapshot);
      return "".concat(this._super(), "/forms/").concat(id, "?account_id=").concat(snapshot.record.get('account_id'));
    },
    urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
      return "".concat(this._super(), "/forms/").concat(id, "?account_id=").concat(snapshot.record.get('account_id'));
    }
  });

  _exports.default = _default;
});