define("pickle/routes/date/edit", ["exports", "pickle/mixins/form-cancel-edit"], function (_exports, _formCancelEdit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_formCancelEdit.default, {});

  _exports.default = _default;
});