define("pickle/mixins/contacts-search", ["exports", "pickle/mixins/search-base"], function (_exports, _searchBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_searchBase.default, {
    selectedContact: null,
    selectedContacts: [],
    searchForContact: function searchForContact(query) {
      this.set('isSearchingForContact', true);
      this.searchWithValues({
        query: query
      });
    },
    actions: {
      contactSearchTyped: function contactSearchTyped() {
        Ember.run.debounce(this, this.searchForContact, this.get('contactSearchValue'), 300);
      },
      selectedContactItem: function selectedContactItem(contact) {
        this.set('selectedContact', contact);
        this.get('selectedContacts').addObject(contact); // send action letting parent know we've selcted an id

        this.send('contactObjectSelected', contact);
      },
      removeContact: function removeContact(contact) {
        this.set('selectedContact', null);
        this.set('selectedContacts', []);
      },
      searchCompleted: function searchCompleted(results) {
        this.set('isSearchingForContact', false);
        this.set('contactSearchResults', results.Contacts);
      }
    }
  });

  _exports.default = _default;
});