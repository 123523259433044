define("pickle/templates/note/meeting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W/vMZggr",
    "block": "[[[1,\"Meeting notes\\n\\n1. Who was involved? \\n2. Meeting Note Contents (markdown/wysiwyg)\\n3. Followups\"]],[],false,[]]",
    "moduleName": "pickle/templates/note/meeting.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});