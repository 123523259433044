define("pickle/templates/components/timeline-messages-timeline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IPJBUCQW",
    "block": "[[[10,0],[14,0,\"notes-container\"],[12],[1,\"\\n\"],[41,[33,1],[[[1,\"        \"],[1,[34,2]],[1,\"\\n\"]],[]],null],[41,[33,3,[\"length\"]],[[[42,[28,[37,5],[[28,[37,5],[[33,3]],null]],null],null,[[[1,\"        \"],[10,0],[14,0,\"group\"],[12],[1,\"\\n            \"],[10,\"h1\"],[12],[1,[28,[35,6],[[30,1,[\"value\"]],\"MMM DD, YYYY\"],null]],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,0],[14,0,\"notes notes-timeline\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"reload-notes\"],[12],[1,\"\\n\"],[41,[33,7,[\"reloadContactNotes\"]],[[[1,\"            \"],[11,3],[24,0,\"button-reload\"],[4,[38,8],[[30,0],\"reloadNotes\"],null],[12],[1,\"🔔 New Messages\"],[13]],[]],null],[1,\"\\n            \"],[13],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,1,[\"items\"]]],null]],null],null,[[[1,\"                \"],[1,[28,[35,9],null,[[\"note\",\"canEdit\",\"editAction\",\"currentUser\",\"account\",\"isTimelineMessage\"],[[30,3],true,\"editNote\",[33,10],[33,11],true]]]],[1,\"\\n\"]],[3]],null],[1,\"        \"],[13],[1,\"\\n\"]],[1,2]],null]],[]],null],[13],[1,\"\\n\\n\\n\"],[18,4,null],[1,\"\\n\\n\"]],[\"dateGroup\",\"index\",\"n\",\"&default\"],false,[\"if\",\"loading\",\"loading-dots\",\"groupedMessages\",\"each\",\"-track-array\",\"moment-format\",\"notificationCenter\",\"action\",\"note-styled\",\"current_user\",\"account\",\"yield\"]]",
    "moduleName": "pickle/templates/components/timeline-messages-timeline.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});