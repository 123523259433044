define("pickle/routes/notes/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params, transition) {
      if (params.note_id == 0) {
        return this.store.createRecord('Note', {
          title: "Meeting about Glib Glob",
          body: "We will return… possibly in different clothing. Summer, next time we're hiding in a chlorkian echo nest, can you do me a favour and turn your ringer off?! You have to turn them on Morty! **The shoes need to be turned on!** Oh, it gets darker, Morty. Welcome to the darkest year of our adventures. First thing that's different? No more dad, Morty. <br><br>\
Crystal poachers. There's no lower form of life. They think the galaxy's their own personal piggy bank. Your blood will be my lotion! It's not like we can do this every week, we get 3 or 4 more of these tops. This is because you give Morty Smith bad grades, bitch! <br><br> \
Aw, geez. Yes. He's a getaway driver with Asperger's and my butthole is a demolitions expert. Are you hungry for apples? ARE YOU HUNGRY FOR APPLESSS!? It takes, like, 78 years to hang a dragon to death. <br><br> \
Yea. If you spend all day shuffling words around you can make anything sound bad, Morty. Oh god, oh, I blame myself. Oh, what a tragedy. Oh, well, he's bones now. I guess all debts are paid. Stealing stuff is about the stuff, not the stealing. Your special time is your power. It makes you strong like a boob.",
          contacts: this.store.findAll('contact'),
          user: this.store.findRecord('User', 1),
          user_id: 1,
          tags: ['meeting', '8x8', 'work', 'rick and morty', 'tv']
        });
      } else {
        return this.store.findRecord('Note', params.note_id);
      }
    }
  });

  _exports.default = _default;
});