define("pickle/models/timeline-message", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    body: _emberData.default.attr(),
    opportunity: _emberData.default.belongsTo('opportunity'),
    User: _emberData.default.belongsTo('user'),
    completes_step: _emberData.default.attr('boolean'),
    sends_email: _emberData.default.attr('boolean'),
    step: _emberData.default.belongsTo('timeline-step')
  });

  _exports.default = _default;
});