define("pickle/adapters/group", ["exports", "pickle/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    addUser: function addUser(group, userId) {
      var url = this.buildURL('groups', group.get('id')) + "/add_user";
      var options = {
        data: {
          user: {
            id: userId
          }
        }
      };
      return this.ajax(url, 'POST', options);
    },
    addContact: function addContact(group, contactId) {
      var url = this.buildURL('groups', group.get('id')) + "/add_contact";
      var options = {
        data: {
          contact: {
            id: contactId
          }
        }
      };
      return this.ajax(url, 'POST', options);
    }
  });

  _exports.default = _default;
});