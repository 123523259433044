define("pickle/components/stripe-card-element", ["exports", "pickle/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    card: null,
    isSaving: false,
    didInsertElement: function didInsertElement() {
      var _this = this;

      // Create a Stripe client
      var stripe = window.Stripe(_environment.default.stripe.publishableKey);
      this.set('stripe', stripe); // Create an instance of Elements

      var elements = stripe.elements(); // Custom styling can be passed to options when creating an Element.
      // (Note that this demo uses a wider set of styles than the guide below.)

      var style = {
        base: {
          color: '#32325d',
          lineHeight: '18px',
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4'
          }
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a'
        }
      }; // Create an instance of the card Element

      var card = elements.create('card', {
        style: style
      });
      card.addEventListener('change', function (_ref) {
        var error = _ref.error;
        var displayError = document.getElementById('card-errors');

        if (error) {
          displayError.textContent = error.message;

          _this.set('validSelections', false);
        } else {
          displayError.textContent = '';

          _this.set('validSelections', true);
        }
      }); // Add an instance of the card Element into the `card-element` <div>

      card.mount('#card-element');
      this.set('card', card);
    },
    actions: {
      saveCard: function saveCard() {
        var _this2 = this;

        var stripe = this.get('stripe');
        this.set('isSaving', true);
        stripe.createToken(this.get('card')).then(function (result) {
          if (result.error) {// console.log(result.error);
          } else {
            // console.log(result.token);
            _this2.sendAction('setCardToken', result.token);
          }
        });
      }
    }
  });

  _exports.default = _default;
});