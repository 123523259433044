define("pickle/components/timeline-messages-timeline", ["exports", "ember-group-by"], function (_exports, _emberGroupBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    messages: [],
    groupedMessages: (0, _emberGroupBy.default)('messages', 'updated_date'),
    loading: false
  });

  _exports.default = _default;
});