define("pickle/templates/note-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MTVxjIy3",
    "block": "[[[6,[39,0],null,[[\"title\",\"ok\",\"close\",\"footer\"],[\"Note\",\"save\",\"removeModal\",false]],[[\"default\"],[[[[1,\"    \"],[10,0],[14,0,\"note-box-container\"],[12],[1,\"\\n        Users: \"],[1,[33,1,[\"length\"]]],[1,\"\\n        \"],[1,[28,[35,2],null,[[\"model\",\"users\",\"contact_id\",\"store\",\"account\",\"type\",\"style\",\"isEditing\",\"editingNote\"],[[33,3],[33,1],[33,3,[\"id\"]],[33,4],[33,5,[\"currentAccount\"]],\"contact\",\"inline\",[33,6],[33,7]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"custom-modal\",\"users\",\"notes-box\",\"model\",\"store\",\"currentAccount\",\"isEditingNote\",\"editingNote\"]]",
    "moduleName": "pickle/templates/note-modal.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});