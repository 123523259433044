define("pickle/routes/search/fullscreen", ["exports", "pickle/mixins/search-base-route"], function (_exports, _searchBaseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_searchBaseRoute.default, {
    afterModel: function afterModel() {
      var _this = this;

      this.store.query('group', {
        account_id: this.get('account.currentAccountId')
      }).then(function (groups) {
        _this.set('groups', groups);

        _this.set('controller.groups', groups);
      });

      this._super();
    },
    resetFilters: function resetFilters() {
      this.set('controller.term', null);
      this.set('controller.selectedTags', []); // search index controller
      // turn off tags and things when leaving fullscreen search

      var searchController = this.controllerFor('search.index');
      searchController.set('selectedTags', []);
      var applicationController = this.controllerFor('application');
      applicationController.set('selectedTags', []);
    },
    actions: {
      willTransition: function willTransition(transition) {
        console.log('transitioning!');
        this.resetFilters();

        this._super();
      }
    }
  });

  _exports.default = _default;
});