define("pickle/routes/contacts/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    account: Ember.inject.service('current-account'),
    model: function model() {
      return this.store.createRecord('contact');
    },
    afterModel: function afterModel(contact
    /*, transition */
    ) {
      var _this = this;

      var customFields = this.store.query('custom_field', {
        account_id: this.get('account.currentAccountId')
      }).then(function (customFields) {
        _this.set('controller.customFields', customFields.toArray());
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('model', model);
    }
  });

  _exports.default = _default;
});