define("pickle/templates/groups/show/message/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jLlPcyUu",
    "block": "[[[6,[39,0],null,[[\"title\",\"ok\",\"footer\",\"okEnabled\",\"close\"],[\"New Message\",\"send message\",false,[33,1],\"navigateBack\"]],[[\"default\"],[[[[1,\"  \"],[1,[28,[35,2],null,[[\"contacts\",\"sendNotificationAction\",\"contactSelectedAction\",\"messageUpdatedAction\",\"sendViaChanged\",\"messageContent\"],[[33,3,[\"contacts\"]],[28,[37,4],[[30,0],\"sendNotification\"],null],[28,[37,4],[[30,0],\"contactSelected\"],null],[28,[37,4],[[30,0],\"messageUpdated\"],null],[28,[37,4],[[30,0],\"sendViaChanged\"],null],[33,5]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[46,[28,[37,7],null,null],null,null,null],[1,\"\\n\"]],[],false,[\"custom-modal\",\"okButtonEnabled\",\"group-message-composer\",\"model\",\"action\",\"messageContent\",\"component\",\"-outlet\"]]",
    "moduleName": "pickle/templates/groups/show/message/new.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});