define("pickle/components/note-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="list-item dark:text-gray-500">
    <div class="flex-row">
      <h1 class="">{{@note.subject}}</h1>
      <div class="note-icon">
        <span class="glyphicon {{@note.iconType}}"></span>
      </div>
    </div>
    <p class="overflow-hidden truncate flow-ellipsis">{{{trim-text @note.body 75}}}</p>
    {{yield}}
    <div class="note-footer">
      {{moment-from-now @note.updated_at}}
    </div>
  </div>
  
  */
  {
    "id": "8TFj8Aat",
    "block": "[[[10,0],[14,0,\"list-item dark:text-gray-500\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex-row\"],[12],[1,\"\\n    \"],[10,\"h1\"],[14,0,\"\"],[12],[1,[30,1,[\"subject\"]]],[13],[1,\"\\n    \"],[10,0],[14,0,\"note-icon\"],[12],[1,\"\\n      \"],[10,1],[15,0,[29,[\"glyphicon \",[30,1,[\"iconType\"]]]]],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,2],[14,0,\"overflow-hidden truncate flow-ellipsis\"],[12],[2,[28,[37,0],[[30,1,[\"body\"]],75],null]],[13],[1,\"\\n  \"],[18,2,null],[1,\"\\n  \"],[10,0],[14,0,\"note-footer\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[[30,1,[\"updated_at\"]]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@note\",\"&default\"],false,[\"trim-text\",\"yield\",\"moment-from-now\"]]",
    "moduleName": "pickle/components/note-list-item.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});