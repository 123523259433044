define("pickle/templates/tables/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eQbV6rSM",
    "block": "[[[10,0],[14,0,\"flex-column left-margin main container\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"header-block\"],[12],[1,\"\\n        \"],[10,\"h1\"],[12],[1,\"Add a Table\"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[19,\"tables/_form\",[]],[1,\"\\n    \"],[46,[28,[37,2],null,null],null,null,null],[1,\"\\n\"],[13],[1,\"\\n\\n\"]],[],true,[\"partial\",\"component\",\"-outlet\"]]",
    "moduleName": "pickle/templates/tables/new.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});