define("pickle/components/imported-contact-column-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    crmFields: [],
    columnNames: [],
    name: null,
    // the value of the CRM field
    index: null,
    importedRowValue: null,
    selected: Ember.computed('columnNames', function () {
      var index = this.get('columnNames').indexOf(this.get('name'));

      if (index !== -1) {
        this.sendAction('updateMapping', this.get('columnNames')[index], this.get('name'), this.get('index'));
        return this.get('columnNames')[index];
      } else {
        return '';
      }
    }),
    actions: {
      columnChanged: function columnChanged(value) {
        // remove old mapping if it exists
        this.send('clearSelection');
        this.set('selected', value);
        this.sendAction('updateMapping', value.name, this.get('name'), this.get('index'), value.type, this.get('importedRowValue'));
      },
      clearSelection: function clearSelection() {
        this.sendAction('removeMapping', this.get('selected'));
        this.set('selected', null);
      }
    }
  });

  _exports.default = _default;
});