define("pickle/templates/forms/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nCjIi5kL",
    "block": "[[[10,0],[14,0,\"centered\"],[12],[1,\"\\n    \"],[10,\"img\"],[14,\"src\",\"/images/icons/form.png\"],[14,\"alt\",\"Search Icon\"],[14,\"width\",\"180\"],[12],[13],[1,\"\\n    \"],[10,2],[12],[1,\"Select a form to view it's info\"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "pickle/templates/forms/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});