define("pickle/serializers/contact", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      Addresses: {
        embedded: 'always'
      },
      email_addresses: {
        embedded: 'always'
      },
      account: 'account_id',
      company: {
        embedded: 'always'
      },
      catchups: {
        embedded: 'always'
      },
      dates: {
        deserialize: 'records',
        serialize: false
      },
      // This will always send the full payload when creating/updating a contact
      locations: {
        embedded: 'always'
      }
    }
  });

  _exports.default = _default;
});