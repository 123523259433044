define("pickle/templates/date/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "joQMZG8e",
    "block": "[[[1,[28,[35,0],[[33,1,[\"name\"]]],null]],[1,\"\\n\"],[10,0],[14,0,\"flex-column maxed date-show\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"main container\"],[12],[1,\"\\n    \"],[10,\"h1\"],[12],[1,[33,1,[\"name\"]]],[13],[1,\"\\n    \"],[10,2],[12],[1,[33,1,[\"description\"]]],[13],[1,\"\\n    \"],[10,\"h4\"],[12],[1,\"Who:\"],[13],[1,\"\\n    \"],[10,0],[14,0,\"people-involved\"],[12],[1,\"\\n      \"],[1,[28,[35,2],null,[[\"contacts\",\"hideAddAndDoneButtons\"],[[33,1,[\"contacts\"]],true]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"right-content\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"block\"],[12],[1,\"\\n    \"],[10,\"h4\"],[12],[1,\"Actions\"],[13],[1,\"\\n    \"],[10,\"ul\"],[12],[1,\"\\n      \"],[10,\"li\"],[12],[6,[39,3],null,[[\"route\",\"model\"],[\"date.edit\",[33,1,[\"id\"]]]],[[\"default\"],[[[[1,\"Edit\"]],[]]]]],[13],[1,\"\\n      \"],[10,\"li\"],[14,0,\"margin-top\"],[12],[10,0],[14,0,\"delete\"],[12],[1,\" \"],[11,3],[24,6,\"#\"],[4,[38,4],[[30,0],\"delete\",[33,1],\"contacts.index\"],null],[12],[1,\"Delete\"],[13],[13],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"block\"],[12],[1,\"\\n    \"],[10,\"h4\"],[12],[1,\"Details\"],[13],[1,\"\\n    \"],[10,\"ul\"],[12],[1,\"\\n      \"],[10,\"li\"],[12],[1,\"Date: \"],[1,[33,1,[\"start_date_without_time\"]]],[1,\" @ \"],[1,[33,1,[\"start_time\"]]],[13],[1,\"\\n\"],[41,[33,1,[\"end_date\"]],[[[1,\"      \"],[10,\"li\"],[12],[1,\"End: \"],[1,[33,1,[\"end_date_without_time\"]]],[1,\" @ \"],[1,[33,1,[\"end_time\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[13],[1,\"\\n\\n\"],[46,[28,[37,7],null,null],null,null,null],[1,\"\\n\"]],[],false,[\"title\",\"model\",\"people-involved\",\"link-to\",\"action\",\"if\",\"component\",\"-outlet\"]]",
    "moduleName": "pickle/templates/date/show.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});