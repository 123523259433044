define("pickle/templates/-note-modal-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "86o6462n",
    "block": "[[[10,0],[14,0,\"modal fade\"],[14,1,\"new_note_modal\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"modal-dialog\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"modal-content\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"modal-header\"],[12],[1,\"\\n                \"],[10,\"button\"],[14,0,\"close\"],[14,\"data-dismiss\",\"modal\"],[14,\"aria-hidden\",\"true\"],[14,4,\"button\"],[12],[1,\"×\"],[13],[1,\"\\n                \"],[10,\"h4\"],[14,0,\"modal-title\"],[12],[1,\"Add Note\"],[13],[1,\"\\n            \"],[13],[1,\"\\n            \"],[10,0],[14,0,\"modal-body\"],[12],[1,\"\\n                \"],[10,0],[14,0,\"note-box-container\"],[12],[1,\"\\n                    \"],[1,[28,[35,0],null,[[\"model\",\"users\",\"contact_id\",\"store\",\"account\",\"type\",\"style\",\"isEditing\",\"editingNote\",\"finishedSavingAction\"],[[33,1],[33,2],[33,1,[\"id\"]],[33,3],[33,4,[\"currentAccount\"]],[33,5],\"inline\",[33,6],[33,7],[28,[37,8],[[30,0],\"noteFinishedSaving\"],null]]]]],[1,\"\\n                \"],[13],[1,\"\\n\"],[41,[33,10],[[[1,\"                \"],[10,0],[14,0,\"modal-footer\"],[12],[1,\"\\n                    \"],[10,\"button\"],[14,0,\"btn btn-default\"],[14,\"data-dismiss\",\"modal\"],[14,4,\"button\"],[12],[1,\"Close\"],[13],[1,\"\\n                    \"],[11,\"button\"],[24,0,\"btn btn-primary\"],[16,\"disabled\",[36,11]],[24,4,\"button\"],[4,[38,8],[[30,0],\"ok\"],null],[12],[1,[30,0,[\"ok\"]]],[13],[1,\"\\n                \"],[13],[1,\"\\n\"]],[]],null],[1,\"            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[],false,[\"notes-box\",\"model\",\"users\",\"store\",\"currentAccount\",\"newNoteType\",\"isEditingNote\",\"editingNote\",\"action\",\"if\",\"footer\",\"okEnabled\"]]",
    "moduleName": "pickle/templates/-note-modal-new.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});