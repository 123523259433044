define("pickle/templates/contacts/show/files", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Lev2WpiQ",
    "block": "[[[1,[28,[35,0],[\"Files\"],null]],[1,\"\\n\\n\"],[10,0],[14,0,\"flex-column\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"\"],[12],[1,\"\\n    \"],[8,[39,1],[[24,0,\"\"]],[[\"@files\",\"@account\"],[[30,1,[\"files\"]],[33,2,[\"currentAccount\"]]]],null],[1,\"\\n    \"],[46,[28,[37,4],null,null],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@model\"],false,[\"page-title\",\"file-element\",\"currentAccount\",\"component\",\"-outlet\"]]",
    "moduleName": "pickle/templates/contacts/show/files.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});