define("pickle/controllers/welcome", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    date: new Date(),
    userOnboarding: Ember.inject.service(),
    showOnwardLink: true,
    greeting: Ember.computed('date', function () {
      var date = this.get('date');
      var hour = date.getHours();

      if (hour > 0 && hour < 12) {
        return 'Good Morning';
      } else if (hour >= 12 && hour < 18) {
        return 'Good Afternoon';
      } else if (hour >= 18) {
        return 'Good Evening';
      }
    }),
    nextStep: function nextStep() {}
  });

  _exports.default = _default;
});