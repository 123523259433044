define("pickle/templates/components/todo-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jwf765q/",
    "block": "[[[10,\"li\"],[14,0,\"todo-list-item\"],[12],[1,\"\\n    \"],[10,0],[12],[1,\"\\n        \"],[1,[28,[35,0],null,[[\"type\",\"id\",\"checked\",\"class\"],[\"checkbox\",[33,1,[\"id\"]],[33,1,[\"is_completed\"]],\"big\"]]]],[1,\"\\n        \"],[10,\"label\"],[15,\"for\",[29,[[33,1,[\"id\"]]]]],[12],[1,[33,1,[\"name\"]]],[13],[41,[28,[37,3],[[33,4],[33,1,[\"contact\",\"fullName\"]]],null],[[[1,\"  -  \"],[6,[39,5],null,[[\"route\",\"model\"],[\"contacts.show\",[33,1,[\"contact\",\"id\"]]]],[[\"default\"],[[[[1,\" \"],[1,[33,1,[\"contact\",\"fullName\"]]]],[]]]]],[1,\"  \"]],[]],null],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[18,1,null]],[\"&default\"],false,[\"input\",\"todo\",\"if\",\"and\",\"showName\",\"link-to\",\"yield\"]]",
    "moduleName": "pickle/templates/components/todo-list-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});