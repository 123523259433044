define("pickle/templates/account/dropbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OZKyxQmI",
    "block": "[[[46,[28,[37,1],null,null],null,null,null],[1,\"\\n\"],[10,\"h4\"],[12],[1,\"Dropbox Integration\"],[13],[1,\"\\n\"],[1,[33,2,[\"email\"]]],[1,\"\\n\"],[10,0],[14,0,\"misc-data-box\"],[12],[1,\"\\n\\t\"],[10,\"h5\"],[12],[1,\"Data\"],[13],[1,\"\\n\\t\"],[10,\"br\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"component\",\"-outlet\",\"current_user\"]]",
    "moduleName": "pickle/templates/account/dropbox.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});