define("pickle/routes/pipelines/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var account_id = window.localStorage.getItem('currentAccountId');
      return this.store.query('pipeline', {
        account_id: account_id
      });
    }
  });

  _exports.default = _default;
});