define("pickle/components/search-box", ["exports", "pickle/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    results: null,
    isSearching: false,
    didInsertElement: function didInsertElement() {
      var self = this;
      Ember.$('#search-box').keyup(function (e) {
        if (e.keyCode === 27) {
          // esc pressed
          self.send('cancelSearch');
        } else {
          self.send('searchValueEntered', e.target.value);
          self.sendAction('searchResultsHandler', null);
        }
      });
    },
    actions: {
      searchValueEntered: function searchValueEntered(value) {
        // var store = this.get('targetObject.store');
        var store = this.get('store');
        var self = this;
        self.set('isSearching', true);
        var token = localStorage.getItem('userAccessToken');
        Ember.$.ajax({
          headers: {
            'x-access-token': token
          },
          url: _environment.default.apiHost + "/search?q=" + value
        }).done(function (res) {
          self.set('isSearching', false);
          store.pushPayload('contact', res);
          self.sendAction('searchResultsHandler', res, value);
        });
      },
      cancelSearch: function cancelSearch() {
        Ember.$('#search-box').val('');
        this.sendAction('cancelSearchHandler');
      }
    }
  });

  _exports.default = _default;
});