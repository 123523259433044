define("pickle/controllers/date/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      cancelEdit: function cancelEdit() {
        this.reload();
      },
      delete: function _delete(date, route) {
        var _this = this;

        var confirm = window.confirm("Are you sure you want to delete ".concat(date.get('name'), "? This action cannot be undone."));

        if (confirm) {
          date.destroyRecord().then(function () {
            _this.transitionToRoute(route);
          });
        }
      }
    }
  });

  _exports.default = _default;
});