define("pickle/helpers/is-equal-year", ["exports", "ember-date-components/helpers/is-equal-year"], function (_exports, _isEqualYear) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _isEqualYear.default;
    }
  });
  Object.defineProperty(_exports, "isEqualYear", {
    enumerable: true,
    get: function get() {
      return _isEqualYear.isEqualYear;
    }
  });
});