define("pickle/components/sms-composer", ["exports", "pickle/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    messageContent: '',
    selectedContacts: [],
    sendVia: 'sms',
    sendButtonEnabled: 'disabled',
    isNotifying: false,
    notificationCenter: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      Ember.$(function () {
        Ember.$('[data-toggle="tooltip"]').tooltip();
      });
    },
    checkIfMessageIsGoodToGo: Ember.observer('messageContent', function () {
      this.send('checkIfAbleToSendMessage');
    }),
    actions: {
      messageChanged: function messageChanged() {
        this.send('checkIfAbleToSendMessage');
      },
      checkIfAbleToSendMessage: function checkIfAbleToSendMessage() {
        !Ember.isEmpty(this.get('messageContent')) ? this.set('sendButtonEnabled', null) : this.set('sendButtonEnabled', 'disabled');
      },
      sendMessage: function sendMessage() {
        var _this = this;

        //   this.sendAction('sendNotificationAction', this.get('messageContent'));
        this.set('isNotifying', true);
        var token = localStorage.getItem('userAccessToken');
        Ember.$.ajax({
          url: "".concat(_environment.default.apiHost, "/contacts/").concat(this.get('contact.id'), "/send_sms"),
          type: "POST",
          headers: {
            'x-access-token': token,
            "Content-Type": "application/json; charset=utf-8"
          },
          contentType: "application/json",
          data: JSON.stringify({
            "message": {
              "body": "".concat(this.get('messageContent')),
              "message": "".concat(this.get('messageContent')),
              "send_via": 'sms'
            }
          })
        }).done(function () {
          _this.set('messageContent', null); // this.store.pushPayload('message', data); // no need for 'message' because it's in the correct format
          // this.model.reload();
          // this.send('removeModal');


          _this.set('isNotifying', false);

          Ember.$('.modal').modal('hide');

          _this.get('notificationCenter').set('reloadContactNotes', new Date());

          _this.get('notificationCenter').tellContactNotesToUpadate();
        }).fail(function () {
          _this.set('isNotifying', false);
        }).always(function () {
          /* ... */
          _this.set('isNotifying', false);
        });
      }
    }
  });

  _exports.default = _default;
});