define("pickle/templates/remind-me-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "69zzRl20",
    "block": "[[[6,[39,0],null,[[\"title\",\"ok\",\"close\",\"footer\"],[\"Add a Reminder\",\"Save Reminder\",\"removeModal\",false]],[[\"default\"],[[[[1,\"    \"],[1,[28,[35,1],null,[[\"contact\",\"categories\"],[[33,2],[33,3,[\"currentAccount\",\"reminder_categories\"]]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"custom-modal\",\"reminder-composer\",\"model\",\"currentAccount\"]]",
    "moduleName": "pickle/templates/remind-me-modal.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});