define("pickle/routes/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUserService: Ember.inject.service('current-user'),
    setupController: function setupController() {
      this.redirectIfAlreadyLoggedIn();
      this.store.unloadAll();

      if (this.controllerFor('application')) {
        this.controllerFor('application').set('showNavLinks', false);
      }
    },
    redirectIfAlreadyLoggedIn: function redirectIfAlreadyLoggedIn() {
      if (this.get('currentUser')) {
        this.transitionTo('index');
      }
    }
  });

  _exports.default = _default;
});