define("pickle/models/table-column", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    data_type: _emberData.default.attr('string'),
    created_at: _emberData.default.attr('date'),
    updated_at: _emberData.default.attr('date'),
    table: _emberData.default.belongsTo('table')
  });

  _exports.default = _default;
});