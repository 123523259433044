define("pickle/templates/components/achievement-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RIvNIPmE",
    "block": "[[[10,0],[14,0,\"icon\"],[12],[1,[33,0,[\"icon\"]]],[13],[1,\"\\n\"],[10,\"h1\"],[12],[1,[33,0,[\"name\"]]],[13],[1,\"\\n\"],[10,2],[12],[1,[33,0,[\"body\"]]],[13],[1,\"\\n\\n\"],[18,1,null]],[\"&default\"],false,[\"achievement\",\"yield\"]]",
    "moduleName": "pickle/templates/components/achievement-box.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});