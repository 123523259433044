define("pickle/templates/components/upgrade-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t85xMEEG",
    "block": "[[[41,[30,1],[[[1,\"    \"],[10,0],[14,0,\"upgrade-banner\"],[12],[1,\"\\n        \"],[10,0],[12],[1,\"\\n\"],[41,[28,[37,1],[[33,2],1],null],[[[1,\"                There is \"],[1,[34,2]],[1,\" day left in your trial. \"],[6,[39,3],null,[[\"route\"],[\"billing\"]],[[\"default\"],[[[[1,\" Click here to upgrade\"]],[]]]]],[1,\".\\n\"]],[]],[[[41,[28,[37,4],[[33,2],0],null],[[[1,\"                There are \"],[1,[34,2]],[1,\" days left in your trial. \"],[6,[39,3],null,[[\"route\"],[\"billing\"]],[[\"default\"],[[[[1,\" Click here to upgrade\"]],[]]]]],[1,\".\\n\"]],[]],[[[1,\"                Your free trial has expired. \"],[6,[39,3],null,[[\"route\"],[\"billing\"]],[[\"default\"],[[[[1,\" Click here to upgrade\"]],[]]]]],[1,\".\\n            \"]],[]]]],[]]],[1,\"        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[18,2,null]],[\"@show\",\"&default\"],false,[\"if\",\"eq\",\"daysLeft\",\"link-to\",\"gt\",\"yield\"]]",
    "moduleName": "pickle/templates/components/upgrade-banner.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});