define("pickle/models/file", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    path: _emberData.default.attr('string'),
    user: _emberData.default.belongsTo('user'),
    account: _emberData.default.belongsTo('account'),
    contact: _emberData.default.belongsTo('contact'),
    opportunity: _emberData.default.belongsTo('opportunity'),
    public_url: _emberData.default.attr('string'),
    content_type: _emberData.default.attr('string'),
    note: _emberData.default.belongsTo('note'),
    public: _emberData.default.attr('boolean'),
    url: Ember.computed('name', function () {
      //TODO: this would provide the private url by requesting a signed url to view the document
      return 'http://mattg.me/';
    }),
    description: Ember.computed('name', function () {
      return this.get('name').split('/').pop();
    })
  });

  _exports.default = _default;
});