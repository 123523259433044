define("pickle/routes/opportunities", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    account: Ember.inject.service('current-account'),
    model: function model(params
    /*, transition*/
    ) {
      var account_id = window.localStorage.getItem('currentAccountId');

      if (Ember.isPresent(params.account_id)) {
        account_id = params.account_id;
      } else if (Ember.isNone(account_id)) {
        account_id = this.get('currentUser.default_account_id');
      }

      return this.store.query('opportunity', {
        account_id: account_id,
        type: 'opportunity'
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('opportunities', model); // make status values available everywhere

      controller.set('status_values', this.get('account.currentAccount.opportunity_states'));
      this.set('controller', controller);

      this._super(controller, model);
    }
  });

  _exports.default = _default;
});