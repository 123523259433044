define("pickle/serializers/opportunity", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      Notes: {
        serialize: false,
        deserialize: 'records' // embedded: 'always'

      },
      contacts: {
        serialize: false,
        deserialize: 'records',
        embedded: 'always'
      },
      account: 'account_id',
      Subopportunities: {
        serialize: false,
        deserialize: 'records' // embedded: 'always',

      },
      Parentopportunities: {
        serialize: false,
        deserialize: 'records' // embedded: 'always',

      }
    }
  });

  _exports.default = _default;
});