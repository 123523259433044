define("pickle/adapters/note", ["exports", "pickle/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType: function pathForType(type) {
      if (type === 'note:new') {
        return 'notes/new';
      } else {
        return this._super(type);
      }
    }
  });

  _exports.default = _default;
});