define("pickle/components/contact-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service('store'),
    contact_id: null,
    contact: null,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.get('store').findRecord('contact', this.get('contact_id')).then(function (contact) {
        _this.set('contact', contact);
      });
    }
  });

  _exports.default = _default;
});