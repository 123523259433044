define("pickle/components/list-building-checkbox-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    model: null,
    list: null,
    actions: {
      toggleChecked: function toggleChecked(isChecked) {
        if (isChecked) {
          this.get('list').pushObject(this.get('model'));
        } else {
          this.get('list').removeObject(this.get('model'));
        }
      }
    }
  });

  _exports.default = _default;
});