define("pickle/templates/groups/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "trd0zicm",
    "block": "[[[10,0],[14,0,\"flex-column left-margin\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"main container\"],[12],[1,\"\\n    \"],[10,\"h1\"],[12],[1,\"Create a group\"],[13],[1,\"\\n    \"],[19,\"groups/_form\",[]],[1,\"\\n    \"],[46,[28,[37,2],null,null],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],true,[\"partial\",\"component\",\"-outlet\"]]",
    "moduleName": "pickle/templates/groups/new.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});