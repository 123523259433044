define("pickle/adapters/table-column", ["exports", "pickle/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      if (requestType === 'updateRecord') {
        return this.buildURLForUpdateRecord(modelName, id, snapshot, requestType, query);
      } else if (requestType === 'createRecord') {
        return this.buildURLForCreateRecord(modelName, id, snapshot, requestType, query);
      } else {
        var table_uuid = null;

        if (query) {
          table_uuid = query.table ? query.table_id : null;
        }

        return "".concat(this._super(), "/tables/").concat(query.table, "/columns");
      }
    },
    buildURLForUpdateRecord: function buildURLForUpdateRecord(modelName, id, snapshot, requestType, query) {
      return "".concat(this._super(), "/tables/columns/").concat(snapshot.record.get('id'));
    },
    buildURLForCreateRecord: function buildURLForCreateRecord(modelName, id, snapshot, requestType, query) {
      return "".concat(this._super(), "/tables/columns");
    }
  });

  _exports.default = _default;
});