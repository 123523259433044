define("pickle/controllers/pipelines/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    loadingStatus: null,
    step: null,
    currentAccount: Ember.inject.service('current-account'),
    templates: [{
      id: 'sales-pipeline-template',
      name: 'Sales Pipeline',
      emoji: '👨‍💼',
      description: 'Keep track of various stages of the sales process.',
      columns: ['Backlog', 'Presentation', 'Qualification', 'Propsal', 'Negotation', 'Sale Made']
    }, {
      id: 'hiring-pipeline-template',
      name: 'Hiring Pipeline',
      emoji: '👩‍🔬',
      description: 'Move candidates across a pipeline for hiring',
      columns: ['Backlog', 'Contact Made', 'Phone Screen 1', 'Phone Screen 2', 'Offer Made', 'Offer Accepted']
    }, {
      id: 'scratch-pipeline-template',
      name: 'Scratch',
      emoji: '📝',
      description: 'Start from scratch'
    }],
    useTemplate: function useTemplate(template) {
      this.set('template', template);
      this.set('step', 'new-from-template');
      this.set('usingTemplate', true);
      window.setTimeout(function () {
        var $input = Ember.$('#pipeline_name_input');
        $input.focus();
      }, 100);
    },
    actions: {
      useTemplate: function useTemplate(template) {
        if (template == null) {
          this.startFromScratch();
        } else {
          this.set('templateId', template.id);
          this.useTemplate(template);
        }
      },
      save: function save() {
        var _this = this;

        this.set('loadingStatus', 'Generating Pipeline...');
        this.set('model.account_id', this.get('currentAccount.currentAccount.id'));
        this.set('model.account', this.get('currentAccount.currentAccount.id'));
        this.get('model').save().then(function (pipeline) {
          _this.set('loadingStatus', null);

          _this.transitionToRoute('pipelines.show', pipeline.id);
        }).catch(function (error) {
          console.log('error');

          _this.set('loadingStatus', null);
        });
      }
    }
  });

  _exports.default = _default;
});