define("pickle/components/add-todo-button/component", ["exports", "pickle/mixins/search-base"], function (_exports, _searchBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_searchBase.default, {
    showForm: false,
    isSearchingForContact: false,
    contactSearchValue: '',
    selectedContact: null,
    selectedContacts: [],
    todo: null,
    storeService: Ember.inject.service('store'),
    isSaving: false,
    account: null,
    searchForContact: function searchForContact(query) {
      this.set('isSearchingForContact', true);
      this.searchWithValues({
        query: query
      });
    },
    listenForEscape: function listenForEscape() {
      var _this = this;

      Ember.$(document).keyup(function (e) {
        // if (e.keyCode === 13) $('.save').click();     // enter
        if (e.keyCode === 27) _this.send('cancel'); // esc
      });
    },
    actions: {
      showForm: function showForm() {
        this.set('showForm', true);
        this.listenForEscape();

        if (Ember.isNone(this.get('todo'))) {
          this.set('todo', this.get('storeService').createRecord('todo'));
        }
      },
      save: function save() {
        var _this2 = this;

        this.set('isSaving', true);
        var accountId = this.get('account.id') || this.get('todo.account_id');
        this.set('todo.account_id', accountId);

        if (Ember.isNone(this.get('todo.account_id'))) {
          console.error('error - no account id provided for todo');
          return null;
        }

        this.get('todo').save().then(function (result) {
          _this2.get('storeService').pushPayload('todo', result.todo);

          _this2.sendAction('addedAction');

          _this2.send('clearForm');

          _this2.set('showForm', false);

          _this2.set('isSaving', false);
        });
      },
      contactSearchTyped: function contactSearchTyped() {
        Ember.run.debounce(this, this.searchForContact, this.get('contactSearchValue'), 300);
      },
      clearForm: function clearForm() {
        this.set('todo', null);
        this.set('showForm', false);
        this.set('isSearchingForContact', false);
        this.send('removeContact');
      },
      cancel: function cancel() {
        this.get('todo').deleteRecord();
        this.send('clearForm');
      },
      // SearchBaseMixin delegate methods
      searchCompleted: function searchCompleted(search_results) {
        this.set('isSearchingForContact', false);
        this.set('contactSearchResults', search_results.Contacts);
      },
      selectedContactItem: function selectedContactItem(contact) {
        this.set('selectedContact', contact);
        this.get('selectedContacts').addObject(contact);
        this.set('todo.entity_id', contact.id);
        this.set('todo.entity_type', 'contact');
        this.set('todo.account_id', contact.account_id);
      },
      removeContact: function removeContact(contact) {
        this.set('selectedContact', null);
        this.set('selectedContacts', []);
      }
    }
  });

  _exports.default = _default;
});