define("pickle/components/table-header-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showColumnTypeSelector: false,
    actions: {
      toggleColumnTypeSelector: function toggleColumnTypeSelector(column) {
        this.toggleProperty('showColumnTypeSelector');
      },
      selectColumnType: function selectColumnType(type, column) {
        console.log('-------------------------------');
        console.log(type);
        console.log(column);
        column.set('data_type', type.type);
        column.save();
        this.set('showColumnTypeSelector', false);
      }
    }
  });

  _exports.default = _default;
});