define("pickle/components/click-to-copy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    addSelectAll: function () {
      this.$().click(function () {
        Ember.$(this).find('input').select();
      });
    }.on('didInsertElement')
  });

  _exports.default = _default;
});