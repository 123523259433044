define("pickle/models/timeline-step", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    opportunity: _emberData.default.belongsTo('opportunity'),
    user: _emberData.default.belongsTo('user'),
    name: _emberData.default.attr(),
    description: _emberData.default.attr(),
    status: _emberData.default.attr(),
    position: _emberData.default.attr('number'),
    template_subject: _emberData.default.attr(),
    template_body: _emberData.default.attr(),
    children: _emberData.default.hasMany('timeline-step', {
      inverse: 'parent'
    }),
    parent: _emberData.default.belongsTo('timeline-step', {
      inverse: 'children'
    }),
    is_parent: _emberData.default.attr('boolean'),
    updated_at: _emberData.default.attr('date'),
    completed: Ember.computed('status', function () {
      return this.get('status') === 'complete';
    })
  });

  _exports.default = _default;
});