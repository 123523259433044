define("pickle/templates/slack-create-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VrVylit4",
    "block": "[[[6,[39,0],null,[[\"title\",\"ok\",\"close\"],[\"Slack Channel\",\"createSlackChannel\",\"removeModal\"]],[[\"default\"],[[[[11,\"form\"],[4,[38,1],[[30,0],\"ok\"],[[\"on\",\"target\"],[\"submit\",[33,2]]]],[12],[1,\"\\n\\t\"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n\\t\\t\"],[10,\"label\"],[12],[1,\"Slack Channel Name:\"],[13],[1,\"\\n\\t\\t\"],[1,[28,[35,3],null,[[\"class\",\"value\"],[\"form-control\",[33,4]]]]],[1,\"\\n\\t\\t\"],[10,1],[14,0,\"help-block\"],[12],[1,\"Choose something descriptive such as the name of this opportunity. It'll help other people know what the purpose of this channel is for :)\"],[13],[1,\"\\n\\t\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"custom-modal\",\"action\",\"view\",\"input\",\"channelName\"]]",
    "moduleName": "pickle/templates/slack-create-modal.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});