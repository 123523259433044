define("pickle/templates/components/clickable-tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RsiKxB4B",
    "block": "[[[10,1],[15,0,[29,[\"tag clickable \",[52,[33,1],\"selected\"],\" inline-block\"]]],[15,\"onclick\",[28,[37,2],[[30,0],\"toggleTag\",[33,3,[\"tags\"]]],null]],[12],[1,[33,3,[\"tags\"]]],[13],[1,\"\\n\"]],[],false,[\"if\",\"isSelected\",\"action\",\"tag\"]]",
    "moduleName": "pickle/templates/components/clickable-tag.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});