define("pickle/helpers/date-format", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dateFormat = dateFormat;
  _exports.default = void 0;

  function dateFormat(params, hash) {
    var date = params[0];

    if (hash.timeAgoInWords) {
      return (0, _moment.default)(date).fromNow();
    } else {
      var format = 'MMMM Do YYYY, h:mm:ss a';
      return (0, _moment.default)(date).format(format);
    }
  }

  var _default = Ember.Helper.helper(dateFormat);

  _exports.default = _default;
});