define("pickle/templates/components/table-cell-for-column", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JX21KiNc",
    "block": "[[[18,1,null],[1,\"\\n\"],[1,[28,[35,1],null,[[\"column\",\"value\",\"cellSavedAction\"],[[33,2],[33,3],[28,[37,4],[[30,0],[33,5]],null]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"table-value-cell\",\"column\",\"value\",\"action\",\"cellSavedAction\"]]",
    "moduleName": "pickle/templates/components/table-cell-for-column.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});