define("pickle/mixins/paid-plan-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    currentUserService: Ember.inject.service('current-user'),
    requiresPaidPlan: function requiresPaidPlan() {
      // console.log('checking current user');
      // console.log(target);
      // console.log(name);
      // console.log(descriptor);
      if (!this.get('currentUserService.currentUser.hasCurrentBillingPlan')) {
        // show modal
        // console.log('does not have a paid plan');
        return false;
      } else {
        // console.log('user has a paid plan');
        return true;
      }
    },
    actions: {
      removeModal: function removeModal() {
        this.set('showDownloadCSVModalPaid', false);
      }
    }
  });

  _exports.default = _default;
});