define("pickle/helpers/custom-field-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.customFieldValue = customFieldValue;
  _exports.default = void 0;

  function customFieldValue(params
  /*, hash*/
  ) {
    var data = params[0];
    var customFieldName = params[1];

    if (data !== null && data[customFieldName] !== undefined) {
      return data[customFieldName];
    } else {
      return null;
    }
  }

  var _default = Ember.Helper.helper(customFieldValue);

  _exports.default = _default;
});