define("pickle/components/account-avatar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="flex justify-end flex-grow pr-5">
    {{#if @user.avatar}}
    {{else}}
      <LinkTo @route="settings">
        <div class="avatar-initials">
          {{#if @user.initials}}
          <div class="content">{{@user.initials}}</div>
          {{else}}
          <div class="content">
            <LinkTo @route="settings">
            <span class="glyphicon glyphicon-user" aria-hidden="false"></span>
            </LinkTo>
          </div>
          {{/if}}
        </div>
      </LinkTo>
    {{/if}}
  </div>
  
  */
  {
    "id": "qCxcXJ+Y",
    "block": "[[[10,0],[14,0,\"flex justify-end flex-grow pr-5\"],[12],[1,\"\\n\"],[41,[30,1,[\"avatar\"]],[[],[]],[[[1,\"    \"],[8,[39,1],null,[[\"@route\"],[\"settings\"]],[[\"default\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"avatar-initials\"],[12],[1,\"\\n\"],[41,[30,1,[\"initials\"]],[[[1,\"        \"],[10,0],[14,0,\"content\"],[12],[1,[30,1,[\"initials\"]]],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,0],[14,0,\"content\"],[12],[1,\"\\n          \"],[8,[39,1],null,[[\"@route\"],[\"settings\"]],[[\"default\"],[[[[1,\"\\n          \"],[10,1],[14,0,\"glyphicon glyphicon-user\"],[14,\"aria-hidden\",\"false\"],[12],[13],[1,\"\\n          \"]],[]]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[\"@user\"],false,[\"if\",\"link-to\"]]",
    "moduleName": "pickle/components/account-avatar.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});