define("pickle/helpers/array-contains", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.arrayContains = arrayContains;
  _exports.default = void 0;

  function arrayContains(params, hash) {
    var item = params[0];
    var array = params[1];

    if (hash.runMethod === "toDateString") {
      item = item.toDateString();
    }

    if (array.indexOf(item) > -1) {
      return true;
    } else {
      return false;
    }
  }

  var _default = Ember.Helper.helper(arrayContains);

  _exports.default = _default;
});