define("pickle/templates/components/people-avatars", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UbavTSHW",
    "block": "[[[18,2,null],[1,\"\\n\\n\"],[41,[33,2],[[[1,\"  \"],[1,[34,3]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[33,6]],null]],null],null,[[[1,\"  \"],[1,[28,[35,7],null,[[\"email\",\"retina\",\"size\",\"class\",\"defaultImage\"],[[30,1,[\"email\"]],true,30,\"rounded-avatar\",\"retro\"]]]],[1,\"\\n\"]],[1]],null]],[\"contact\",\"&default\"],false,[\"yield\",\"if\",\"showText\",\"involvementString\",\"each\",\"-track-array\",\"people\",\"gravatar-image\"]]",
    "moduleName": "pickle/templates/components/people-avatars.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});