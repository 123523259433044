define("pickle/components/account-changer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.extend({
    account: Ember.inject.service('current-account'),
    actions: {
      changeAccount: function changeAccount(accountId) {
        this.get('account').changeTo(accountId);
        this.notifyChange(accountId);
      }
    }
  });

  _exports.default = _default;
});