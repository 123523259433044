define("pickle/routes/search/index", ["exports", "pickle/mixins/search-base-route"], function (_exports, _searchBaseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_searchBaseRoute.default, {});

  _exports.default = _default;
});