define("pickle/components/custom-field-select-option-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    customValue: null,
    field: null,
    contact: null,
    didInsertElement: function didInsertElement() {
      // set customValue if it exists for this key
      var data = this.get('contact.jsonb_data');

      if (data) {
        var value = data[this.get('field.name')];
        this.set('customValue', value);
      }
    },
    actions: {
      changed: function changed(value) {
        this.sendAction('action', this.get('field.name'), value);
      }
    }
  });

  _exports.default = _default;
});