define("pickle/components/group-message-composer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    messageContent: '',
    contacts: [],
    selectedContacts: [],
    sendVia: 'sms',
    sendButtonEnabled: 'disabled',
    smsEnabled: true,
    emailEnabled: true,
    didInsertElement: function didInsertElement() {
      Ember.$(function () {
        Ember.$('[data-toggle="tooltip"]').tooltip();
      });
    },
    checkIfMessageIsGoodToGo: Ember.observer('messageContent', function () {
      this.send('checkIfAbleToSendMessage');
    }),
    actions: {
      messageChanged: function messageChanged() {
        this.send('checkIfAbleToSendMessage');
      },
      checkIfAbleToSendMessage: function checkIfAbleToSendMessage() {
        this.get('selectedContacts.length') > 0 && !Ember.isEmpty(this.get('messageContent')) ? this.set('sendButtonEnabled', null) : this.set('sendButtonEnabled', 'disabled');
      },
      checkedContact: function checkedContact(contact, event) {
        if (event.target.checked) {
          this.get('selectedContacts').addObject(contact);
        } else {
          this.get('selectedContacts').removeObject(contact);
        }

        this.send('checkIfAbleToSendMessage');
        this.sendAction('contactSelectedAction', this.get('selectedContacts'));
      },
      sendMessage: function sendMessage() {
        this.sendAction('sendNotificationAction', this.get('messageContent'));
      },
      selectAll: function selectAll() {
        // this.set('selectedContacts', this.get('contacts'));
        Ember.$(".contacts>.contact>input").each(function () {
          this.click();
        });
      },
      selectedSendVia: function selectedSendVia(method) {
        this.send('checkIfAbleToSendMessage');
        this.set('sendVia', method);
        this.sendAction('sendViaChanged', method);
      }
    }
  });

  _exports.default = _default;
});