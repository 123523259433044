define("pickle/components/array-item-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    item: null,
    index: null,
    originalValue: null,
    updateAction: null,
    didInsertElement: function didInsertElement() {
      this.set('originalValue', this.get('item'));
    },
    actions: {
      update: function update() {
        // console.log('update', this.get('originalValue'), 'to', this.get('item'));
        this.sendAction('updateAction', this.get('originalValue'), this.get('item'), this.get('index'));
      }
    }
  });

  _exports.default = _default;
});