define("pickle/templates/components/custom-field-select-option-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Azt3t8Qx",
    "block": "[[[10,\"select\"],[15,\"onchange\",[28,[37,0],[[30,0],\"changed\"],[[\"value\"],[\"target.value\"]]]],[14,0,\"form-control\"],[12],[1,\"\\n    \"],[10,\"option\"],[14,2,\"\"],[12],[13],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[33,3,[\"value_options\"]]],null]],null],null,[[[41,[28,[37,5],[[30,1],[33,6]],null],[[[1,\"        \"],[8,[30,1],[[16,2,[29,[[30,1]]]],[24,\"selected\",\"\"]],null,[[\"default\"],[[[[1,[30,1]]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[30,1],[[16,2,[29,[[30,1]]]]],null,[[\"default\"],[[[[1,[30,1]]],[]]]]],[1,\"\\n\"]],[]]]],[1]],null],[13],[1,\"\\n\"]],[\"option\"],false,[\"action\",\"each\",\"-track-array\",\"field\",\"if\",\"eq\",\"customValue\"]]",
    "moduleName": "pickle/templates/components/custom-field-select-option-menu.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});