define("pickle/templates/pipelines/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kHLAcN6j",
    "block": "[[[10,0],[14,0,\"flex-column left-margin main container\"],[12],[1,\"\\n\"],[41,[28,[37,1],[[33,2],null],null],[[[1,\"\\n    \"],[19,\"pipelines/_template-chooser\",[]],[1,\"\\n\\n\"]],[]],[[[41,[28,[37,1],[[33,2],\"form\"],null],[[[1,\"\\n    \"],[19,\"pipelines/_form\",[]],[1,\"\\n\\n\"]],[]],[[[41,[33,4],[[[1,\"\\n    \"],[10,0],[14,0,\"centered\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"big-emoji-icon\"],[12],[1,[33,5,[\"emoji\"]]],[13],[1,\"\\n      \"],[10,\"h1\"],[12],[1,[33,6,[\"account_id\"]]],[1,\" New Pipeline from Template: \"],[1,[33,5,[\"name\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[41,[51,[33,8]],[[[1,\"      \"],[19,\"pipelines/_form\",[]],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[14,0,\"centered\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"loading-spinner\"],[12],[13],[1,\"\\n        \"],[10,2],[12],[1,[34,8]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"\\n  \"]],[]],null]],[]]]],[]]],[1,\"\\n  \"],[46,[28,[37,10],null,null],null,null,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[],true,[\"if\",\"eq\",\"step\",\"partial\",\"usingTemplate\",\"template\",\"model\",\"unless\",\"loadingStatus\",\"component\",\"-outlet\"]]",
    "moduleName": "pickle/templates/pipelines/new.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});