define("pickle/templates/components/icon-for-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CEjKsNg/",
    "block": "[[[18,1,null],[1,\"\\n\"],[41,[28,[37,2],[[33,3],\"note\"],null],[[[1,\"    \"],[10,1],[14,0,\"glyphicon glyphicon-edit\"],[12],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,2],[[33,3],\"file\"],null],[[[1,\"    \"],[10,1],[14,0,\"glyphicon glyphicon-file\"],[12],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,2],[[33,3],\"contact\"],null],[[[1,\"    \"],[10,1],[14,0,\"glyphicon glyphicon-user\"],[12],[13],[1,\"\\n\"]],[]],null]],[]]]],[]]]],[\"&default\"],false,[\"yield\",\"if\",\"eq\",\"type\"]]",
    "moduleName": "pickle/templates/components/icon-for-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});