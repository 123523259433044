define("pickle/templates/components/contact-initials-avatar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ymp6WIhU",
    "block": "[[[10,0],[15,0,[29,[\"contact-initials-avatar \",[30,0,[\"args\",\"class\"]],\" \"]]],[12],[1,[30,0,[\"initials\"]]],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "pickle/templates/components/contact-initials-avatar.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});