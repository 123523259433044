define("pickle/templates/components/loading-dots", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5Vj0nnht",
    "block": "[[[10,0],[14,0,\"\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"centered\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"loader loader-3\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"dot dot1\"],[12],[13],[1,\"\\n            \"],[10,0],[14,0,\"dot dot2\"],[12],[13],[1,\"\\n            \"],[10,0],[14,0,\"dot dot3\"],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[18,1,null],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "pickle/templates/components/loading-dots.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});