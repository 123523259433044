define("pickle/controllers/tables/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      save: function save() {
        var _this = this;

        this.model.save().then(function (table) {
          _this.transitionToRoute('tables.show', table);
        });
      }
    }
  });

  _exports.default = _default;
});