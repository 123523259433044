define("pickle/templates/contacts/show-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oy3KiB+n",
    "block": "[[[1,\"SHOW LOADING\"]],[],false,[]]",
    "moduleName": "pickle/templates/contacts/show-loading.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});