define("pickle/models/feature-flag", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    user: _emberData.default.belongsTo('user'),
    is_enabled: _emberData.default.attr('boolean'),
    created_at: _emberData.default.attr('date'),
    invited_by: _emberData.default.attr('string')
  });

  _exports.default = _default;
});