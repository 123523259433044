define("pickle/templates/components/imported-contact-column-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Sr+3GrMY",
    "block": "[[[6,[39,0],null,[[\"placeholder\",\"options\",\"selected\",\"onchange\",\"searchField\"],[\"Select Mapping\",[33,1],[33,2],[28,[37,3],[[30,0],\"columnChanged\"],null],\"displayName\"]],[[\"default\"],[[[[1,\"  \"],[1,[30,1,[\"displayName\"]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"],[41,[33,2],[[[10,1],[14,0,\"inline-flex\"],[12],[1,\"\\n  \"],[11,3],[4,[38,3],[[30,0],\"clearSelection\",[30,0]],null],[12],[1,\"Reset\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[]],null]],[\"columnName\"],false,[\"power-select\",\"columnNames\",\"selected\",\"action\",\"if\"]]",
    "moduleName": "pickle/templates/components/imported-contact-column-row.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});