define("pickle/services/notification-center", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    reloadGroup: false,
    reloadContactNotes: false,
    tellGroupToUpdate: function tellGroupToUpdate() {
      this.set('reloadGroup', true);
    }
  });

  _exports.default = _default;
});