define("pickle/controllers/opportunities", ["exports", "pickle/mixins/keyboard-shortcuts"], function (_exports, _keyboardShortcuts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_keyboardShortcuts.default, {
    queryParams: ['account_id'],
    currentUser: Ember.inject.service('current-user')
  });

  _exports.default = _default;
});