define("pickle/helpers/is-equal-day", ["exports", "ember-date-components/helpers/is-equal-day"], function (_exports, _isEqualDay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _isEqualDay.default;
    }
  });
  Object.defineProperty(_exports, "isEqualDay", {
    enumerable: true,
    get: function get() {
      return _isEqualDay.isEqualDay;
    }
  });
});