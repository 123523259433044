define("pickle/helpers/is-even", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function isEven(params
  /*, hash*/
  ) {
    if (params % 2 == 0) return true;
    return false;
  });

  _exports.default = _default;
});