define("pickle/templates/sms-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y+c3TwEX",
    "block": "[[[6,[39,0],null,[[\"title\",\"ok\",\"close\",\"footer\"],[\"Send a Text Message\",\"Send Messsage\",\"removeModal\",false]],[[\"default\"],[[[[1,\"    \"],[1,[28,[35,1],null,[[\"contact\"],[[33,2]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"custom-modal\",\"sms-composer\",\"model\"]]",
    "moduleName": "pickle/templates/sms-modal.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});