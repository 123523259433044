define("pickle/routes/opportunities/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      this.set('contact_id', params.contact_id);
      return this.store.createRecord('opportunity');
    },
    afterModel: function afterModel(opportunity) {
      if (!Ember.isNone(this.get('contact_id'))) {
        this.store.findRecord('contact', this.get('contact_id')).then(function (contact) {
          opportunity.get('contacts').addObject(contact);
        });
      }
    },
    resetController: function resetController(controller, isExiting, transition) {
      if (isExiting) {
        controller.set('contact_id', null);
      }
    }
  });

  _exports.default = _default;
});