define("pickle/templates/contacts/todos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SY5Xd22P",
    "block": "[[[1,[28,[35,0],[\"Todos\"],null]],[1,\"\\n\\n\"],[10,0],[14,0,\"flex-column\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"container main centered center-content\"],[12],[1,\"\\n        \"],[10,\"h1\"],[12],[1,[30,1,[\"fullName\"]]],[1,\"'s Todos\"],[13],[1,\"\\n        \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,1,[\"todos\"]]],null]],null],null,[[[1,\"            \"],[8,[39,3],null,[[\"@todo\"],[[30,2]]],null],[1,\"\\n\"]],[2]],null],[1,\"        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"block\"],[12],[1,\"\\n            \"],[8,[39,4],null,[[\"@entityType\",\"@model\"],[\"contact\",[30,1]]],null],[1,\"\\n        \"],[13],[1,\"\\n        \"],[46,[28,[37,6],null,null],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[\"@model\",\"todo\"],false,[\"page-title\",\"each\",\"-track-array\",\"todo-list-item\",\"add-todo-form\",\"component\",\"-outlet\"]]",
    "moduleName": "pickle/templates/contacts/todos.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});