define("pickle/templates/companies/_form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F/3OEpjQ",
    "block": "[[[11,\"form\"],[4,[38,0],[[30,0],\"save\"],[[\"on\"],[\"submit\"]]],[12],[1,\"\\n\\t\"],[10,0],[14,0,\"form-callout-block\"],[12],[1,\"\\n\\t\\t\"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n\\t\\t\\t\"],[10,\"label\"],[14,\"for\",\"name\"],[12],[1,\"Name: \"],[13],[1,\"\\n\\t\\t\\t\"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n\\t\\t\\t\\t\"],[1,[28,[35,1],null,[[\"value\",\"class\",\"placeholder\"],[[33,2,[\"name\"]],\"form-control fifty right-margin\",\"Company Name\"]]]],[1,\"\\n\\t\\t\\t\"],[13],[1,\"\\n\\t\\t\"],[13],[1,\"\\n\\t\"],[13],[1,\"\\n\\n\"],[1,\"\\n\\t\"],[10,0],[14,0,\"form-actions flex-bottom margin-top\"],[12],[1,\"\\n\"],[41,[33,4],[[[1,\"\\t\\t\\tSaving...\\n\"]],[]],[[[1,\"\\t\\t\\t\"],[10,\"button\"],[14,1,\"save_contact_button\"],[14,0,\"button-shadow green\"],[14,4,\"submit\"],[12],[1,\"Save Company\"],[13],[1,\"\\n\"]],[]]],[1,\"\\t\\t\"],[41,[33,2,[\"id\"]],[[[10,0],[14,0,\"cancel\"],[12],[1,\" or \"],[11,3],[24,6,\"#\"],[4,[38,0],[[30,0],\"cancelEdit\",[33,2],\"companies.show\"],null],[12],[1,\"cancel\"],[13],[13]],[]],null],[1,\"\\n\\t\\t\"],[41,[33,2,[\"id\"]],[[[10,0],[14,0,\"delete\"],[12],[1,\" \"],[11,3],[24,6,\"#\"],[4,[38,0],[[30,0],\"delete\",[33,2]],null],[12],[1,\"delete\"],[13],[13]],[]],null],[1,\"\\n\\t\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"action\",\"input\",\"model\",\"if\",\"isSaving\"]]",
    "moduleName": "pickle/templates/companies/_form.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});