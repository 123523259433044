define("pickle/components/catchups-empty-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="empty-state centered">
      <div class="graphic">👩‍🚀 👩‍🔬 👨‍🍳</div>
      <p>Never forget to follow up with someone</p>
      <ul>
          <li>Catchups help strengthen your relationships by reminding you to catch up often</li>
      </ul>
      <div>
          To create a catchup, click on a contact and add a catchup reminder.
      </div>
      {{yield}}
  </div>
  */
  {
    "id": "X847YDnN",
    "block": "[[[10,0],[14,0,\"empty-state centered\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"graphic\"],[12],[1,\"👩‍🚀 👩‍🔬 👨‍🍳\"],[13],[1,\"\\n    \"],[10,2],[12],[1,\"Never forget to follow up with someone\"],[13],[1,\"\\n    \"],[10,\"ul\"],[12],[1,\"\\n        \"],[10,\"li\"],[12],[1,\"Catchups help strengthen your relationships by reminding you to catch up often\"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[12],[1,\"\\n        To create a catchup, click on a contact and add a catchup reminder.\\n    \"],[13],[1,\"\\n    \"],[18,1,null],[1,\"\\n\"],[13]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "pickle/components/catchups-empty-view.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});