define("pickle/routes/account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    afterModel: function afterModel() {
      this.set('current_user', 'booger');
      this.set('parent-object', 'what!?!?!?!??!');
    },
    setupController: function setupController(controller
    /*, model*/
    ) {
      controller.set('current_user', 'testing again wee');
    }
  });

  _exports.default = _default;
});