define("pickle/adapters/search", ["exports", "pickle/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    buildURL: function buildURL(model, id, snapshot, requestType, query) {
      if (requestType === 'query' || requestType === 'createRecord') {
        return "".concat(this._super(), "/search/saved");
      } else {
        return this._super(model, id, snapshot, requestType, query);
      }
    }
  });

  _exports.default = _default;
});