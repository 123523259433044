define("pickle/components/simple-opportunity-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    opportunity: null,
    classNames: ['simple-opportunity-cell'],
    linkToDestination: Ember.computed('opportunity', function () {
      if (this.get('opportunity.type') === 'timeline') {
        return 'timelines.show';
      } else {
        return 'opportunities.show';
      }
    })
  });

  _exports.default = _default;
});