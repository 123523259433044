define("pickle/components/json-debugger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    jsonObject: {},
    jsonObjectStringified: Ember.computed('jsonObject', function () {
      return JSON.stringify(this.get('jsonObject'), null, 4);
    })
  });

  _exports.default = _default;
});