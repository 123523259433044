define("pickle/helpers/phone-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.phoneNumber = phoneNumber;

  function phoneNumber(params
  /*, hash*/
  ) {
    var number = params[0];

    if (Ember.isNone(number)) {
      return '';
    }

    if (number.length === 10) {
      return number.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    } else if (number.length === 12) {
      // assumed: +14082345789
      return number.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1 ($2) $3-$4');
    } else {
      return number;
    }
  }

  var _default = Ember.Helper.helper(phoneNumber);

  _exports.default = _default;
});