define("pickle/mixins/notes-profile-show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    sortingOrders: ['updated_at:desc'],
    reversedNotes: Ember.computed.sort('model.Notes', 'sortingOrders'),
    isLoadingMoreNotes: false,
    isLoadingNotes: false,
    currentAccount: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    endOfNotesReached: false,
    notesPage: 0,
    actions: {
      // TODO: This is almost reusable without company_id
      loadMoreNotes: function loadMoreNotes() {
        var _this = this;

        this.set('isLoadingMoreNotes', true);
        this.incrementProperty('notesPage', 1);
        var limit = 5;
        this.store.query('note', {
          company_id: this.get('model.id'),
          account_id: this.get('model.account.id'),
          page: this.get('notesPage'),
          limit: limit
        }).then(function (notes) {
          if (notes.get('length') < limit) _this.set('endOfNotesReached', true);

          _this.get('model.Notes').addObjects(notes);

          _this.set('isLoadingMoreNotes', false);
        });
      }
    }
  });

  _exports.default = _default;
});