define("pickle/models/table-column-value", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    uuid: _emberData.default.attr('string'),
    object_type: _emberData.default.attr('string'),
    object_id: _emberData.default.attr('number'),
    content_value: _emberData.default.attr('string'),
    row_uuid: _emberData.default.attr('string'),
    table_column_id: _emberData.default.attr('number'),
    created_at: _emberData.default.attr('date'),
    updated_at: _emberData.default.attr('date'),
    table_column: _emberData.default.belongsTo('table-column'),
    user: _emberData.default.belongsTo('user')
  });

  _exports.default = _default;
});