define("pickle/templates/components/click-to-copy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1eRaGXEl",
    "block": "[[[1,[28,[35,0],null,[[\"type\",\"value\"],[\"text\",[33,1]]]]],[1,\"\\n\"],[18,1,null],[1,\"\\n\"]],[\"&default\"],false,[\"input\",\"value\",\"yield\"]]",
    "moduleName": "pickle/templates/components/click-to-copy.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});