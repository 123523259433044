define("pickle/models/message-reply", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    reply_data: _emberData.default.attr(),
    message: _emberData.default.belongsTo('message'),
    contact_id: _emberData.default.attr(),
    contact: _emberData.default.belongsTo('contact', {
      async: true
    }),
    created_at: _emberData.default.attr('date'),
    updated_at: _emberData.default.attr('date')
  });

  _exports.default = _default;
});