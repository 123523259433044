define("pickle/components/navigation-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
      <header class="sticky-navbar">
      <div class="logo">
        <LinkTo @route="index" class="pickle-logo">
          <div class="logo"></div>
        </LinkTo>
      </div>
  
      {{#if (gt this.user.accounts.length 1)}}
        <div class="account-changer">
          <AccountChanger @notifyChange={{fn this.accountChanged}} />
        </div>
      {{/if}}
  
      <NavigationSearch />
  
      <div class="flex items-center h-full justify-evenly">
        <LinkTo @route="contacts" class="navlink">People</LinkTo>
        <LinkTo @route="notes" class="navlink">Notes</LinkTo>
        <LinkTo @route="history.index" class="navlink">Activity</LinkTo>
      </div>
      <div class="flex items-center justify-end flex-grow h-full">
        <LinkTo @route="contacts.new" class="px-4 py-1 mr-5 font-bold text-white bg-blue-500 rounded-full shadow hover:bg-purple-900 dark:bg-purple-800">
          Add New Contact
        </LinkTo>
        <LinkTo @route='notes.new' class="px-4 py-1 font-bold text-gray-500 rounded-full shadow bg-smoke-100 hover:bg-blue-700 hover:text-white">
          Add a Note
        </LinkTo>
      </div>
  
      <AccountAvatar @user={{this.user}} class="flex border-red-500"/>
  
    </header>
  
  */
  {
    "id": "552jpZgx",
    "block": "[[[1,\"  \"],[10,\"header\"],[14,0,\"sticky-navbar\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"logo\"],[12],[1,\"\\n      \"],[8,[39,0],[[24,0,\"pickle-logo\"]],[[\"@route\"],[\"index\"]],[[\"default\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"logo\"],[12],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[41,[28,[37,2],[[30,0,[\"user\",\"accounts\",\"length\"]],1],null],[[[1,\"      \"],[10,0],[14,0,\"account-changer\"],[12],[1,\"\\n        \"],[8,[39,3],null,[[\"@notifyChange\"],[[28,[37,4],[[30,0,[\"accountChanged\"]]],null]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[8,[39,5],null,null,null],[1,\"\\n\\n    \"],[10,0],[14,0,\"flex items-center h-full justify-evenly\"],[12],[1,\"\\n      \"],[8,[39,0],[[24,0,\"navlink\"]],[[\"@route\"],[\"contacts\"]],[[\"default\"],[[[[1,\"People\"]],[]]]]],[1,\"\\n      \"],[8,[39,0],[[24,0,\"navlink\"]],[[\"@route\"],[\"notes\"]],[[\"default\"],[[[[1,\"Notes\"]],[]]]]],[1,\"\\n      \"],[8,[39,0],[[24,0,\"navlink\"]],[[\"@route\"],[\"history.index\"]],[[\"default\"],[[[[1,\"Activity\"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"flex items-center justify-end flex-grow h-full\"],[12],[1,\"\\n      \"],[8,[39,0],[[24,0,\"px-4 py-1 mr-5 font-bold text-white bg-blue-500 rounded-full shadow hover:bg-purple-900 dark:bg-purple-800\"]],[[\"@route\"],[\"contacts.new\"]],[[\"default\"],[[[[1,\"\\n        Add New Contact\\n      \"]],[]]]]],[1,\"\\n      \"],[8,[39,0],[[24,0,\"px-4 py-1 font-bold text-gray-500 rounded-full shadow bg-smoke-100 hover:bg-blue-700 hover:text-white\"]],[[\"@route\"],[\"notes.new\"]],[[\"default\"],[[[[1,\"\\n        Add a Note\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[8,[39,6],[[24,0,\"flex border-red-500\"]],[[\"@user\"],[[30,0,[\"user\"]]]],null],[1,\"\\n\\n  \"],[13],[1,\"\\n\"]],[],false,[\"link-to\",\"if\",\"gt\",\"account-changer\",\"fn\",\"navigation-search\",\"account-avatar\"]]",
    "moduleName": "pickle/components/navigation-bar.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});