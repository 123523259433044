define("pickle/components/contact-select-picker", ["exports", "pickle/mixins/search-base"], function (_exports, _searchBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_searchBase.default, {
    searchValue: null,
    isSearching: false,
    isError: false,
    contacts: null,
    selectedContact: null,
    showGenericContactsLayout: false,
    account: null,
    accountId: Ember.computed('account', function () {
      return this.get('account.id');
    }),
    store: Ember.inject.service(),
    labelText: "Contact Search",
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.$('input').keyup(function (e) {
        if (e.keyCode === 27) {
          // esc pressed
          _this.cancelSearch();
        }
      });
    },
    cancelSearch: function cancelSearch() {
      this.set('searchValue', null);
      this.set('contacts', null);
      this.set('isSearching', false); // this.$('input').val();

      this.set('searchValue', null);
    },
    keyTyped: Ember.observer('searchValue', function () {
      if (this.get('searchValue') && this.get('searchValue').length >= 3) {
        this.searchForContactWithValue(this.get('searchValue'));
      }
    }),
    searchForContactWithValue: function searchForContactWithValue(query) {
      return this.searchWithValues({
        query: query
      });
    },
    handleContactResults: function handleContactResults(contacts) {
      this.set('contacts', contacts);
    },
    actions: {
      searchCompleted: function searchCompleted(results) {
        this.set('isSearching', false);
        this.get('store').pushPayload('contact', results.Contacts);
        this.set('contacts', results.Contacts);
      }
    }
  });

  _exports.default = _default;
});