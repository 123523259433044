define("pickle/templates/fullscreen-split", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DL57XLWv",
    "block": "[[[10,0],[14,0,\"fullscreen\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"left\"],[12],[1,\"\\n      \"],[46,[28,[37,1],null,null],null,null,null],[1,\"\\n      \"],[46,[28,[37,1],[\"modal\"],null],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"right\"],[12],[1,\"\\n        \"],[46,[28,[37,1],[\"right\"],null],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[],false,[\"component\",\"-outlet\"]]",
    "moduleName": "pickle/templates/fullscreen-split.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});