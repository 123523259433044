define("pickle/components/achievement-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['achievement'],
    click: function click() {
      this.element.style.display = "none"; // Ember.$(this.element).hide();
    }
  });

  _exports.default = _default;
});