define("pickle/templates/settings/account/users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/0nG0dTr",
    "block": "[[[10,\"h1\"],[12],[1,[33,0,[\"length\"]]],[1,\" Users:\"],[13],[1,\"\\n\\n\"],[10,\"table\"],[14,0,\"table\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[33,3]],null]],null],null,[[[1,\"        \"],[10,\"tr\"],[12],[1,\"\\n            \"],[10,\"td\"],[12],[1,[30,1,[\"first_name\"]]],[13],[1,\"\\n            \"],[10,\"td\"],[12],[1,[30,1,[\"last_name\"]]],[13],[1,\"\\n            \"],[10,\"td\"],[12],[1,[30,1,[\"email\"]]],[13],[1,\"\\n            \"],[10,\"td\"],[12],[10,1],[14,0,\"badge\"],[12],[1,[30,1,[\"accounts_users\",\"access\"]]],[13],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[1]],null],[13],[1,\"\\n\\n\\n\"],[10,\"hr\"],[12],[13],[1,\"\\n\"],[46,[28,[37,5],null,null],null,null,null]],[\"user\"],false,[\"model\",\"each\",\"-track-array\",\"userList\",\"component\",\"-outlet\"]]",
    "moduleName": "pickle/templates/settings/account/users.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});