define("pickle/helpers/get-object-at-index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.getObjectAtIndex = getObjectAtIndex;

  // helper that allows returning a dyanmic index for an array in handlebars
  // example: {{get-object-at-index array dynamicIndex}}
  function getObjectAtIndex(params
  /*, hash*/
  ) {
    return params[0][params[1]];
  }

  var _default = Ember.Helper.helper(getObjectAtIndex);

  _exports.default = _default;
});