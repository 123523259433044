define("pickle/templates/contacts/show/notes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QW8Gs+PR",
    "block": "[[[1,[28,[35,0],[\"Notes\"],null]],[1,\"\\n\"],[46,[28,[37,2],null,null],null,null,null],[1,\"\\n\"],[10,0],[14,0,\"p-2 note-box-container\"],[12],[1,\"\\n  \"],[8,[39,3],null,[[\"@model\",\"@users\",\"@contact_id\",\"@account\",\"@type\",\"@style\",\"@isEditing\",\"@editingNote\",\"@store\"],[[30,0,[\"model\"]],[30,0,[\"users\"]],[30,0,[\"model\",\"id\"]],[33,4,[\"currentAccount\"]],\"contact\",\"inline\",[30,0,[\"isEditingNote\"]],[30,0,[\"editingNote\"]],[30,0,[\"store\"]]]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,5],null,[[\"@notes\",\"@current_user\",\"@account\",\"@isLoadingMoreNotes\",\"@hideLoadMoreButton\",\"@loading\"],[[30,0,[\"model\"]],[30,0,[\"current_user\"]],[30,0,[\"currentAccount\",\"currentAccount\"]],[36,6],[30,0,[\"endOfNotesReached\"]],[36,7]]],null],[1,\"\\n\"]],[],false,[\"page-title\",\"component\",\"-outlet\",\"notes-box\",\"currentAccount\",\"notes-timeline\",\"isLoadingMoreNotes\",\"isLoadingNotes\"]]",
    "moduleName": "pickle/templates/contacts/show/notes.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});