define("pickle/templates/partials/no-contacts-involved", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+qtXBYOo",
    "block": "[[[41,[51,[33,1]],[[[1,\"    \"],[10,0],[14,0,\"flex-col text-center content-center\"],[12],[1,\"\\n\"],[41,[51,[33,2]],[[[1,\"        \"],[10,\"img\"],[14,0,\"m-auto\"],[14,\"src\",\"/images/icons/employee-badge.png\"],[14,\"alt\",\"Contact Badge\"],[14,\"width\",\"150\"],[14,5,\"opacity:0.75\"],[12],[13],[1,\"\\n        \"],[10,2],[12],[1,[34,3]],[13],[1,\"\\n        \"],[11,\"button\"],[24,0,\"add-person-button btn btn-sm btn-default always-display button-shadow green\"],[4,[38,4],[[30,0],\"showAddPersonForm\"],null],[12],[10,\"i\"],[14,0,\"green glyphicon glyphicon-plus\"],[12],[13],[1,\"\\n            Add Person\"],[13],[1,\"\\n        \"],[10,2],[12],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[11,\"button\"],[24,0,\"add-person-button btn btn-sm btn-default always-display button-shadow green\"],[4,[38,4],[[30,0],\"showAddPersonForm\"],null],[12],[10,\"i\"],[14,0,\"green glyphicon glyphicon-plus\"],[12],[13],[1,\"\\n            Add Person\"],[13],[1,\"\\n        \"],[10,2],[12],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"unless\",\"hideAddAndDoneButtons\",\"minimized\",\"addContactsText\",\"action\"]]",
    "moduleName": "pickle/templates/partials/no-contacts-involved.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});