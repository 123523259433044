define("pickle/templates/pipelines/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QZe+YTp5",
    "block": "[[[46,[28,[37,1],null,null],null,null,null],[1,\"\\n\\n\"],[10,0],[14,0,\"main container\"],[12],[1,\"\\n    \"],[10,\"h1\"],[12],[1,[33,2,[\"name\"]]],[13],[1,\"\\n\"],[1,\"\\n\"],[41,[33,4],[[[6,[39,5],null,[[\"movedAction\"],[[28,[37,6],[[30,0],\"opportunityMoved\"],null]]],[[\"default\"],[[[[1,\"            \"],[10,0],[14,0,\"flexbox pipelines\"],[12],[1,\"\\n\"],[42,[28,[37,8],[[33,4]],null],null,[[[1,\"                    \"],[10,0],[14,0,\"column\"],[12],[1,\"\\n                        \"],[10,\"h3\"],[12],[1,[30,2]],[13],[1,\"\\n\"],[1,\"                        \"],[10,0],[14,0,\"pipeline-column container\"],[15,\"data-state\",[29,[[30,2]]]],[12],[1,\"\\n\"],[42,[28,[37,9],[[28,[37,9],[[30,1]],null]],null],null,[[[1,\"                                \"],[1,[28,[35,10],null,[[\"column\",\"opportunity\"],[[30,2],[30,3]]]]],[1,\"\\n\"]],[3]],null],[1,\"                        \"],[13],[1,\"\\n                    \"],[13],[1,\"\\n\"]],[1,2]],null],[1,\"            \"],[13],[1,\"\\n\"]],[]]]]]],[]],null],[41,[33,11],[[[1,\"        \"],[10,0],[12],[1,\"\\n            Saving \"],[10,1],[14,0,\"loading-spinner-sm\"],[12],[13],[1,\" \\n        \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"items\",\"column\",\"opportunity\"],false,[\"component\",\"-outlet\",\"model\",\"if\",\"columnGroups\",\"pipeline-container\",\"action\",\"each\",\"-each-in\",\"-track-array\",\"pipeline-container-cell\",\"isSaving\"]]",
    "moduleName": "pickle/templates/pipelines/show.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});