define("pickle/templates/errors/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3u9mXwCT",
    "block": "[[[46,[28,[37,1],null,null],null,null,null],[1,\"\\n\\n\"],[10,0],[14,0,\"error\"],[12],[1,\"\\n    \"],[10,\"h1\"],[12],[1,\"Shoot a Pickle!\"],[13],[1,\"\\n    \"],[10,2],[12],[1,\"We couldn't find the page you were looking for. If you think this is an error, \"],[11,3],[24,0,\"pointer\"],[4,[38,2],[[30,0],\"showModal\",\"app-feedback-modal\"],null],[12],[1,\"let us know.\"],[13],[13],[1,\"\\n\"],[13]],[],false,[\"component\",\"-outlet\",\"action\"]]",
    "moduleName": "pickle/templates/errors/not-found.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});