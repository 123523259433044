define("pickle/routes/cases/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    account: Ember.inject.service('current-account'),
    model: function model(params) {
      var account_id = window.localStorage.getItem('currentAccountId');

      if (Ember.isPresent(params.account_id)) {
        account_id = params.account_id;
      } else if (Ember.isNone(account_id)) {
        account_id = this.get('currentUser.default_account_id');
      }

      return this.store.query('opportunity', {
        account_id: account_id,
        type: 'case'
      });
    },
    setupController: function setupController(controller, model) {
      if (Ember.isNone(this.get('account.currentAccount.id'))) {
        controller.set('cases', model);
      }

      this._super(controller, model);
    },
    actions: {
      accountChanged: function accountChanged() {
        var _this = this;

        this.store.query('opportunity', {
          account_id: this.get('account.currentAccount.id'),
          type: 'case'
        }).then(function (opportunities) {
          _this.set('controller.opportunities', opportunities);

          _this.set('controller.model', opportunities);
        });
      }
    }
  });

  _exports.default = _default;
});