define("pickle/components/tab-bar-navigation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zLbsyvLJ",
    "block": "[[[18,1,null],[1,\"\\n\\n\"],[6,[39,1],null,[[\"class\",\"activeClass\",\"route\"],[\"tab-bar-item\",\"active\",\"contacts.index\"]],[[\"default\"],[[[[10,1],[14,0,\"glyphicon glyphicon-book\"],[14,\"aria-hidden\",\"false\"],[12],[13],[1,\" Contacts\"]],[]]]]],[1,\"\\n\"],[6,[39,1],null,[[\"class\",\"activeClass\",\"route\"],[\"tab-bar-item\",\"active\",\"notes.new\"]],[[\"default\"],[[[[10,1],[14,0,\"glyphicon glyphicon-plus\"],[14,\"aria-hidden\",\"false\"],[12],[13],[1,\" Quick Note\"]],[]]]]],[1,\"\\n\"],[6,[39,1],null,[[\"class\",\"activeClass\",\"route\"],[\"tab-bar-item\",\"active\",\"search\"]],[[\"default\"],[[[[10,1],[14,0,\"glyphicon glyphicon-search\"],[14,\"aria-hidden\",\"false\"],[12],[13],[1,\" Search\"]],[]]]]]],[\"&default\"],false,[\"yield\",\"link-to\"]]",
    "moduleName": "pickle/components/tab-bar-navigation/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});