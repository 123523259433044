define("pickle/templates/components/profile-action-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TxbF7YF5",
    "block": "[[[10,0],[14,0,\"centered\"],[12],[1,\"\\n    \"],[41,[33,1,[\"button_icon_url\"]],[[[10,\"img\"],[15,\"src\",[29,[[33,1,[\"button_icon_url\"]]]]],[14,\"alt\",\"\"],[14,\"width\",\"50\"],[14,\"height\",\"50\"],[12],[13]],[]],null],[1,\"\\n    \"],[41,[33,1,[\"emoji\"]],[[[10,0],[14,0,\"big-emoji-icon\"],[12],[1,[33,1,[\"emoji\"]]],[13]],[]],null],[1,\"\\n    \"],[10,\"h1\"],[12],[1,[33,1,[\"button_name\"]]],[13],[1,\"\\n    \"],[10,2],[12],[1,[33,1,[\"description\"]]],[13],[1,\"\\n    \"],[1,[28,[35,2],null,[[\"value\",\"theme\",\"onToggle\"],[[33,1,[\"is_enabled\"]],\"ios\",[28,[37,3],[[30,0],\"toggleAction\"],null]]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[28,[37,4],[[33,1,[\"is_enabled\"]],[33,1,[\"required_data\"]]],null],[[[1,\"    \"],[10,\"hr\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"profile-action-required-data margin-top centered\"],[12],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[33,1,[\"required_data\"]]],null]],null],null,[[[1,\"            \"],[1,[28,[35,7],null,[[\"class\",\"type\",\"placeholder\",\"value\"],[\"form-control\",\"text\",[30,1,[\"name\"]],[28,[37,8],[[33,1,[\"private_metadata\"]],[30,1,[\"key\"]]],null]]]]],[1,\"\\n            \"],[10,2],[14,0,\"gray-text\"],[12],[1,[30,1,[\"info\"]]],[13],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n\\n\"],[41,[33,9],[[[1,\"        \"],[11,\"button\"],[24,0,\"button-shadow green\"],[24,\"disabled\",\"\"],[4,[38,3],[[30,0],\"saveRequiredData\"],null],[12],[10,0],[14,0,\"loading-spinner-sm\"],[12],[13],[1,\" Saving... \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[11,\"button\"],[24,0,\"button-shadow green\"],[4,[38,3],[[30,0],\"saveRequiredData\"],null],[12],[1,\"Save\"],[13],[1,\"\\n\"]],[]]]],[]],null],[1,\"\\n\"],[10,\"footer\"],[12],[1,\"Created by: \"],[1,[33,1,[\"author_name\"]]],[13],[1,\"\\n\\n\"],[18,2,null]],[\"required_data\",\"&default\"],false,[\"if\",\"profileAction\",\"x-toggle\",\"action\",\"and\",\"each\",\"-track-array\",\"input\",\"get\",\"isSaving\",\"yield\"]]",
    "moduleName": "pickle/templates/components/profile-action-box.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});