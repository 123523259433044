define("pickle/components/dropbox-files", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import Dropbox from 'dropbox';
  var _default = Ember.Component.extend({
    files: null,
    dropbox_folder_path: null,
    dropboxAccessToken: null,
    isAuthenticated: false,
    didInsertElement: function didInsertElement() {
      var self = this;
      Ember.$.getScript('//cdnjs.cloudflare.com/ajax/libs/dropbox.js/0.10.2/dropbox.min.js').then(function () {
        // Browser-side applications do not use the API secret.
        var client = new window.Dropbox.Client({
          key: "vmd2cvgauyylgdx",
          maxApiServer: 0,
          token: self.get('dropboxAccessToken')
        });
        self.set('isAuthenticated', client.isAuthenticated());
        client.readdir(self.get('dropbox_folder_path'), function (error, entries, dir_stat
        /*, entry_stats*/
        ) {
          if (error) {// console.log(error);
          } else {
            //self.controller.set('entries', [{'facebook':'no'}]);
            self.set('files', dir_stat._json.contents); // console.log(dir_stat);
          }
        });
      });
      Ember.$(function () {
        Ember.$('[data-toggle="tooltip"]').tooltip();
      });
    }
  });

  _exports.default = _default;
});