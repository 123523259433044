define("pickle/components/history-event-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    routeName: Ember.computed('event.entity_type', function () {
      switch (this.get('event.entity_type')) {
        case 'contact':
          return 'contacts.show';
          break;

        case 'note':
          return 'notes.show';
          break;

        case 'file':
          return 'files.show';
          break;

        default:
          break;
      }
    })
  });

  _exports.default = _default;
});