define("pickle/templates/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U/f3VF3N",
    "block": "[[[10,0],[14,0,\"flex flex-column center\"],[12],[1,\"\\n  \"],[10,\"img\"],[14,\"src\",\"/images/dog-oops.jpg\"],[14,\"alt\",\"sad dog\"],[14,\"width\",\"600\"],[14,\"height\",\"600\"],[14,0,\"rounded-lg\"],[12],[13],[1,\"\\n  \"],[10,\"h2\"],[14,0,\"font-bold dark:text-gray-100\"],[12],[1,\"Oops, something went wrong.\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "pickle/templates/error.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});