define("pickle/tagcolors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "_default": {
      class: "bg-gray-200 text-gray-800"
    },
    "hockey": {
      class: "bg-red-500"
    },
    "8x8": {
      class: "bg-red-500"
    },
    "work": {
      class: "bg-yellow-900"
    },
    "meeting": {
      class: "bg-gray-500"
    },
    "san jose": {
      class: "bg-teal-500"
    },
    "cbury": {
      class: "bg-blue-400 text-blue-800"
    },
    "family": {
      class: "bg-purple-400"
    },
    "rit": {
      class: "bg-orange-500 text-orange-900"
    },
    "neighbor": {
      class: "bg-green-500 text-white"
    },
    "neighbors": {
      class: "bg-green-500 text-white"
    },
    "sailing": {
      class: "bg-blue-200 text-gray-800"
    },
    "sailor": {
      class: "bg-blue-200 text-gray-800"
    },
    "annapolis": {
      class: "bg-blue-200 text-gray-800"
    }
  };
  _exports.default = _default;
});