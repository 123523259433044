define("pickle/routes/welcome", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    account: Ember.inject.service('current-account'),
    actions: {
      hideLink: function hideLink() {
        this.set('controller.showOnwardLink', false);
      }
    }
  });

  _exports.default = _default;
});