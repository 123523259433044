define("pickle/templates/settings/account/profile-buttons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cylgI7fW",
    "block": "[[[46,[28,[37,1],null,null],null,null,null],[1,\"\\n\"],[10,0],[14,0,\"header-block margin-top\"],[12],[1,\"\\n    \"],[10,\"h2\"],[12],[1,\"Profile Buttons\"],[13],[1,\"\\n    \"],[10,2],[12],[1,\"Enable/disable custom buttons for contact profile accounts. \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"templates\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[33,4]],null]],null],null,[[[1,\"        \"],[1,[28,[35,5],null,[[\"profileAction\",\"account\"],[[30,1],[33,6,[\"currentAccount\"]]]]]],[1,\"\\n\"]],[1]],null],[13]],[\"profileAction\"],false,[\"component\",\"-outlet\",\"each\",\"-track-array\",\"model\",\"profile-action-box\",\"currentAccount\"]]",
    "moduleName": "pickle/templates/settings/account/profile-buttons.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});