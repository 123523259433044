define("pickle/components/people-avatars", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    people: null,
    involvementString: Ember.computed('people', function () {
      if (this.get('people.length') > 1) {
        return "".concat(this.get('people.length'), " people involved");
      } else if (this.get('people.length')) {
        return "".concat(this.get('people.length'), " person involved");
      } else {
        return '';
      }
    })
  });

  _exports.default = _default;
});