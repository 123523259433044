define("pickle/templates/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "st1RB2id",
    "block": "[[[1,\"Loading!!!!!!!!\"]],[],false,[]]",
    "moduleName": "pickle/templates/loading.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});