define("pickle/controllers/pipelines/show", ["exports", "ember-cli-group-by/macros"], function (_exports, _macros) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentAccount: Ember.inject.service('current-account'),
    opportunities: null,
    status_values: Ember.computed('currentAccount.currentAccount', function () {
      return this.get('currentAccount.currentAccount.opportunity_states');
    }),
    // groups: groupByPath('model', 'status'),
    columnGroups: Ember.computed('status_values', 'opportunities', function () {
      console.log('--- column groups ---');
      var cols = new Ember.Object(); // build template

      if (this.get('status_values.length')) {
        this.get('status_values').forEach(function (val) {
          cols[val] = Ember.A();
        }); // now, push items into each 

        this.get('opportunities').forEach(function (opportunity) {
          if (!opportunity) return;
          var status = opportunity.get('status');
          if (status === null) status = 'N/A'; // cols.get(status).pushObject(opportunity);

          if (cols[status]) cols[status].push(opportunity);
        });
        return cols;
      }
    }),
    recalculateTotals: function recalculateTotals() {
      console.log('recalculating totals...'); // this.notifyPropertyChange('columnGroups');
    },
    actions: {
      opportunityMoved: function opportunityMoved(opportunityId, newStatus) {
        var _this = this;

        this.set('isSaving', true);
        var opportunity = this.store.peekRecord('opportunity', opportunityId);
        var oldStatus = opportunity.get('status');
        console.log("Updating status from ".concat(oldStatus, " to ").concat(newStatus, " for opportunity with id: ").concat(opportunityId));
        opportunity.set('status', newStatus);
        opportunity.save().then(function (opp) {
          _this.recalculateTotals();

          _this.set('isSaving', false); // move into the new column
          // this.get('columnGroups').get(oldStatus).removeObject(opportunity);
          // this.get('columnGroups').get(newStatus).addObject(opportunity);

        });
      }
    }
  });

  _exports.default = _default;
});