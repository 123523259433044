define("pickle/helpers/format-currency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatCurrency = formatCurrency;

  function formatCurrency(params, hash) {
    var value = params[0];
    if (!value) return '';
    var symbol = hash.symbol || '$';
    var useCommas = hash.commas;

    switch (hash.denomination) {
      case 'cents':
        value = value / 100;
        break;

      default:
        break;
    }

    if (useCommas) {
      value = numberWithCommas(value);
    }

    return "".concat(symbol).concat(value);
  }

  var numberWithCommas = function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  var _default = Ember.Helper.helper(formatCurrency);

  _exports.default = _default;
});