define("pickle/templates/components/quick-note-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qwxGnnyi",
    "block": "[[[18,1,null],[1,\"\\n\"],[11,\"button\"],[24,0,\"btn btn-default btn-sm\"],[24,\"data-toggle\",\"tooltip\"],[16,\"title\",[29,[[36,1]]]],[4,[38,2],[[30,0],\"handleOnClick\"],null],[12],[1,[34,3]],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"tooltipText\",\"action\",\"text\"]]",
    "moduleName": "pickle/templates/components/quick-note-button.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});