define("pickle/adapters/application", ["exports", "ember-data", "pickle/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _emberData.default.RESTAdapter.extend({
    host: _environment.default.apiHost,
    namespace: '',
    ajax: function ajax(url, method, hash) {
      hash = hash || {};
      hash.crossDomain = true;
      hash.xhrFields = {
        withCredentials: true,
        credentials: 'include'
      };
      return this._super(url, method, hash);
    },
    // fix found here: https://github.com/emberjs/data/issues/6413#issuecomment-529276275
    ajaxOptions: function ajaxOptions() {
      return _objectSpread(_objectSpread({}, this._super.apply(this, arguments)), {}, {
        credentials: 'include'
      });
    },
    handleResponse: function handleResponse(status, headers, payload, requestData) {
      // 403s are ok, only redirect if they're trying to login or retrieve info?
      // 4030 is failed to authentiate token, aka: user token is invalid
      if (status === 403 && !payload.success && payload.code === "4030") {
        console.log(payload);
        window.location = "/login";
      }

      if (headers['x-access-token'] && headers['x-refresh-token']) {
        var ls_user = JSON.parse(window.localStorage.getItem('user'));
        ls_user.accessToken = headers['x-access-token'];
        window.localStorage.setItem('user', JSON.stringify(ls_user));
      }

      return this._super(status, headers, payload, requestData);
    },
    // taken from https://www.w3schools.com/js/js_cookies.asp
    getCookie: function getCookie(cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');

      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];

        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }

        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }

      return "";
    }
  });

  _exports.default = _default;
});