define("pickle/components/file-upload-drop/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wjlfOWG8",
    "block": "[[[10,\"form\"],[14,1,\"file_upload_form\"],[12],[1,\"\\n  \"],[10,\"input\"],[14,0,\"file-uploader\"],[14,1,\"file_upload_input\"],[14,4,\"file\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[15,0,[29,[\"file-upload-drop \",[52,[33,1],\"drag-over\"]]]],[12],[1,\"\\n\"],[41,[30,0,[\"isUploading\"]],[[[1,\"    🚀 Uploading...\\n\"]],[]],[[[41,[30,0,[\"recentlyUploadedFile\"]],[[[1,\"      Uploaded! 🎉\\n\"]],[]],[[[1,\"      \"],[10,0],[14,0,\"placeholder\"],[12],[1,\"Drop files here to upload\"],[13],[1,\"\\n\"]],[]]]],[]]],[13],[1,\"\\n\"]],[],false,[\"if\",\"isOver\"]]",
    "moduleName": "pickle/components/file-upload-drop/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});