define("pickle/templates/notes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iOjyustj",
    "block": "[[[10,0],[14,0,\"flex w-screen p-3\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex flex-grow-0 mr-5 w-max sm:mr-2 sm:w-1/5\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"sticky flex h-screen overflow-y-scroll notes-list\"],[14,5,\"top:55px;\"],[12],[1,\"\\n\"],[41,[33,1],[[[42,[28,[37,3],[[28,[37,3],[[33,1]],null]],null],null,[[[1,\"        \"],[10,0],[14,0,\"\"],[12],[1,\"\\n        \"],[8,[39,4],null,[[\"@route\",\"@model\"],[\"notes.show\",[30,1]]],[[\"default\"],[[[[1,\"\\n          \"],[8,[39,5],null,[[\"@note\"],[[30,1]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[1]],null]],[]],[[[1,\"      There are no notes.\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"sticky flex-grow w-2/3 p-2 overflow-y-scroll border rounded-lg shadow-md sm:w-4/5 dark:border-smoke-100 dark:bg-smoke-100\"],[14,5,\"top: 55px; height: calc(100vh - 55px)\"],[12],[1,\"\\n    \"],[46,[28,[37,7],null,null],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"note\"],false,[\"if\",\"sortedModelByDate\",\"each\",\"-track-array\",\"link-to\",\"note-list-item\",\"component\",\"-outlet\"]]",
    "moduleName": "pickle/templates/notes.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});