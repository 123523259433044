define("pickle/controllers/signup-invitation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import SignupMixin from 'pickle/mixins/signup';
  var _default = Ember.Controller.extend({
    queryParams: ['redirect_to', 'token', 'invitationToken']
  });

  _exports.default = _default;
});