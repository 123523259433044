define("pickle/templates/components/autofocus-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x75Isuhx",
    "block": "[[[18,1,null],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "pickle/templates/components/autofocus-input.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});