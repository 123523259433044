define("pickle/controllers/lists/catchups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    rowDataElementKeys: ['email_hash', 'nickname', 'last_catchup_at'],
    // within the next week
    upcomingCatchupContacts: Ember.computed('upcomingCatchups', function () {
      return this.get('upcomingCatchups').map(function (catchup) {
        return catchup.get("contact");
      });
    }),
    futureCatchupContacts: Ember.computed('futureCatchups', function () {
      return this.get('futureCatchups').map(function (catchup) {
        return catchup.get("contact");
      });
    }),
    // within the next 2 weeks
    upcomingCatchups: Ember.computed.filter('model', function (catchup) {
      var oneWeekAgo = new Date();
      oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
      var oneDayAgo = new Date();
      oneDayAgo.setMinutes(oneDayAgo.getMinutes() - 1);
      var last_at = catchup.get('last_catchup_at');

      if (last_at > oneDayAgo && last_at < oneWeekAgo || Ember.isNone(catchup.get('last_catchup_at'))) {
        console.log('catchup', catchup.get('contact.first_name'));
        return true;
      }
    }),
    futureCatchups: Ember.computed.filter('model', function (catchup) {
      var twoWeeksAgo = new Date();
      twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
      console.log(twoWeeksAgo);
      var last_at = catchup.get('last_catchup_at');

      if (last_at < twoWeeksAgo && !Ember.isNone(last_at)) {
        return true;
      }
    }),
    // within 3 days
    soonCatchups: Ember.computed.filter('model', function (catchup) {
      var threeDaysAgo = new Date();
      threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);
      var last_at = catchup.get('last_catchup_at');

      if (last_at < threeDaysAgo && !Ember.isNone(last_at)) {
        return true;
      }
    }),
    actions: {
      notificationToggle: function notificationToggle(catchup) {
        console.log('toggled', catchup);
        catchup.toggleProperty('notifications_on', true);
      }
    }
  });

  _exports.default = _default;
});