define("pickle/helpers/replace-tokens-with-object-attributes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.replaceTokensWithObjectAttributes = replaceTokensWithObjectAttributes;

  function replaceTokensWithObjectAttributes(params
  /*, hash*/
  ) {
    var text = params[0];
    var obj = params[1];
    var known_tokens = ['contact_id', 'email', 'first_name', 'last_name'];
    var tokens = text.match(/({{[\w\d\s]*}})/g);
    tokens.forEach(function (token) {
      var tokenWithoutBraces = token.replace(/{/g, '');
      tokenWithoutBraces = tokenWithoutBraces.replace(/}/g, '');
      var replacement = obj.get(tokenWithoutBraces);
      text = text.replace(token, replacement);
    });
    return text;
  }

  var _default = Ember.Helper.helper(replaceTokensWithObjectAttributes);

  _exports.default = _default;
});