define("pickle/templates/components/add-todo-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IXMcpxl2",
    "block": "[[[8,[39,0],[[24,0,\"addTagField standard-input\"],[24,\"placeholder\",\"type a todo and press enter/return to save\"],[16,\"disabled\",[30,0,[\"isReadOnly\"]]],[24,4,\"text\"]],[[\"@enter\",\"@value\"],[[28,[37,1],[[30,0],[30,0,[\"addTodo\"]]],null],[30,0,[\"todo\"]]]],null],[1,\"\\n\\n\"],[18,1,null],[1,\"\\n\"]],[\"&default\"],false,[\"input\",\"action\",\"yield\"]]",
    "moduleName": "pickle/templates/components/add-todo-form.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});