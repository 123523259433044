define("pickle/controllers/settings", ["exports", "pickle/mixins/magic-email-generator"], function (_exports, _magicEmailGenerator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_magicEmailGenerator.default, {
    debugMode: Ember.inject.service(),
    account: Ember.inject.service("current-account")
  });

  _exports.default = _default;
});