define("pickle/mixins/keyboard-shortcuts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    prefixFound: false,
    init: function init() {
      this._super.apply(this, arguments); // this.listenForNavigationShortcuts();

    },
    listenForNavigationShortcuts: function listenForNavigationShortcuts() {//Ember.$('body').on('keypress', Ember.$.proxy(this.handleNavigationShortcut, this));
    },
    handleNavigationShortcut: function handleNavigationShortcut(e) {
      // navigation prefixes
      if (this.prefixFound) {
        switch (e.keyCode) {
          case 99:
            this.transitionToRoute('/contacts');
            break;

          case 111:
            this.transitionToRoute('/opportunities');
        }
      }

      if (e.keyIdentifier === 'U+0047' || e.keyCode === 103) {
        this.prefixFound = true; // listen for next key, that's where we go
      } else {
        this.prefixFound = false;
      }
    }
  });

  _exports.default = _default;
});