define("pickle/routes/notes/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      // TODO: set account id here
      var body = params.body ? window.atob(params.body) : null;
      var meta = params.meta ? window.atob(params.meta) : null;
      if (typeof meta === "string") meta = {
        pickleExtension: JSON.parse(meta)
      };
      return this.store.createRecord("note", {
        body: body,
        metadata: meta
      });
    },
    afterModel: function afterModel(note) {
      if (!note.body) {
        this.restoreFromLocalstorage(note);
      }
    },
    restoreFromLocalstorage: function restoreFromLocalstorage(note) {
      var body = window.localStorage.getItem("quickNoteBody");
      note.set("body", body);
    }
  });

  _exports.default = _default;
});