define("pickle/adapters/email-address", ["exports", "pickle/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForDeleteRecord: function urlForDeleteRecord(id, modelName, snapshot) {
      console.log(snapshot);
      return "".concat(this.buildURL(), "/contacts/").concat(snapshot.record.get('contact.id'), "/email_addresses/").concat(id);
    }
  });

  _exports.default = _default;
});