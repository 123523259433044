define("pickle/components/note-blurb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="hover:bg-purple-500 rounded hover:shadow mb-5 p-2">
      <LinkTo @route="notes.show" @model={{@note}}>
          <div class="list-item" style="list-style-type:none;">
              <div class="flex-row">
                  <span class="text-lg font-bold">{{@note.subject}}</span>
                  <div class="note-icon">
                      <span class="glyphicon {{@note.iconType}}"></span>
                  </div>
              </div>
              <p>{{{trim-text @note.body 75}}}</p>
              <div class="note-footer font-light text-smoke-300">
                  {{moment-from-now @note.updated_at}}
              </div>
          </div>
      </LinkTo>
  </div>
  */
  {
    "id": "SpSX9HKP",
    "block": "[[[10,0],[14,0,\"hover:bg-purple-500 rounded hover:shadow mb-5 p-2\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@route\",\"@model\"],[\"notes.show\",[30,1]]],[[\"default\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"list-item\"],[14,5,\"list-style-type:none;\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"flex-row\"],[12],[1,\"\\n                \"],[10,1],[14,0,\"text-lg font-bold\"],[12],[1,[30,1,[\"subject\"]]],[13],[1,\"\\n                \"],[10,0],[14,0,\"note-icon\"],[12],[1,\"\\n                    \"],[10,1],[15,0,[29,[\"glyphicon \",[30,1,[\"iconType\"]]]]],[12],[13],[1,\"\\n                \"],[13],[1,\"\\n            \"],[13],[1,\"\\n            \"],[10,2],[12],[2,[28,[37,1],[[30,1,[\"body\"]],75],null]],[13],[1,\"\\n            \"],[10,0],[14,0,\"note-footer font-light text-smoke-300\"],[12],[1,\"\\n                \"],[1,[28,[35,2],[[30,1,[\"updated_at\"]]],null]],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"],[13]],[\"@note\"],false,[\"link-to\",\"trim-text\",\"moment-from-now\"]]",
    "moduleName": "pickle/components/note-blurb.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});