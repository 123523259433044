define("pickle/templates/account-invitations/accept", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Zj1D+Qn9",
    "block": "[[[10,0],[14,0,\"fullscreen  centered\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"fullscreen-container\"],[12],[1,\"\\n\"],[41,[51,[28,[37,1],[[33,2,[\"status\"]],\"accepted\"],null]],[[[1,\"            \"],[10,\"h1\"],[12],[1,\"🎉\"],[13],[1,\"\\n            \"],[10,\"h1\"],[12],[1,\"You've been invited!\"],[13],[1,\"\\n\"],[41,[33,2,[\"User\",\"first_name\"]],[[[1,\"                \"],[10,2],[12],[1,[33,2,[\"User\",\"first_name\"]]],[1,\" has invited you to join \"],[1,[33,2,[\"Account\",\"name\"]]],[1,\"!\"],[13],[1,\"\\n\"]],[]],[[[1,\"                \"],[10,2],[12],[1,\"You've been invited to join \"],[1,[33,2,[\"Account\",\"name\"]]],[1,\"! Once you join you'll have access to their contacts and more.\"],[13],[1,\"\\n\"]],[]]],[1,\"            \"],[11,\"button\"],[24,0,\"button button-shadow\"],[4,[38,4],[[30,0],\"accept\"],null],[12],[1,\"\\n                Click here to Accept \"],[41,[33,5],[[[10,0],[14,0,\"loading-spinner-sm\"],[12],[13]],[]],null],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],[[[1,\"            \"],[10,\"h1\"],[12],[1,\"👍\"],[13],[1,\"\\n            \"],[10,\"h1\"],[12],[1,\"You're in!\"],[13],[1,\"\\n            \"],[10,2],[12],[1,\"Awesome, you're now a part of \"],[10,\"strong\"],[12],[1,[33,2,[\"Account\",\"name\"]]],[13],[1,\". \"],[10,3],[14,6,\"/\"],[12],[1,\"Click here\"],[13],[1,\" to go back to your account.\"],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"],[13]],[],false,[\"unless\",\"eq\",\"model\",\"if\",\"action\",\"isAccepting\"]]",
    "moduleName": "pickle/templates/account-invitations/accept.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});