define("pickle/models/message", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    group: _emberData.default.belongsTo('group'),
    user: _emberData.default.belongsTo('user'),
    contact: _emberData.default.belongsTo('contact'),
    message: _emberData.default.attr('string'),
    sent_via: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    sent_to: _emberData.default.attr('string'),
    collection: _emberData.default.attr('string'),
    created_at: _emberData.default.attr('date')
  });

  _exports.default = _default;
});