define("pickle/controllers/cases/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    showAddPersonForm: false,
    currentAccount: Ember.inject.service(),
    actions: {
      showAddPersonForm: function showAddPersonForm() {
        this.set('showAddPersonForm', true);
      },
      hideAddPersonForm: function hideAddPersonForm() {
        this.set('showAddPersonForm', false);
      },
      addContact: function addContact(contact) {
        var c = this.store.peekRecord('contact', contact.id);
        this.get('model.contacts').addObject(c);
        this.model.save();
      },
      removeContact: function removeContact(contact) {
        this.model.get('contacts').removeObject(contact);
        this.model.save();
      },
      fileUploaded: function fileUploaded() {
        this.store.query('file', {
          account_id: this.get('currentAccount.currentAccount.id'),
          opportunity_id: this.model.get('id')
        });
      }
    }
  });

  _exports.default = _default;
});