define("pickle/serializers/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      featureFlags: {
        embedded: 'always'
      },
      isOnboarding: 'is_onboarding' // inboundEmailAddresses: 'inbound_email_addresses'
      // inboundEmailAddresses: { key: 'inbound_email_addresses', embedded: 'always'}

    }
  });

  _exports.default = _default;
});