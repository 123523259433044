define("pickle/components/billing-plan-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selectedPlan: null,
    actions: {
      selectPlan: function selectPlan(plan) {
        this.set('selectedPlan', plan);
        this.sendAction('selectPlan', plan);
      }
    }
  });

  _exports.default = _default;
});