define("pickle/routes/timelines/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentAccount: Ember.inject.service(),
    model: function model(params) {
      var account_id = null;

      if (Ember.isPresent(params.account_id)) {
        account_id = params.account_id;
      } else if (Ember.isPresent(window.localStorage.getItem('currentAccountId'))) {
        account_id = window.localStorage.getItem('currentAccountId');
      } else {
        account_id = this.get('currentUser.default_account_id');
      }

      return this.store.query('opportunity', {
        type: 'timeline',
        account_id: account_id
      });
    },
    actions: {
      accountChanged: function accountChanged(newAccountId) {
        // update query param
        this.set('controller.account_id', newAccountId);
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});