define("pickle/helpers/json-stringify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.jsonStringify = jsonStringify;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function jsonStringify(params
  /*, hash*/
  ) {
    if (_typeof(params[0]) === 'object') {
      return JSON.stringify(params[0], null, 4);
    } else {
      return params[0];
    }
  }

  var _default = Ember.Helper.helper(jsonStringify);

  _exports.default = _default;
});