define("pickle/components/people-involved", ["exports", "ember-group-by"], function (_exports, _emberGroupBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    contacts: null,
    groupedContactsByRole: (0, _emberGroupBy.default)('contacts', 'opportunity_contacts.role'),
    showAddPersonForm: false,
    hideAddAndDonebuttons: false,
    actions: {
      showAddPersonForm: function showAddPersonForm() {
        this.set('showAddPersonForm', true);
        this.sendAction('showForm');
      },
      hideAddPersonForm: function hideAddPersonForm() {
        this.set('showAddPersonForm', false);
        this.sendAction('hideForm');
      },
      removeContact: function removeContact(contact) {
        this.sendAction('removeContact', contact);
      }
    }
  });

  _exports.default = _default;
});