define("pickle/controllers/groups/show/message/new", ["exports", "pickle/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notificationCenter: Ember.inject.service(),
    selectedContacts: [],
    okButtonEnabled: 'disabled',
    messageContent: null,
    sendVia: 'sms',
    actions: {
      messageWasSent: function messageWasSent() {
        this.get('notificationCenter').set('reloadGroup', new Date());
      },
      messageUpdated: function messageUpdated(message) {
        this.set('messageContent', message);
      },
      sendViaChanged: function sendViaChanged(via) {
        this.set('sendVia', via);
      },
      contactSelected: function contactSelected(contacts) {
        this.set('selectedContacts', contacts);

        if (this.get('selectedContacts.length') === 0) {
          this.set('okButtonEnabled', 'disabled');
        } else {
          this.set('okButtonEnabled', null);
        }
      },
      'send message': function sendMessage() {
        if (this.get('selectedContacts.length') === 0) {
          window.alert('Please select a contact to send this message to :)');
        }
      },
      navigateBack: function navigateBack() {
        this.transitionToRoute('groups.show', this.get('model.id'));
      },
      sendNotification: function sendNotification() {
        var _this = this;

        this.set('isNotifying', true); // build contacts

        var tmpArray = [];
        var sendVia = this.get('sendVia'); // tmp array is used so that we include the contact.id

        this.get('selectedContacts').forEach(function (contact) {
          tmpArray.push({
            name: "".concat(contact.get('first_name'), " ").concat(contact.get('last_name')),
            send_via: sendVia,
            email: contact.get('email'),
            number: contact.get('phone_number') || contact.get('jsonb_data.preferred_contact_value'),
            id: contact.get('id')
          });
        });
        var token = localStorage.getItem('userAccessToken');
        Ember.$.ajax({
          url: "".concat(_environment.default.apiHost, "/groups/").concat(this.get('model.id'), "/notify_all"),
          type: "POST",
          headers: {
            'x-access-token': token,
            "Content-Type": "application/json; charset=utf-8"
          },
          contentType: "application/json",
          data: JSON.stringify({
            "message": {
              "subject": this.get('messageSubject') || "Message from ".concat(this.get('currentUser.first_name'), " ").concat(this.get('currentUser.last_name')),
              // "from": `${this.get('model.name')}<noreply@lol.me>`,
              "body": "".concat(this.get('messageContent')),
              "message": "".concat(this.get('messageContent')),
              "send_via": sendVia
            },
            "contacts": tmpArray
          })
        }).done(function (data
        /*textStatus, jqXHR*/
        ) {
          _this.set('isNotifying', false);

          _this.set('messageContent', null);

          _this.store.pushPayload('message', data); // no need for 'message' because it's in the correct format


          _this.model.reload();

          _this.send('removeModal');

          Ember.$('.modal').modal('hide');

          _this.send('messageWasSent');

          _this.transitionToRoute('groups.show', _this.get('model.id'));
        }).fail(function () {
          _this.set('isNotifying', false);
        }).always(function () {
          /* ... */
          _this.set('isNotifying', false);
        });
      }
    }
  });

  _exports.default = _default;
});