define("pickle/templates/signup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "myDHOb05",
    "block": "[[[10,0],[14,0,\"flex self-end flex-grow justify-items-end\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex self-end justify-end flex-grow \"],[12],[1,\"\\n    \"],[8,[39,0],[[24,0,\"flex self-end p-2 pl-3 pr-3 m-2 font-bold text-purple-700 border-2 border-purple-500 rounded-full hover:bg-purple-700 hover:text-white border-3\"]],[[\"@route\",\"@query\"],[\"login\",[28,[37,1],null,[[\"redirect_to\"],[[30,0,[\"redirect_to\"]]]]]]],[[\"default\"],[[[[1,\"Sign In →\"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,1,\"signup\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"mt-5 dark:text-smoke-800\"],[12],[1,\"\\n    \"],[10,\"h1\"],[14,0,\"dark:text-smoke-800\"],[12],[1,\"👋️ Meet Pickle: A simple way to remember information about people.\"],[13],[1,\"\\n    \"],[10,\"h2\"],[14,0,\"dark:text-smoke-700\"],[12],[1,\"Keep in touch and stay in touch with the people you meet.\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"flex flex-row\"],[12],[1,\"\\n    \"],[8,[39,2],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[1,\"\\n  \"],[10,0],[14,0,\"mt-5\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"text-gray-300 navigation\"],[12],[1,\"\\n      This is a \"],[10,3],[14,6,\"http://toyroomventures.com\"],[12],[1,\"Toy Room Venture\"],[13],[1,\".\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"link-to\",\"hash\",\"signup-form\"]]",
    "moduleName": "pickle/templates/signup.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});