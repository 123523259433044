define("pickle/routes/cases/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord('opportunity', params.opportunity_id);
    },
    afterModel: function afterModel(model) {
      this.store.query('file', {
        opportunity_id: model.get('id'),
        // opportunities and cases are the same object
        account_id: model.get('account.id')
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model); // for default behavior


      this.get('controller').set('possibleContacts', this.store.findAll('contact'));
      controller.set('dropbox_path', "/better-crm/cases/" + model.get('id'));
    },
    actions: {
      accountChanged: function accountChanged() {
        this.transitionTo('cases.index');
      },
      showAddPersonForm: function showAddPersonForm() {
        this.controller.set('showAddPersonForm', true);
        this.send('setPerson'); // set default person when add person form is called
      },
      setPerson: function setPerson() {
        var selectElement = Ember.$('#set-person-select')[0];
        var selectedIndex = selectElement ? selectElement.selectedIndex : 0; //const options = Ember.$('#set-person-select option');
        //const selectedValue = options[selectedIndex].value;

        this.set('personToAdd', this.get('controller.possibleContacts').objectAtContent(selectedIndex));
      },
      addPerson: function addPerson() {
        var personToAdd = this.get('personToAdd');
        this.get('controller.model.Contacts').addObject(personToAdd);
        this.get('controller.model').save();
      }
    }
  });

  _exports.default = _default;
});