define("pickle/mixins/contacts-sort-order", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    currentFilter: null,
    loadFilterTypeFromLocalStorage: function loadFilterTypeFromLocalStorage() {
      var filter = window.localStorage.getItem('contactsSortOrder');
      this.send('changeFilter', filter);
    },
    fetchFilteredContacts: function fetchFilteredContacts(filter) {
      var _this = this;

      this.toggleProperty('filterIsLoading');
      var currentAccountId = this.get('account_id') || this.get('account.currentAccount.id');
      this.store.query('contact', {
        account_id: currentAccountId,
        'order_by': filter['order_by'],
        size: this.get('size'),
        page: this.get('page') - 1
      }).then(function (contacts) {
        _this.set('model', contacts);

        _this.toggleProperty('filterIsLoading');
      });
    },

    /**
      Returns the filter 
      @param {string} filter - The name of the filter (example: newest | oldest | created_newest | created_oldest)
      Example: returns {'order_by': 'created_at}
    */
    getOrderBy: function getOrderBy(filter) {
      var response = null;

      switch (filter) {
        case 'newest':
          response = {
            'order_by': 'updated_at DESC'
          };
          this.set('sortDefinition', ['isStarred:desc', 'updated_at:desc']);
          break;

        case 'oldest':
          response = {
            'order_by': 'updated_at ASC'
          };
          this.set('sortDefinition', ['isStarred:desc', 'updated_at:asc']); // starred should always be important

          break;

        case 'created_newest':
          response = {
            'order_by': 'created_at DESC'
          };
          this.set('sortDefinition', ['isStarred:desc', 'created_at:desc']);
          break;

        case 'created_oldest':
          response = {
            'order_by': 'created_at ASC'
          };
          this.set('sortDefinition', ['isStarred:desc', 'created_at:asc']); // starred should always be important

          break;

        default:
          response = {
            'order_by': 'updated_at DESC'
          };
          this.set('sortDefinition', ['isStarred:desc', 'updated_at:desc']);
          break;
      }

      return response;
    },
    getFilterBy: function getFilterBy(filter) {
      var element = document.getElementById('contactFilterBySelect');

      if (element) {
        var filterBy = filter || element.val();
        return filterBy;
      } else {
        return '';
      }
    },
    actions: {
      changeFilter: function changeFilter(filter) {
        var filterBy = this.getFilterBy(filter);
        this.set('order', filterBy);
        window.localStorage.setItem('contactsSortOrder', filterBy);
        this.set('currentFilter', filterBy);
        this.fetchFilteredContacts(this.getOrderBy(filterBy));
      }
    }
  });

  _exports.default = _default;
});