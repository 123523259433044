define("pickle/helpers/comma-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.commaList = commaList;
  _exports.default = void 0;

  function commaList(params, hash) {
    var key = hash.key;
    var string = '';
    var elements = params[0];

    if (elements) {
      for (var i = 0; i < elements.length; i++) {
        var item = elements[i][key];
        string += "".concat(item);
        if (i + 1 !== elements.length) string += ', ';
      }
    }

    return string;
  }

  var _default = Ember.Helper.helper(commaList);

  _exports.default = _default;
});