define("pickle/templates/notes/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WbXVCJX8",
    "block": "[[[8,[39,0],null,[[\"@note\",\"@saveButtonText\",\"@afterSaveAction\"],[[30,0,[\"model\"]],\"Update Note\",[28,[37,1],[[30,0,[\"afterSave\"]]],null]]],null],[1,\"\\n\\n\"],[46,[28,[37,3],null,null],null,null,null],[1,\"\\n\"]],[],false,[\"note-form\",\"fn\",\"component\",\"-outlet\"]]",
    "moduleName": "pickle/templates/notes/edit.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});