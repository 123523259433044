define("pickle/components/table-cell-for-column", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    values: null,
    column: null,
    store: Ember.inject.service(),
    row_uuid: Ember.computed('values', function () {
      return this.get('values.firstObject.row_uuid');
    }),
    value: Ember.computed('values', function () {
      var column_id = parseInt(this.get('column.id'));
      var v = this.get('values').findBy('table_column_id', column_id);

      if (v === undefined) {
        v = this.get('store').createRecord('table-column-value', {
          table_column: this.get('column'),
          row_uuid: this.get('row_uuid')
        });
      }

      return v;
    })
  });

  _exports.default = _default;
});