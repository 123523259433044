define("pickle/models/todo", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    description: _emberData.default.attr(),
    is_completed: _emberData.default.attr('boolean'),
    entity_id: _emberData.default.attr(),
    entity_type: _emberData.default.attr(),
    account_id: _emberData.default.attr(),
    // belongs to relationsihps
    contact: _emberData.default.belongsTo('contact', {
      polymorphic: true
    })
  });

  _exports.default = _default;
});