define("pickle/templates/components/table-header-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EfvHMo6G",
    "block": "[[[11,3],[4,[38,0],[[30,0],[33,1],[33,2]],null],[12],[1,[33,2,[\"name\"]]],[13],[1,\"\\n\"],[11,\"i\"],[24,0,\"glyphicon glyphicon-chevron-down\"],[4,[38,0],[[30,0],\"toggleColumnTypeSelector\",[33,2]],null],[12],[13],[1,\"\\n\"],[41,[33,4],[[[1,\"    \"],[10,0],[14,0,\"contacts-search-results\"],[12],[1,\"\\n        \"],[10,\"h5\"],[12],[1,\"Select a column type\"],[13],[1,\"\\n        \"],[10,\"ul\"],[14,0,\"no-bullets\"],[12],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[33,7]],null]],null],null,[[[1,\"            \"],[10,\"li\"],[15,\"onclick\",[28,[37,0],[[30,0],\"selectColumnType\",[30,1],[33,2]],null]],[12],[1,[30,1,[\"displayName\"]]],[13],[1,\"\\n\"]],[1]],null],[1,\"        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[18,2,null]],[\"type\",\"&default\"],false,[\"action\",\"sortByColumnAction\",\"column\",\"if\",\"showColumnTypeSelector\",\"each\",\"-track-array\",\"columnTypes\",\"yield\"]]",
    "moduleName": "pickle/templates/components/table-header-cell.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});