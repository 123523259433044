define("pickle/helpers/trim-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.trimText = trimText;

  function trimText(params
  /*, hash*/
  ) {
    var length = params[1] || 200;

    if (Ember.isEmpty(params[0])) {
      return '';
    }

    if (params[0].length > length) {
      return params[0].substring(0, length) + "...";
    } else {
      return params[0].substring(0, length);
    }
  }

  var _default = Ember.Helper.helper(trimText);

  _exports.default = _default;
});