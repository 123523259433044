define("pickle/controllers/search/fullscreen", ["exports", "pickle/mixins/search-base", "pickle/mixins/checked-contacts-for-group", "pickle/mixins/paid-plan-modal", "pickle/config/environment"], function (_exports, _searchBase, _checkedContactsForGroup, _paidPlanModal, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_searchBase.default, _checkedContactsForGroup.default, _paidPlanModal.default, {
    queryParams: ['term', 'quantity', 'first_name', 'last_name', 'email', 'phone_number', 'background_info', 'custom_fields_query', 'encQueryObjects', 'searchHash'],
    term: null,
    isFullscreen: true,
    showSettings: false,
    showFilters: false,
    showAddFilterForm: true,
    columns: [{
      name: 'first_name',
      displayName: 'First Name',
      enabled: true
    }, {
      name: 'last_name',
      displayName: 'Last Name',
      enabled: true
    }, {
      name: 'email',
      displayName: 'Email Address',
      enabled: true
    }, {
      name: 'phone_number',
      displayName: 'Phone Number',
      enabled: true
    }, {
      name: 'background_info',
      displayName: 'Background Info',
      enabled: false
    } // {
    //     name: 'tags',
    //     displayName: 'Tags',
    //     enabled: false
    // },
    ],
    modifiers: [{
      name: 'contains',
      displayName: 'contains'
    }, {
      name: 'equals',
      displayName: 'equals'
    }, {
      name: 'greater-than',
      displayName: 'greater than'
    }, {
      name: 'less-than',
      displayName: 'less than'
    }, {
      name: 'not-equals',
      displayName: 'does not equal'
    }, {
      name: 'is-blank',
      displayName: 'is blank'
    }, {
      name: 'is-not-blank',
      displayName: 'is not blank'
    }, {
      name: 'is-null',
      displayName: 'is null'
    }, {
      name: 'is-not-null',
      displayName: 'is not null'
    }],
    showDownloadCSVModalPaid: false,
    uniqueColumns: Ember.computed('columns', 'customFields', function () {
      var _this = this;

      var unique = this.get('columns').uniqBy('displayName');
      unique = this.restoreEnabledColumnsFromLocalstorage(unique); // run only after render

      Ember.run.schedule('afterRender', function () {
        _this.restore();

        _this.doSearchForDebounceV2();
      });
      return unique;
    }),
    quantityChange: Ember.observer('quantity', function () {
      Ember.run.debounce(this, this.doSearchForDebounceV2, 300);
    }),
    restoreEnabledColumnsFromLocalstorage: function restoreEnabledColumnsFromLocalstorage(columns) {
      if (window.localStorage) {
        var items = localStorage.getItem('fullscreenEnabledColumns');

        if (items) {
          items = items.split(',');
          columns.map(function (column) {
            if (items.indexOf(column.name) > -1) {
              Ember.set(column, 'enabled', true);
            } else {
              Ember.set(column, 'enabled', false);
            }

            return column;
          });
        }

        return columns;
      }
    },
    customFieldsObserver: Ember.observer('customFields', function () {
      var _this2 = this;

      var custom_fields = this.get('customFields');
      custom_fields.map(function (field) {
        _this2.get('columns').pushObject({
          name: field.get('name'),
          displayName: field.get('name'),
          enabled: false,
          isCustomField: true,
          object: field
        });
      });
    }),
    actions: {
      goToBilling: function goToBilling() {
        Ember.$('.modal').modal('hide');
        this.transitionToRoute('billing');
      },
      checkedColumn: function checkedColumn(a, b) {
        Ember.set(a, 'enabled', b.target.checked);
        localStorage.setItem('fullscreenEnabledColumns', this.get('columns').filterBy('enabled', true).mapBy('name').toArray());
      },
      downloadCSV: function downloadCSV() {
        if (this.requiresPaidPlan()) {
          // in the future, this should be a decorator...
          var url = "".concat(_environment.default.apiHost, "/search/csv2");
          var base64Encoded = window.btoa(JSON.stringify(this.get("queryObjects")));
          this.set('encQueryObjects', base64Encoded);
          var searchParams = this.get('searchParams');

          if (Ember.isNone(searchParams)) {
            window.alert('Perform a search before downloading');
            return false;
          }

          var csv_fields = []; // for each field turned on, create a csv_fields item like 'jsonb_data.Field Name'

          var enabled_columns = this.get('columns').filterBy('enabled', true);
          csv_fields = enabled_columns.mapBy('name');
          searchParams['csv_fields'] = csv_fields;
          searchParams['account_id'] = this.get('currentAccount.currentAccount.id');
          searchParams['token'] = localStorage.getItem('userAccessToken');
          searchParams['encSearch'] = this.get('searchHash');
          searchParams['quantity'] = this.get('quantity'); // prepend jsonb_data to all columns that aren't default or token
          // first_name = first_name; rsvp? = jsonb_data.rsvp?

          searchParams['csv_fields'] = searchParams['csv_fields'].map(function (field) {
            if (['first_name', 'last_name', 'phone_number', 'email', 'description', 'tags', 'token'].indexOf(field) > -1) {
              return field;
            } else {
              return "jsonb_data.".concat(field);
            }
          });
          var queryParams = Ember.$.param(searchParams);
          url = "".concat(url, "/?").concat(queryParams);
          window.open(url, '_blank');
        } else {
          this.set('showDownloadCSVModalPaid', true);
        }
      },
      applyFilter: function applyFilter(columnName, modifierName, value) {
        this.updateQueryObjects({
          columnName: columnName,
          modifier: modifierName,
          inputValue: value
        });
        this.doSearchForDebounceV2();
        this.set('addAnotherSearchFilter', false);
      },
      updateFilter: function updateFilter(columnName, modifierName, value) {
        this.updateQueryObjects({
          columnName: columnName,
          modifier: modifierName,
          inputValue: value,
          updateOnly: true
        });
        this.send('performSearch');
        this.set('addAnotherSearchFilter', false);
      },
      // override to use v2 search
      performSearch: function performSearch() {
        Ember.run.debounce(this, this.doSearchForDebounceV2, 300);
      },
      cancelAddingAnotherSearchFilter: function cancelAddingAnotherSearchFilter() {
        this.set('addAnotherSearchFilter', false);
      }
    }
  });

  _exports.default = _default;
});