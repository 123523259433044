define("pickle/models/timeline", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    description: _emberData.default.attr(),
    account: _emberData.default.belongsTo('account', {
      async: true
    }),
    contacts: _emberData.default.hasMany('contact'),
    Notes: _emberData.default.hasMany('note', {
      inverse: 'opportunity'
    }),
    files: _emberData.default.hasMany('file'),
    timelineSteps: _emberData.default.hasMany('timeline-step'),
    timelineMessages: _emberData.default.hasMany('timeline-message')
  });

  _exports.default = _default;
});