define("pickle/models/date", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    // contact_id: validator('presence', true),
    name: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 5
    })],
    start_date: (0, _emberCpValidations.validator)('presence', true)
  });

  var _default = _emberData.default.Model.extend(Validations, {
    user: _emberData.default.belongsTo('user'),
    account: _emberData.default.belongsTo('account'),
    account_id: _emberData.default.attr(),
    contacts: _emberData.default.hasMany('contact'),
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    start_date: _emberData.default.attr('date'),
    end_date: _emberData.default.attr('date'),
    event_type: _emberData.default.attr('string'),
    start_date_without_time: Ember.computed('start_date', function () {
      if (this.get('start_date')) {
        return this.get('start_date').toDateString();
      } else {
        return null;
      }
    }),
    start_time: Ember.computed('start_date', function () {
      // return this.get('start_date').toTimeString();
      if (this.get('start_date')) {
        return this.get('start_date').toLocaleTimeString();
      } else {
        return null;
      }
    }),
    end_date_without_time: Ember.computed('end_date', function () {
      if (this.get('end_date')) {
        return this.get('end_date').toDateString();
      } else {
        return null;
      }
    }),
    end_time: Ember.computed('end_date', function () {
      if (this.get('end_date')) {
        return this.get('end_date').toLocaleTimeString();
      } else {
        return null;
      }
    })
  });

  _exports.default = _default;
});