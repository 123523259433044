define("pickle/templates/tables/_form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YMDg60zy",
    "block": "[[[10,0],[14,0,\"col-xs-12 opportunity-form\"],[12],[1,\"\\n\"],[41,[33,1],[[[1,\"    \"],[10,0],[14,0,\"header-block\"],[12],[1,\"\\n      \"],[10,\"h1\"],[12],[1,\"Add a Table\"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[11,\"form\"],[4,[38,2],[[30,0],\"save\"],[[\"on\"],[\"submit\"]]],[12],[1,\"\\n    \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n      \"],[10,\"label\"],[14,\"for\",\"exampleInputEmail1\"],[12],[1,\"Name: \"],[13],[1,\"\\n      \"],[1,[28,[35,3],null,[[\"value\",\"class\",\"placeholder\",\"autofocus\"],[[33,4,[\"name\"]],\"form-control name\",\"Table name\",\"autofocus\"]]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n      \"],[10,\"label\"],[14,\"for\",\"exampleinputemail1\"],[12],[1,\"Description: \"],[13],[1,\"\\n      \"],[1,[28,[35,5],null,[[\"value\",\"class\",\"placeholder\"],[[33,4,[\"description\"]],\"form-control\",\"what is this opportunity about?\"]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"form-actions\"],[12],[1,\"\\n      \"],[10,\"button\"],[14,0,\"button-shadow green\"],[14,4,\"submit\"],[12],[1,\"Save\"],[13],[1,\"\\n      \"],[41,[33,4,[\"id\"]],[[[10,0],[14,0,\"cancel\"],[12],[1,\" or \"],[11,3],[24,6,\"#\"],[4,[38,2],[[30,0],\"cancelEdit\",[33,4],\"tables.show\"],null],[12],[1,\"cancel\"],[13],[13]],[]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"isNew\",\"action\",\"input\",\"model\",\"textarea\"]]",
    "moduleName": "pickle/templates/tables/_form.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});