define("pickle/templates/opportunities/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eYlW4fu8",
    "block": "[[[10,0],[14,0,\"flex-column left-margin main container\"],[12],[1,\"\\n  \"],[46,[28,[37,1],null,null],null,null,null],[1,\"\\n  \"],[19,\"opportunities/_form\",[]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],true,[\"component\",\"-outlet\",\"partial\"]]",
    "moduleName": "pickle/templates/opportunities/new.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});