define("pickle/routes/admin/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    customFieldList: [{
      'name': 'test'
    }],
    account: Ember.inject.service('current-account'),
    model: function model() {
      var account_id = this.get('account.currentAccount.id') || window.localStorage.getItem('currentAccountId');
      return this.store.query('custom_field', {
        account_id: account_id
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('customFieldList', model.toArray());
      controller.set('account', this.get('account'));
    },
    actions: {
      accountChanged: function accountChanged() {
        this.refresh(); // this.store.query('opportunity', {
        //   account_id: this.get('account.currentAccount.id'),
        //   type: 'case'
        // }).then(opportunities => {
        //   this.set('controller.opportunities', opportunities);
        //   this.set('controller.model', opportunities);
        // });
      }
    }
  });

  _exports.default = _default;
});