define("pickle/helpers/aggregate-sum", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.aggregateSum = aggregateSum;
  _exports.default = void 0;

  function aggregateSum(params, hash) {
    if (hash.items) {
      var arr = hash.items.toArray();
      var objName = hash.obj;
      var total = 0.00;
      arr.map(function (opp) {
        total += parseFloat(opp.get(objName));
      });
      return total;
    } else {
      return '';
    }
  }

  var _default = Ember.Helper.helper(aggregateSum);

  _exports.default = _default;
});