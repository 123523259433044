define("pickle/components/add-search-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    fields: null,
    modifiers: null,
    value: null,
    column: null,
    selectedModifier: 'equals',
    // first
    selectedColumnName: 'first_name',
    // first
    saved: false,
    observeChanges: Ember.observer('selectedColumnName', 'selectedModifier', 'value', function () {
      if (this.get('saved') && this.get('changedAction')) {
        this.sendAction('changedAction', this.get('selectedColumnName'), this.get('selectedModifier'), this.get('value'));
      }
    }),
    actions: {
      apply: function apply() {
        this.sendAction('applyFilterAction', this.get('selectedColumnName'), this.get('selectedModifier'), this.get('value'));
      },
      cancel: function cancel() {
        this.sendAction('cancelAction');
        this.reset();
      },
      remove: function remove() {
        this.sendAction('removeAction');
      }
    },
    reset: function reset() {
      this.set('value', null);
      this.set('selectedModifier', 'equals');
      this.set('selectedColumnName', 'first_name');
    }
  });

  _exports.default = _default;
});