define("pickle/routes/lists/catchups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import SearchBaseMixin from 'pickle/mixins/search-base';
  // TODO: Need to get it to get the account_id when first loading.
  var _default = Ember.Route.extend({
    model: function model() {
      return this.store.findAll('catchup');
    }
  });

  _exports.default = _default;
});