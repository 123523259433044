define("pickle/controllers/admin/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    account: Ember.inject.service('current-account'),
    currentUserService: Ember.inject.service('current-user'),
    customFieldList: null,
    limitReached: Ember.computed('currentUserService.currentUserObject.hasCurrentBillingPlan', 'customFieldList.length', function () {
      if (this.get('currentUserService.currentUserObject.hasCurrentBillingPlan')) {
        return false;
      } else if (this.get("customFieldList.length") >= 3) {
        return true;
      }
    }),
    actions: {
      addField: function addField() {
        var newCustomField = this.store.createRecord('custom_field', {
          name: 'new custom field',
          account: this.get('account.currentAccount')
        });
        this.get('customFieldList').pushObject(newCustomField);
      },
      removeField: function removeField(element) {
        var _this = this;

        var flashMessages = Ember.get(this, 'flashMessages');
        var confirm = window.confirm("Are you sure you want to delete ".concat(element.get('name'), "?"));

        if (confirm) {
          element.destroyRecord().then(function () {
            _this.get('customFieldList').removeObject(element);

            flashMessages.success('Custom field has been removed!');
          });
        }
      },
      saveCustomFields: function saveCustomFields() {
        var flashMessages = Ember.get(this, 'flashMessages');
        this.get('customFieldList').filterBy('hasDirtyAttributes', true).forEach(function (item) {
          item.save().then(function () {
            flashMessages.success('Saved!');
          }).catch(function () {});
        });
      }
    }
  });

  _exports.default = _default;
});