define("pickle/serializers/file", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      User: 'user_id',
      contact: 'contact_id',
      opportunity: 'opportunity_id',
      note: 'note_id'
    }
  });

  _exports.default = _default;
});