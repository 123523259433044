define("pickle/routes/groups/new", ["exports", "pickle/mixins/form-cancel-edit"], function (_exports, _formCancelEdit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_formCancelEdit.default, {
    account: Ember.inject.service('current-account'),
    model: function model() {
      return this.store.createRecord('group');
    },
    actions: {
      save: function save() {
        var _this = this;

        this.set('controller.model.account', this.store.peekRecord('account', this.get('account.currentAccount.id')));
        this.get('controller.model').save().then(function (result) {
          _this.transitionTo('groups.show', result);
        });
      }
    }
  });

  _exports.default = _default;
});