define("pickle/helpers/query-modifier-display-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.queryModifierDisplayName = queryModifierDisplayName;

  function queryModifierDisplayName(params
  /*, hash*/
  ) {
    switch (params[0]) {
      case 'greater-than':
        return "greater than ".concat(params[1]);
        break;

      case 'less-than':
        return "less than ".concat(params[1]);
        break;

      case 'is-blank':
        return 'blank';
        break;

      case 'is-not-blank':
        return 'not blank';
        break;

      case 'not-equals':
        return "does not equal ".concat(params[1]);
        break;

      case 'equals':
        return "like ".concat(params[1]);
        break;

      default:
        return params[0];
        break;
    }
  }

  var _default = Ember.Helper.helper(queryModifierDisplayName);

  _exports.default = _default;
});