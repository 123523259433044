define("pickle/helpers/dropbox-url-from-path", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.dropboxUrlFromPath = dropboxUrlFromPath;

  function dropboxUrlFromPath(params) {
    var segments = params[0].split('/');
    var length = segments.length;
    var url = "";

    for (var i = 0; i < length; i++) {
      if (i < length - 1) {
        if (i === 0) {
          url += segments[i];
        } else {
          url += "/" + segments[i];
        }
      } else {
        url += "?preview=" + segments[i];
      }
    }

    return url;
  }

  var _default = Ember.HTMLBars.makeBoundHelper(dropboxUrlFromPath);

  _exports.default = _default;
});