define("pickle/components/overview-contacts-list-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="overview-panel">
      <h1>{{@title}} - {{@contacts.length}}</h1>
      <div class="p-3">
          {{#if this.loadingContacts}}
              loading...
          {{else}}
              {{#each @contacts as |contact index|}}
                  <SimpleContactCell @contact={{contact}} @extraClass={{if (eq (mod index 2) 0) 'bg-purple-100' 'bg-gray-100'}} />
              {{/each}}
          {{/if}}
      </div>
  </div>
  */
  {
    "id": "MAs3CooX",
    "block": "[[[10,0],[14,0,\"overview-panel\"],[12],[1,\"\\n    \"],[10,\"h1\"],[12],[1,[30,1]],[1,\" - \"],[1,[30,2,[\"length\"]]],[13],[1,\"\\n    \"],[10,0],[14,0,\"p-3\"],[12],[1,\"\\n\"],[41,[30,0,[\"loadingContacts\"]],[[[1,\"            loading...\\n\"]],[]],[[[42,[28,[37,2],[[28,[37,2],[[30,2]],null]],null],null,[[[1,\"                \"],[8,[39,3],null,[[\"@contact\",\"@extraClass\"],[[30,3],[52,[28,[37,4],[[28,[37,5],[[30,4],2],null],0],null],\"bg-purple-100\",\"bg-gray-100\"]]],null],[1,\"\\n\"]],[3,4]],null]],[]]],[1,\"    \"],[13],[1,\"\\n\"],[13]],[\"@title\",\"@contacts\",\"contact\",\"index\"],false,[\"if\",\"each\",\"-track-array\",\"simple-contact-cell\",\"eq\",\"mod\"]]",
    "moduleName": "pickle/components/overview-contacts-list-panel.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});