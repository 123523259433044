define("pickle/templates/contacts/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VhjSR9hB",
    "block": "[[[10,0],[14,0,\"flex-column left-margin main container\"],[12],[1,\"\\n\"],[41,[33,1],[[[1,\"    \"],[10,\"h1\"],[12],[1,\"Add info about yourself\"],[13],[1,\"\\n    \"],[10,2],[12],[1,\"You're creating your first contact profile and it's all about you! Enter your information below. We've already typed in your email address for you 😃\"],[13],[1,\"\\n    \"],[10,0],[14,0,\"margin-top\"],[12],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"header-block\"],[12],[1,\"\\n      \"],[10,\"h1\"],[12],[1,\"Add a contact\"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[1,\"\\n  \"],[19,\"contacts/_form\",[]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],true,[\"if\",\"onboarding\",\"partial\"]]",
    "moduleName": "pickle/templates/contacts/new.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});