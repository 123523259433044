define("pickle/helpers/is-equal-month", ["exports", "ember-date-components/helpers/is-equal-month"], function (_exports, _isEqualMonth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _isEqualMonth.default;
    }
  });
  Object.defineProperty(_exports, "isEqualMonth", {
    enumerable: true,
    get: function get() {
      return _isEqualMonth.isEqualMonth;
    }
  });
});