define("pickle/controllers/history/index", ["exports", "ember-group-by"], function (_exports, _emberGroupBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    groupedHistory: (0, _emberGroupBy.default)('model', 'created_at_without_time')
  });

  _exports.default = _default;
});