define("pickle/templates/search-results", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9lWw72Xd",
    "block": "[[[10,0],[14,0,\"\"],[12],[1,\"\\n  \"],[10,\"h1\"],[12],[1,\"Search Results\"],[13],[1,\"\\n  \"],[18,2,null],[1,\"\\n  \"],[10,\"table\"],[14,0,\"table table-hover\"],[12],[1,\"\\n    \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[33,3,[\"Contacts\"]]],null]],null],null,[[[6,[39,4],null,[[\"tagName\",\"action\",\"route\",\"model\"],[\"tr\",\"closeSearch\",\"contacts.show\",[30,1,[\"id\"]]]],[[\"default\"],[[[[1,\"          \"],[10,\"td\"],[12],[1,[30,1,[\"first_name\"]]],[13],[1,\"\\n          \"],[10,\"td\"],[12],[1,[30,1,[\"last_name\"]]],[13],[1,\"\\n          \"],[10,\"td\"],[12],[1,[30,1,[\"email\"]]],[13],[1,\"\\n\"]],[]]]]]],[1]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"contact\",\"&default\"],false,[\"yield\",\"each\",\"-track-array\",\"model\",\"link-to\"]]",
    "moduleName": "pickle/templates/search-results.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});