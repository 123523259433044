define("pickle/controllers/companies/index", ["exports", "pickle/mixins/filterable-objects", "pickle/mixins/model-pagination"], function (_exports, _filterableObjects, _modelPagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_filterableObjects.default, _modelPagination.default, {
    account: Ember.inject.service('current-account'),
    sortedCompanies: Ember.computed.sort('model', 'sortDefinition'),
    sortDefinition: ['isStarred:desc', 'updated_at:desc'],
    account_id: null,
    order: null,
    currentFilter: 'newest'
  });

  _exports.default = _default;
});