define("pickle/components/custom-field", ["exports", "pickle/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    customKey: '',
    customValue: '',
    statusMessage: null,
    isEditing: false,
    showName: true,
    showThis: Ember.computed('customValue', 'isEditing', function () {
      if (this.get('isEditing')) {
        return true;
      } else {
        return this.get('customValue');
      }
    }),
    listenForReturn: function () {
      var val = null; // console.log('listening for return...');
      // console.log('----------------');

      var jsonb_data = this.get('model.jsonb_data');

      if (jsonb_data) {
        val = jsonb_data[this.get('data.name')]; // console.log('---- Custom Field ----');
        // console.log(this.get('data.name'));
        // console.log(val);
        // console.log(jsonb_data);
        // console.log('----------------------');
      }

      this.set('customKey', this.get('data.name'));
      this.set('customValue', val);
    }.on('didInsertElement'),
    actions: {
      toggleEditing: function toggleEditing() {
        this.toggleProperty('isEditing');
      },
      save: function save() {
        var self = this;
        this.set('statusMessage', 'saving...');
        var contact_id = this.get('model.id');
        var token = localStorage.getItem('userAccessToken');
        var dataUpdate = {};
        dataUpdate[this.get('customKey')] = this.get('customValue'); // need to update this record for the user, we can use jquery for this.

        Ember.$.ajax({
          url: "".concat(_environment.default.apiHost, "/").concat(self.get('type'), "/").concat(contact_id, "/set_custom_data"),
          method: 'post',
          headers: {
            'x-access-token': token
          },
          data: dataUpdate
        }).done(function () {
          self.set('statusMessage', '✔');
        });
      },
      setValueFromOptions: function setValueFromOptions() {
        // console.log('set value from options');
        var value = this.$().find('select').val(); // console.log(value);

        this.set('customKey', this.get('data.name'));
        this.set('customValue', value);
        this.send('save');
      }
    }
  });

  _exports.default = _default;
});