define("pickle/templates/components/account-changer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GZ5Nf5D7",
    "block": "[[[18,2,null],[1,\"\\n\\n\"],[10,0],[14,0,\"inline-block relative w-64\"],[12],[1,\"\\n  \"],[10,\"select\"],[14,0,\"block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:ring\"],[15,\"onchange\",[28,[37,1],[[30,0],\"changeAccount\"],[[\"value\"],[\"target.value\"]]]],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[33,4,[\"accounts\"]]],null]],null],null,[[[1,\"    \"],[10,\"option\"],[15,2,[29,[[30,1,[\"id\"]]]]],[15,\"selected\",[28,[37,5],[[30,1,[\"name\"]],[33,4,[\"currentAccount\",\"name\"]]],null]],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700\"],[12],[1,\"\\n    \"],[10,\"svg\"],[14,0,\"fill-current h-4 w-4\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"viewBox\",\"0 0 20 20\"],[12],[10,\"path\"],[14,\"d\",\"M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z\"],[12],[13],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"acct\",\"&default\"],false,[\"yield\",\"action\",\"each\",\"-track-array\",\"account\",\"is-equal\"]]",
    "moduleName": "pickle/templates/components/account-changer.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});