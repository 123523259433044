define("pickle/routes/groups/edit", ["exports", "pickle/mixins/form-cancel-edit"], function (_exports, _formCancelEdit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_formCancelEdit.default, {
    actions: {
      save: function save() {
        var _this = this;

        this.get('controller.model').save().then(function (result) {
          _this.transitionTo('groups.show', result);
        });
      }
    }
  });

  _exports.default = _default;
});