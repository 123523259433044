define("pickle/routes/messages/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params
    /*, transition*/
    ) {
      this.set('group_id', params.group_id);
      this.set('collection', params.collection);
      return this.store.queryRecord('message', {
        message_id: params.message_id,
        collection: this.get('collection'),
        group_id: params.group_id
      });
    },
    afterModel: function afterModel(model
    /*, transition*/
    ) {
      // this.store.queryRecord('');
      this.store.query('messageReply', {
        collection: this.get('collection'),
        message_id: model.id
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('messageReplies', this.store.query('messageReply', {
        group_id: this.get('group_id'),
        collection: this.get('collection')
      }));
      controller.set('messages', this.store.query('message', {
        group_id: this.get('group_id'),
        collection: this.get('collection')
      }));

      this._super(controller, model);
    }
  });

  _exports.default = _default;
});