define("pickle/components/custom-field-form-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    removeFieldAction: null,
    data: null
  });

  _exports.default = _default;
});