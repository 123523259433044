define("pickle/components/note-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service('store'),
    note_id: null,
    note: null,
    didInsertElement: function didInsertElement() {
      var _this = this;

      // find note
      this.get('store').findRecord('note', this.get('note_id')).then(function (note) {
        _this.set('note', note);
      });
    }
  });

  _exports.default = _default;
});