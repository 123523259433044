define("pickle/components/reminder-composer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    contact: null,
    store: Ember.inject.service(),
    reminder: null,
    startDateLabelText: Ember.computed('reminder.category', function () {
      switch (this.get('reminder.category')) {
        case 'anniversary':
          return 'Anniversary Date';
        // break;

        case 'product launch':
          return 'Launch Date';
        // break;

        case 'birthday':
          return 'Birthday';
        // break;

        default:
          return 'Start Date';
        // break;
      }
    }),
    didInsertElement: function didInsertElement() {
      var now = new Date();
      var reminder = this.get('store').createRecord('reminder', {
        contact: this.get('contact'),
        user: this.get('currentUser'),
        frequency: 'daily',
        // default in select option menu
        name: 'follow up',
        description: "Follow up with ".concat(this.get('contact.first_name'), " ").concat(this.get('contact.last_name')),
        category: 'default',
        start_date: now
      });
      this.set('reminder', reminder);
    },
    willDestroyElement: function willDestroyElement() {
      // this.get('store').deleteRecord(this.get('reminder'));
      this.set('reminder', null);
    },
    actions: {
      saveReminder: function saveReminder() {
        this.get('reminder').save().then(function () {
          Ember.$('.modal').modal('hide');
        });
      },
      updateStartDate: function updateStartDate(date) {
        this.set('reminder.start_date', date.toDate());
      }
    }
  });

  _exports.default = _default;
});