define("pickle/routes/admin/account-invitations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    account: Ember.inject.service('current-account'),
    accountInvitations: null,
    filteredInvitations: null,
    model: function model() {
      var account_id = this.get('account.currentAccount.id') || window.localStorage.getItem('currentAccountId');
      return this.store.query('account-invitation', {
        account_id: account_id
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('newInvitation', this.store.createRecord('account-invitation', {
        account_id: this.get('account.currentAccount.id') || window.localStorage.getItem('currentAccountId')
      }));
      controller.set('model', model);
      controller.set('account', this.get('account'));
    },
    actions: {
      save: function save() {
        var _this = this;

        var $invitationField = Ember.$("#new_invitation_email_field");
        this.set('controller.isSaving', true);
        var flashMessages = Ember.get(this, 'flashMessages'); // disable the input field

        $invitationField.attr('disabled', 'disabled');
        this.get('controller.newInvitation').save().then(function () {
          flashMessages.success('Your invitation is on it\'s way!');
          $invitationField.removeAttr('disabled');

          _this.set('controller.isSaving', false);

          _this.refresh();
        }).catch(function (error) {
          flashMessages.danger("Oh no! ".concat(error.errors[0].message));
          $invitationField.removeAttr('disabled');

          _this.set('controller.isSaving', false);
        });
      },
      cancelInvitation: function cancelInvitation() {
        var flashMessages = Ember.get(this, 'flashMessages');
        flashMessages.danger('This feature is not complete yet :(');
      },
      accountChanged: function accountChanged() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});