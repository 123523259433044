define("pickle/routes/account/dropbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    dropbox_data: [{
      'nil': true
    }],
    model: function model() {
      return this.get('dropbox_data');
    },
    afterModel: function afterModel() {},
    setupController: function setupController(controller
    /*, model*/
    ) {
      controller.set('current_user', this.controllerFor('application').get('current_user'));
      var self = this;
      Ember.$.getScript('//cdnjs.cloudflare.com/ajax/libs/dropbox.js/0.10.2/dropbox.min.js').then(function () {
        // Browser-side applications do not use the API secret.
        // docs: http://coffeedoc.info/github/dropbox/dropbox-js/master/classes/Dropbox/Client.html#constructor-instance
        var client = new window.Dropbox.Client({
          key: "vmd2cvgauyylgdx",
          maxApiServer: 0,
          token: controller.get('current_user.dropbox_token')
        });
        client.authenticate(function (error, client) {
          if (error) {// Replace with a call to your own error-handling code.
            //
            // Don't forget to return from the callback, so you don't execute the code
            // that assumes everything went well.
            // return console.log(error);
          } // Replace with a call to your own application code.
          //
          // The user authorized your app, and everything went well.
          // client is a Dropbox.Client instance that you can use to make API calls.


          self.saveDropboxToken(client);
          self.makeSampleRequest(client);
        });
      });
    },
    saveDropboxToken: function saveDropboxToken(client) {
      this.store.find('user', 'me').then(function (me) {
        me.set('dropbox_token', client._oauth._token).save();
      });
    },
    makeSampleRequest: function makeSampleRequest(client) {
      var self = this;
      client.getAccountInfo(function () {});
      client.readdir('/better-crm/contacts/matt gardner', function (error, entries, dir_stat
      /*, entry_stats*/
      ) {
        if (error) {
          self.controller.set('entries', error);
        } //alert("Your Dropbox contains " + entries.join(", "));
        //self.controller.set('entries', [{'facebook':'no'}]);


        self.controller.set('entries', dir_stat._json.contents);
      });
    },
    actions: {}
  });

  _exports.default = _default;
});