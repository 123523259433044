define("pickle/controllers/opportunities/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      accountChanged: function accountChanged(accountId) {
        this.model.set('the_account_id', accountId);
      },
      save: function save() {
        var _this = this;

        this.set('isSaving', true);
        this.model.save().then(function (result) {
          _this.set('isSaving', false);

          _this.transitionToRoute('opportunities.show', result.get('id'));
        });
      },
      delete: function _delete(opportunity) {
        var _this2 = this;

        var confirm = window.confirm("Are you sure you want to delete ".concat(opportunity.get('name'), "? This action cannot be undone."));
        var route = 'opportunities.index';

        if (confirm) {
          opportunity.destroyRecord().then(function () {
            _this2.transitionToRoute(route);
          });
        }
      }
    }
  });

  _exports.default = _default;
});