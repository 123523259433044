define("pickle/models/reminder", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    frequency: _emberData.default.attr('string'),
    recurs_every: _emberData.default.attr(),
    last_notification_date: _emberData.default.attr('date'),
    next_notification_date: _emberData.default.attr('date'),
    next_reminder_date: _emberData.default.attr('date'),
    created_at: _emberData.default.attr('date'),
    updated_at: _emberData.default.attr('date'),
    start_date: _emberData.default.attr('date'),
    // this is the start date/anniversary, birthday, etc.
    category: _emberData.default.attr('string'),
    user: _emberData.default.belongsTo('user'),
    contact: _emberData.default.belongsTo('contact')
  });

  _exports.default = _default;
});