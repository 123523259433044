define("pickle/templates/components/list-building-checkbox-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5UiVvQrR",
    "block": "[[[1,[28,[35,0],null,[[\"type\",\"click\",\"checked\"],[\"checkbox\",[28,[37,1],[[30,0],\"toggleChecked\"],[[\"value\"],[\"target.checked\"]]],[33,2,[\"isChecked\"]]]]]]],[],false,[\"input\",\"action\",\"model\"]]",
    "moduleName": "pickle/templates/components/list-building-checkbox-input.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});