define("pickle/adapters/pipeline", ["exports", "pickle/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForCreateRecord: function urlForCreateRecord(modelName, snapshot) {
      var account_id = snapshot.record.get('account_id') || snapshot.record.get('account.id');
      return "".concat(this._super.apply(this, arguments), "/?account_id=").concat(account_id);
    },
    urlForQueryRecord: function urlForQueryRecord(query, modelName) {
      var baseUrl = this.buildURL(); // console.log(slug);
      // return `${baseUrl}/${encodeURIComponent(slug)}`;

      return "".concat(baseUrl, "/pipelines/").concat(query.id);
    }
  });

  _exports.default = _default;
});