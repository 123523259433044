define("pickle/adapters/app-feedback", ["exports", "pickle/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForCreateRecord: function urlForCreateRecord(modelName, snapshot) {
      return "".concat(this.buildURL(), "/app_feedback");
    }
  });

  _exports.default = _default;
});