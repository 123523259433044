define("pickle/helpers/opportunity-status-badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.opportunityStatusBadge = opportunityStatusBadge;

  function opportunityStatusBadge(params
  /*, hash*/
  ) {
    //console.log(params[0]);
    switch (params[0]) {
      case null:
        return new Ember.String.htmlSafe("<span class=\"label label-default\">".concat(params[0], "</span>"));

      case 'won':
        return new Ember.String.htmlSafe("<span class=\"label label-success\">".concat(params[0], "</span>"));

      case 'lost':
        return new Ember.String.htmlSafe("<span class=\"label label-danger\">".concat(params[0], "</span>"));

      case 'pending':
        return new Ember.String.htmlSafe("<span class=\"label label-primary\">".concat(params[0], "</span>"));

      case 'on hold':
        return new Ember.String.htmlSafe("<span class=\"label label-default\">".concat(params[0], "</span>"));

      default:
        return new Ember.String.htmlSafe("<span class=\"label label-default\">".concat(params[0], "</span>"));
    }
  }

  var _default = Ember.Helper.helper(opportunityStatusBadge);

  _exports.default = _default;
});