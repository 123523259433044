define("pickle/templates/components/tag-search-results-popover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CCL7k0q1",
    "block": "[[[41,[33,1],[[[1,\"    \"],[10,0],[14,0,\"search-results\"],[12],[1,\"\\n\"],[41,[33,2,[\"length\"]],[[[1,\"        \"],[10,\"h5\"],[12],[1,\"Found tags:\"],[13],[1,\"\\n        \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[33,2]],null]],null],null,[[[1,\"            \"],[10,\"li\"],[15,\"onclick\",[28,[37,5],[[30,0],\"selectTag\",[30,1]],null]],[12],[1,[30,1,[\"tags\"]]],[13],[1,\"\\n\"]],[1]],null],[1,\"        \"],[13],[1,\"\\n\"]],[]],[[[41,[33,1,[\"length\"]],[[[1,\"        \"],[10,0],[14,0,\"empty\"],[12],[1,\"\\n            Press return/enter to save \"],[10,\"strong\"],[12],[1,[34,1]],[13],[1,\" as a new tag.\\n        \"],[13],[1,\"\\n        \"]],[]],null]],[]]],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[18,2,null]],[\"tag\",\"&default\"],false,[\"if\",\"tagName\",\"results\",\"each\",\"-track-array\",\"action\",\"yield\"]]",
    "moduleName": "pickle/templates/components/tag-search-results-popover.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});