define("pickle/adapters/opportunity", ["exports", "pickle/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    opp_value: {
      key: 'value'
    },
    urlForQueryRecord: function urlForQueryRecord(query, modelName) {
      if (query.id) {
        var id = query.id;
        var baseUrl = this.buildURL();
        delete query.id;
        var url = "".concat(baseUrl, "/opportunities/").concat(id);
        return url;
      } else {
        return this._super();
      }
    },
    setCase: function setCase(opportunity, caseId) {
      var url = this.buildURL('opportunities', opportunity.get('id')) + "/set_case";
      var options = {
        data: {
          case: {
            id: caseId
          }
        }
      };
      return this.ajax(url, 'POST', options);
    },
    removeCase: function removeCase(opportunity, caseId) {
      var url = this.buildURL('opportunities', opportunity.get('id')) + "/remove_case";
      var options = {
        data: {
          case: {
            id: caseId
          }
        }
      };
      return this.ajax(url, 'POST', options);
    },
    addContact: function addContact(opportunity, contact) {
      var url = this.buildURL('opportunities', opportunity.get('id')) + "/add_contact";
      var options = {
        data: {
          contact: contact
        }
      };
      return this.ajax(url, 'POST', options);
    },
    removeContact: function removeContact(opportunity, contact) {
      var url = this.buildURL('opportunities', opportunity.get('id')) + "/remove_contact";
      var options = {
        data: {
          contact: {
            id: contact.get('id')
          }
        }
      };
      return this.ajax(url, 'POST', options);
    }
  });

  _exports.default = _default;
});