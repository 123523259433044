define("pickle/routes/search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    account: Ember.inject.service('current-account'),
    currentAccount: Ember.inject.service(),
    // queryParams: {
    //     s: { modelRefresh: true }
    // }, // s = search term
    model: function model() {
      var account_id = this.get('account.currentAccount.id') || window.localStorage.getItem('currentAccountId') || this.get('currentUser.default_account_id');

      if (account_id) {
        return this.store.query('search', {
          account_id: account_id
        });
      } else {
        console.log('no account id... :(');
      } // find all saved searches
      // this.store.query('search', {
      //   account_id: account_id
      // }).then(searches => {
      //   this.set('controller.searches', searches);
      // });

    },
    afterModel: function afterModel() {
      var _this = this;

      var account_id = this.get('account.currentAccount.id') || window.localStorage.getItem('currentAccountId'); // find all custom fields to use when useAllCustomFields is set

      this.store.query('customField', {
        account_id: account_id
      }).then(function (fields) {
        _this.set('controller.customFields', fields);
      }); // find all tags

      this.store.query('tag', {
        account_id: account_id
      }).then(function (tags) {
        _this.set('controller.tags', tags);
      });
    },
    actions: {
      accountChanged: function accountChanged() {
        this.refresh();
      },
      customSearchSavingHasFinished: function customSearchSavingHasFinished() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});