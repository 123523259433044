define("pickle/components/upgrade-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentAccount: null,
    daysLeft: Ember.computed('currentAccount', 'currentAccount.organization', function () {
      var dayms = 24 * 60 * 60 * 1000;
      var created = new Date(this.get('currentAccount.created_at'));
      var trialDays = this.get('currentAccount.trialDays');
      var endDate = new Date(created.setTime(created.getTime() + trialDays * 86400000));
      var now = new Date();

      if (endDate.getTime() < now.getTime()) {
        return 0;
      } else {
        return Math.round(Math.abs(now.getTime() - endDate.getTime()) / dayms);
      }
    })
  });

  _exports.default = _default;
});