define("pickle/initializers/user", ["exports", "pickle/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize(application) {
    application.deferReadiness();
    var url = _environment.default.apiHost + "/users/me";
    fetch(url, {
      credentials: 'include',
      method: "get",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(function (response) {
      return response.json();
    }).then(function (response) {
      if (response.user) {
        application.register('user:current', response.user, {
          instantiate: false,
          singleton: true
        }); // Inject the namespace into controllers and routes

        application.inject('route', 'currentUser', 'user:current');
        application.inject('controller', 'currentUser', 'user:current');
      } else {
        console.error('Failed to authenticate', response.message);
      }

      application.advanceReadiness();
    }).catch(function (error) {
      console.error(error);

      if (error.status === 403) {
        // server returned with unknown user
        // unset user
        localStorage.clear();
        application.advanceReadiness();
      } else {
        application.advanceReadiness();
      }
    });
  }

  var _default = {
    name: 'user',
    after: 'ember-data',
    initialize: initialize
  };
  _exports.default = _default;
});