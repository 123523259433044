define("pickle/templates/components/custom-code-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gw+kl4Jj",
    "block": "[[[41,[33,1],[[[1,\"    \"],[10,\"h4\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"big centered\"],[12],[1,\"\\n        \"],[1,[34,2]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,\"h4\"],[12],[1,[33,3,[\"name\"]]],[13],[1,\"\\n    \"],[10,0],[14,0,\"big centered\"],[12],[1,\"\\n        \"],[1,[34,4]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,2],[14,0,\"centered\"],[12],[1,[33,3,[\"description\"]]],[13],[1,\"\\n\"],[41,[28,[37,5],[[33,6,[\"currentAccount\",\"stats\",\"magicEmails\",\"today\"]],0],null],[[],[]],[[],[]]]],[]]]],[],false,[\"if\",\"isLoading\",\"loading-dots\",\"customCodePanel\",\"body\",\"eq\",\"currentAccount\"]]",
    "moduleName": "pickle/templates/components/custom-code-panel.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});