define("pickle/models/custom-code-panel", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    uuid: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    url: _emberData.default.attr('string'),
    url_method: _emberData.default.attr('string'),
    account: _emberData.default.belongsTo('account')
  });

  _exports.default = _default;
});