define("pickle/templates/components/custom-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o7mUypXr",
    "block": "[[[10,0],[15,0,[29,[\"modal-container \",[52,[30,0,[\"isHidden\"]],\"hidden\"]]]],[15,\"onclick\",[28,[37,1],[[30,0],\"hide\"],null]],[12],[1,\"\\n  \"],[10,0],[14,0,\"modal md:min-w-3/4 lg:min-w-1/2\"],[15,1,[29,[\"edit-note-modal-\",[33,2,[\"id\"]]]]],[12],[1,\"\\n    \"],[10,\"button\"],[14,0,\"close\"],[14,\"data-dismiss\",\"modal\"],[14,\"aria-hidden\",\"false\"],[15,\"onclick\",[28,[37,1],[[30,0],\"hide\"],null]],[14,4,\"button\"],[12],[1,\"×\"],[13],[1,\"\\n    \"],[10,\"header\"],[12],[1,\"\\n      \"],[10,\"h1\"],[12],[1,[30,1]],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"modal-body\"],[12],[1,\"\\n      \"],[18,2,[[28,[37,1],[[30,0],\"hide\"],null]]],[1,\"\\n\"],[41,[33,4],[[[1,\"      \"],[10,\"footer\"],[12],[1,\"\\n\"],[1,\"        \"],[11,\"button\"],[24,0,\"btn btn-primary\"],[16,\"disabled\",[36,5]],[24,4,\"button\"],[4,[38,1],[[30,0],\"ok\"],null],[12],[1,[30,0,[\"ok\"]]],[13],[1,\"\\n        \"],[3,\"Footer\"],[1,\"\\n\"],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@title\",\"&default\"],false,[\"if\",\"action\",\"note\",\"yield\",\"footer\",\"okEnabled\"]]",
    "moduleName": "pickle/templates/components/custom-modal.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});