define("pickle/adapters/company", ["exports", "pickle/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    addContact: function addContact(company, contactId) {
      var url = "".concat(this.buildURL('company', company.get('id')), "/contacts/").concat(contactId, "/add");
      var options = {
        data: {
          contact: {
            id: contactId
          }
        }
      };
      return this.ajax(url, 'POST', options);
    },
    removeContact: function removeContact(company, contactId) {
      var url = "".concat(this.buildURL('company', company.get('id')), "/contacts/").concat(contactId, "/remove");
      var options = {
        data: {
          contact: {
            id: contactId
          }
        }
      };
      return this.ajax(url, 'POST', options);
    }
  });

  _exports.default = _default;
});