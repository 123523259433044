define("pickle/templates/settings/account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kEqliRLi",
    "block": "[[[10,0],[14,0,\"flex-column left-margin\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"main container\"],[12],[1,\"\\n        \"],[10,\"h1\"],[12],[1,\"Account Settings!\"],[13],[1,\"\\n\"],[41,[51,[33,1]],[[[1,\"            \"],[11,\"form\"],[4,[38,2],[[30,0],\"save\"],[[\"on\"],[\"submit\"]]],[12],[1,\"\\n                \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n                    \"],[10,\"label\"],[14,\"for\",\"\"],[12],[1,\"Account Name:\"],[13],[1,\"\\n                    \"],[10,0],[12],[1,[28,[35,3],null,[[\"class\",\"type\",\"value\"],[\"form-control\",\"text\",[33,4,[\"name\"]]]]]],[13],[1,\"\\n                \"],[13],[1,\"\\n                \"],[10,\"button\"],[14,0,\"button-shadow green\"],[14,4,\"submit\"],[12],[1,\"Save\"],[13],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],[[[1,\"            \"],[1,[34,5]],[1,\"\\n\"]],[]]],[1,\"\\n        \"],[10,0],[14,0,\"margin-top\"],[12],[1,\"\\n            \"],[6,[39,6],null,[[\"class\",\"route\",\"model\"],[\"button-shadow\",\"settings.account.profile_buttons\",[33,4,[\"id\"]]]],[[\"default\"],[[[[1,\"Show Button Settings\"]],[]]]]],[1,\"\\n\\n            \"],[46,[28,[37,8],null,null],null,null,null],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[],false,[\"unless\",\"isSaving\",\"action\",\"input\",\"model\",\"loading-dots\",\"link-to\",\"component\",\"-outlet\"]]",
    "moduleName": "pickle/templates/settings/account.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});