define("pickle/templates/contacts/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YIhzbW1r",
    "block": "[[[10,0],[14,0,\"flex-column maxed\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"main container\"],[12],[1,\"\\n    \"],[46,[28,[37,1],null,null],null,null,null],[1,\"\\n\\n    \"],[10,0],[14,0,\"header-block\"],[12],[1,\"\\n      \"],[10,\"h1\"],[12],[1,\"Edit contact\"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[19,\"contacts/_form\",[]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"right-content max-width\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"block\"],[12],[1,\"\\n    \"],[10,\"h4\"],[12],[1,\"Advanced\"],[13],[1,\"\\n    \"],[6,[39,3],null,[[\"route\",\"model\"],[\"contacts.merge\",[33,4]]],[[\"default\"],[[[[1,\"Merge Contact\"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],true,[\"component\",\"-outlet\",\"partial\",\"link-to\",\"model\"]]",
    "moduleName": "pickle/templates/contacts/edit.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});