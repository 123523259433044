define("pickle/components/add-location-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button {{action 'requestGeolocation'}} class="flex p-2 pl-3 pr-3 mb-2 font-bold text-gray-600 border border-gray-300 rounded-full sm:p-2 sm:text-sm w-max flex-nowrap hover:bg-blue-400 hover:text-white text-md">📍 Add Location</button>
  {{yield}}
  
  */
  {
    "id": "dejKqzZv",
    "block": "[[[11,\"button\"],[24,0,\"flex p-2 pl-3 pr-3 mb-2 font-bold text-gray-600 border border-gray-300 rounded-full sm:p-2 sm:text-sm w-max flex-nowrap hover:bg-blue-400 hover:text-white text-md\"],[4,[38,0],[[30,0],\"requestGeolocation\"],null],[12],[1,\"📍 Add Location\"],[13],[1,\"\\n\"],[18,1,null],[1,\"\\n\"]],[\"&default\"],false,[\"action\",\"yield\"]]",
    "moduleName": "pickle/components/add-location-button.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});