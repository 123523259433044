define("pickle/routes/contacts/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    contact: null,
    account: Ember.inject.service('current-account'),
    model: function model(params) {
      this.contact = this.store.findRecord('contact', params.contact_id);
      return this.contact;
    },
    afterModel: function afterModel(contact) {
      var _this = this;

      console.log(contact.get('jsonb_data'));
      var customFields = this.store.query('custom_field', {
        account_id: this.get('account.currentAccountId')
      }).then(function (customFields) {
        _this.set('controller.customFields', customFields.toArray());
      });
      return this.model;
    }
  });

  _exports.default = _default;
});