define("pickle/components/pipeline-container", ["exports", "dragula"], function (_exports, _dragula) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['pipeline-container'],
    drake: null,
    didInsertElement: function didInsertElement() {
      console.log('inserted pipeline container');
      this.enableDragAndDrop();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      console.log("update attrs");
    },
    enableDragAndDrop: function enableDragAndDrop() {
      var self = this;
      var containers = Array.from(document.getElementsByClassName('pipeline-column'));
      var drake = (0, _dragula.default)(containers, {
        copy: false
      }).on('drag', function (el) {
        el.className = el.className.replace('ex-moved', '');
      }).on('drop', function (el, target) {
        el.className += ' ex-moved';
        console.log(el);
        console.log(target);
        self.sendAction('movedAction', el.children[0].dataset.opportunity, target.dataset.state);
      }).on('over', function (el, container) {
        container.className += ' ex-over';
      }).on('out', function (el, container) {
        container.className = container.className.replace('ex-over', '');
      });
      this.set('drake', drake);
    },
    willDestroyElement: function willDestroyElement() {
      var drake = this.get('drake');
      drake.destroy();
    }
  });

  _exports.default = _default;
});