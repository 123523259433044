define("pickle/routes/timeline-steps/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord('timeline-step', params.id);
    },
    actions: {
      accountChanged: function accountChanged() {
        this.transitionTo('timelines.index');
      }
    }
  });

  _exports.default = _default;
});