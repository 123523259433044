define("pickle/models/form", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _DS$Model$extend;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _emberData.default.Model.extend((_DS$Model$extend = {
    name: _emberData.default.attr(),
    user: _emberData.default.belongsTo('user'),
    account: _emberData.default.belongsTo('account'),
    account_id: _emberData.default.attr(),
    success_url: _emberData.default.attr('string'),
    uuid: _emberData.default.attr('string'),
    meta: _emberData.default.attr(),
    success_email: _emberData.default.attr('string'),
    form_post_url: _emberData.default.attr('string')
  }, _defineProperty(_DS$Model$extend, "success_url", _emberData.default.attr('string')), _defineProperty(_DS$Model$extend, "fail_url", _emberData.default.attr('string')), _defineProperty(_DS$Model$extend, "created_at", _emberData.default.attr('date')), _defineProperty(_DS$Model$extend, "updated_at", _emberData.default.attr('date')), _defineProperty(_DS$Model$extend, "submissions_count", _emberData.default.attr('number')), _DS$Model$extend));

  _exports.default = _default;
});