define("pickle/components/simple-contact-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    contact: null,
    displayName: Ember.computed('contact.first_name', function () {
      if (this.get('contact.first_name')) {
        return "".concat(this.get('contact.first_name'), " ").concat(this.get('contact.last_name'));
      } else if (this.get('contact.email')) {
        return "".concat(this.get('contact.email'));
      } else {
        return '';
      }
    })
  });

  _exports.default = _default;
});