define("pickle/routes/cases/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    account: Ember.inject.service('current-account'),
    model: function model() {
      return this.store.createRecord('opportunity', {
        type: 'case'
      });
    },
    actions: {
      save: function save() {
        var _this = this;

        this.set('controller.model.account', this.store.peekRecord('account', this.get('account.currentAccount.id')));
        this.get('controller.model').save().then(function (result) {
          _this.transitionTo('cases.show', result);
        });
      },
      accountChanged: function accountChanged(accountId) {
        this.set('selectedAccountId', accountId);
      }
    }
  });

  _exports.default = _default;
});