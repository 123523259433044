define("pickle/templates/tables/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iGUOLn2I",
    "block": "[[[10,0],[14,0,\"flex-column left-margin\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"main container\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"container-header\"],[12],[1,\"\\n            \"],[10,\"h1\"],[12],[1,\"Tables \"],[10,1],[14,0,\"beta badge\"],[12],[1,\"Beta\"],[13],[13],[1,\"\\n            \"],[10,0],[14,0,\"right\"],[12],[1,\"\\n                \"],[6,[39,0],null,[[\"class\",\"route\"],[\"button-shadow\",\"tables.new\"]],[[\"default\"],[[[[1,\"Add a new Table\"]],[]]]]],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"table-container\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"table table-hover\"],[12],[1,\"\\n                \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[33,3]],null]],null],null,[[[6,[39,0],null,[[\"tagName\",\"route\",\"model\"],[\"tr\",\"tables.show\",[30,1,[\"uuid\"]]]],[[\"default\"],[[[[1,\"                            \"],[10,\"td\"],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n                            \"],[10,\"td\"],[12],[1,[30,1,[\"description\"]]],[13],[1,\"\\n                            \"],[10,\"td\"],[12],[1,[28,[35,4],[[30,1,[\"updated_at\"]]],[[\"timeAgoInWords\"],[true]]]],[13],[1,\"\\n\"]],[]]]]]],[1]],null],[1,\"                \"],[13],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[46,[28,[37,6],null,null],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"]],[\"table\"],false,[\"link-to\",\"each\",\"-track-array\",\"model\",\"date-format\",\"component\",\"-outlet\"]]",
    "moduleName": "pickle/templates/tables/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});