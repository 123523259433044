define("pickle/templates/components/notes-timeline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "swws2dCS",
    "block": "[[[10,0],[14,0,\"h-screen overflow-auto notes-container\"],[12],[1,\"\\n\"],[41,[30,0,[\"loading\"]],[[[1,\"        \"],[8,[39,1],null,null,null],[1,\"\\n\"]],[]],null],[41,[30,1,[\"length\"]],[[[1,\"        \"],[10,0],[14,0,\"notes notes-timeline\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"reload-notes\"],[12],[1,\"\\n\"],[41,[30,0,[\"notificationCenter\",\"reloadContactNotes\"]],[[[1,\"                \"],[11,3],[24,0,\"button-reload\"],[4,[38,2],[[30,0],\"reloadNotes\"],null],[12],[1,\"🔔 New Messages\"],[13]],[]],null],[1,\"\\n            \"],[13],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"reversedNotes\"]]],null]],null],null,[[[1,\"                \"],[8,[39,5],null,[[\"@note\",\"@canEdit\",\"@editAction\",\"@currentUser\",\"@account\"],[[30,2],true,[30,0,[\"editNote\"]],[36,6],[36,7]]],null],[1,\"\\n\"]],[2]],null],[1,\"        \"],[13],[1,\"\\n\\n\"],[41,[33,8],[[[1,\"            \"],[10,0],[14,0,\"centered load-more\"],[12],[1,\"\\n\"],[41,[51,[30,0,[\"hideLoadMoreButton\"]]],[[[41,[30,0,[\"isLoadingMoreNotes\"]],[[[1,\"                        \"],[10,\"button\"],[14,0,\"button-shadow\"],[14,\"disabled\",\"disabled\"],[14,4,\"button\"],[12],[1,\"Loading \"],[41,[30,0,[\"isLoadingMoreNotes\"]],[[[10,0],[14,0,\"loading-spinner-sm\"],[12],[13]],[]],null],[13],[1,\"\\n\"]],[]],[[[1,\"                        \"],[11,\"button\"],[24,0,\"button-shadow\"],[24,4,\"button\"],[4,[38,10],[\"click\",[30,0,[\"loadMoreAction\"]]],null],[12],[1,\"Load more\"],[13],[1,\"\\n\"]],[]]]],[]],null],[1,\"            \"],[13],[1,\"\\n\"]],[]],null]],[]],[[[1,\"        \"],[10,0],[14,0,\"min-w-full\"],[12],[1,\"No Notes\"],[13],[1,\"\\n\"]],[]]],[13],[1,\"\\n\\n\\n\"]],[\"@notes\",\"n\"],false,[\"if\",\"loading-spinner\",\"action\",\"each\",\"-track-array\",\"note-styled\",\"current_user\",\"account\",\"loadMoreAction\",\"unless\",\"on\"]]",
    "moduleName": "pickle/templates/components/notes-timeline.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});