define("pickle/templates/save-search-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "95jDrmf2",
    "block": "[[[6,[39,0],null,[[\"title\",\"ok\",\"close\"],[\"Save Search\",\"save\",\"removeModal\"]],[[\"default\"],[[[[11,\"form\"],[4,[38,1],[[30,0],\"ok\"],[[\"on\",\"target\"],[\"submit\",[33,2]]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n    \"],[10,\"label\"],[12],[1,\"Name your search:\"],[13],[1,\"\\n    \"],[1,[28,[35,3],null,[[\"class\",\"value\"],[\"form-control\",[33,4,[\"name\"]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[33,6],[[[1,\"  \"],[10,2],[12],[10,\"strong\"],[12],[1,\"Search Term:\"],[13],[1,\" \"],[1,[33,4,[\"search_string\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[10,\"h6\"],[12],[1,\"Tags included in this search:\"],[13],[1,\"\\n\\n\\n  \"],[10,0],[14,0,\"tags flex-tags\"],[12],[1,\"\\n\"],[42,[28,[37,8],[[28,[37,8],[[33,9]],null]],null],null,[[[1,\"    \"],[10,1],[14,0,\"tag inline-block\"],[12],[1,[30,1]],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"Saving your search will allow for one click access to this search in the future.\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[]]]]]],[\"tag\"],false,[\"custom-modal\",\"action\",\"view\",\"input\",\"search\",\"if\",\"searchString\",\"each\",\"-track-array\",\"tags\"]]",
    "moduleName": "pickle/templates/save-search-modal.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});