define("pickle/templates/components/user-avatar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2IOBJ1Nw",
    "block": "[[[18,1,null],[1,\"\\n\"],[1,[28,[35,1],null,[[\"email\",\"retina\",\"size\",\"class\",\"defaultImage\"],[[33,2,[\"email\"]],true,[33,3],\"rounded-avatar\",\"retro\"]]]]],[\"&default\"],false,[\"yield\",\"gravatar-image\",\"user\",\"size\"]]",
    "moduleName": "pickle/templates/components/user-avatar.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});