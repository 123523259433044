define("pickle/templates/components/pill-view-contact", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5sqyQqzS",
    "block": "[[[41,[48,[30,2]],[[[1,\"    \"],[18,2,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"avatar h-6 w-6 inline-flex rounded-full mx-auto\"],[12],[1,\"\\n        \"],[8,[39,3],null,[[\"@contact\",\"@size\",\"@textSize\"],[[30,1],\"h-6 w-6\",\"text-sm\"]],null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[1,[33,4,[\"first_name\"]]],[1,\" \"],[1,[33,4,[\"last_name\"]]],[1,\"\\n\"]],[]]]],[\"@contact\",\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"contact-avatar-image\",\"contact\"]]",
    "moduleName": "pickle/templates/components/pill-view-contact.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});