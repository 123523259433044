define("pickle/templates/address-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H8IXjvE2",
    "block": "[[[6,[39,0],null,[[\"title\",\"ok\",\"close\"],[\"Address\",\"save\",\"removeModal\"]],[[\"default\"],[[[[11,\"form\"],[4,[38,1],[[30,0],\"ok\"],[[\"on\",\"target\"],[\"submit\",[33,2]]]],[12],[1,\"\\n\\t\"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n\\t\\t\"],[10,\"label\"],[12],[1,\"Address:\"],[13],[1,\"\\n\\t\\t\"],[1,[28,[35,3],null,[[\"class\",\"value\"],[\"form-control\",[33,4,[\"address_1\"]]]]]],[1,\"\\n\\t\"],[13],[1,\"\\n\\t\\t\"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n\\t\\t\\t\"],[10,\"label\"],[12],[1,\"Address 2:\"],[13],[1,\"\\n\\t\\t\\t\"],[1,[28,[35,3],null,[[\"class\",\"value\"],[\"form-control\",[33,4,[\"address_2\"]]]]]],[1,\"\\n\\t\\t\"],[13],[1,\"\\n\\n\\t\"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n\\t\\t\"],[10,\"label\"],[12],[1,\"City, State & Zip code\"],[13],[1,\"\\n\\t\\t\"],[10,0],[14,0,\"row\"],[12],[1,\"\\n\\t\\t\\t\"],[10,0],[14,0,\"col-xs-4\"],[12],[1,\"\\n\\t\\t\\t\\t\"],[1,[28,[35,3],null,[[\"class\",\"value\",\"placeholder\"],[\"form-control\",[33,4,[\"city\"]],\"city\"]]]],[1,\"\\n\\t\\t\\t\"],[13],[1,\"\\n\\t\\t\\t\"],[10,0],[14,0,\"col-xs-4\"],[12],[1,\"\\n\\t\\t\\t\\t\"],[1,[28,[35,3],null,[[\"class\",\"value\",\"placeholder\"],[\"form-control\",[33,4,[\"state\"]],\"state\"]]]],[1,\"\\n\\t\\t\\t\"],[13],[1,\"\\n\\t\\t\\t\"],[10,0],[14,0,\"col-xs-4\"],[12],[1,\"\\n\\t\\t\\t\\t\"],[1,[28,[35,3],null,[[\"class\",\"value\",\"placeholder\"],[\"form-control\",[33,4,[\"zip\"]],\"zip\"]]]],[1,\"\\n\\t\\t\\t\"],[13],[1,\"\\n\\t\\t\"],[13],[1,\"\\n\\t\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"custom-modal\",\"action\",\"view\",\"input\",\"address\"]]",
    "moduleName": "pickle/templates/address-modal.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});