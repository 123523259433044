define("pickle/templates/components/contact-avatar-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Xr/eWdVr",
    "block": "[[[41,[30,1,[\"hasAvatar\"]],[[[1,\"    \"],[10,\"img\"],[15,\"src\",[29,[[33,1,[\"avatar_url\"]]]]],[14,\"alt\",\"avatar\"],[15,\"width\",[36,2]],[15,\"height\",[36,2]],[15,0,[29,[\"rounded-full object-cover \",[36,2]]]],[12],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,3],null,[[\"@contact\",\"@class\"],[[36,1],[29,[\"font-bold flex content-center items-center justify-center text-center flex bg-gray-300 border-gray-400 \",[36,2],\" \",[36,4]]]]],null],[1,\"\\n\"]],[]]]],[\"@contact\"],false,[\"if\",\"contact\",\"size\",\"contact-initials-avatar\",\"textSize\"]]",
    "moduleName": "pickle/templates/components/contact-avatar-image.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});