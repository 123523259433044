define("pickle/mixins/magic-email-generator", ["exports", "pickle/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    account: Ember.inject.service('current-account'),
    actions: {
      generateMagicEmail: function generateMagicEmail(account, inboundEmailAddress) {
        var _this = this;

        var emailId = !Ember.isNone(inboundEmailAddress) ? inboundEmailAddress.id : 'new';
        this.set('isError', false);
        var url;

        if (emailId === 'new') {
          url = "".concat(_environment.default.apiHost, "/magicEmail/new?accountId=").concat(account.id);
        } else {
          url = "".concat(_environment.default.apiHost, "/magicEmail/").concat(emailId, "/regenerate?accountId=").concat(account.id);
        }

        fetch(url, {
          credentials: 'include',
          method: 'post'
        }).then(function (response) {
          _this.set('isSearching', false);

          _this.get('store').pushPayload('inboundEmailAddress', response);

          _this.store.findAll('account');
        }).catch(function () {
          _this.set('isError', true);

          _this.set('isSearching', false);
        });
      }
    }
  });

  _exports.default = _default;
});