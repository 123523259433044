define("pickle/routes/pipelines/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      var account_id = window.localStorage.getItem('currentAccountId');
      return this.store.queryRecord('pipeline', {
        id: params.id,
        account_id: account_id
      });
    },
    afterModel: function afterModel(pipeline
    /*, transition*/
    ) {
      var _this = this;

      if (pipeline) {
        var account_id = pipeline.get('account_id');
        this.store.query('opportunity', {
          type: 'opportunity',
          account_id: account_id
        }).then(function (opportunities) {
          _this.set('controller.opportunities', opportunities);
        });
      }
    }
  });

  _exports.default = _default;
});